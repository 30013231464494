import { useEffect, useMemo } from 'react'

import { Spin } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/common/arrowLeft.svg'
import { ReactComponent as LockIcon } from 'assets/images/common/redesign/lock-outlined.svg'
import { ReactComponent as UnlockIcon } from 'assets/images/common/redesign/unlock-outlined.svg'
import {
  AdminSalonSectionEight,
  AdminSalonSectionFive,
  AdminSalonSectionFour,
  AdminSalonSectionOne,
  AdminSalonSectionSeven,
  AdminSalonSectionSix,
  AdminSalonSectionThree,
  AdminSalonSectionTwo,
} from 'modules/admin/Salons'
import {
  getAdminCurrentSalonRequest,
  setAdminCurrentSalonRequest,
} from 'store/Admin/Salons/Salons.action'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import { ADMIN_SALONS } from 'utils'

import style from './style.module.scss'

const ViewAdminSalonProfile = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    currentSalon: { profile, active },
    isLoading,
    isLockLoading,
    documentLoading,
    isConfirmLoading,
    isSubscriptionLoading,
  } = useSelector((store: IRootState) => store.adminSalons)
  const id = useMemo(() => {
    const splittedPath = location.pathname.split('/')

    return Number(splittedPath[splittedPath.length - 1])
  }, [location.pathname])

  const handleLockClick = () => {
    dispatch(setAdminCurrentSalonRequest(id, Number(!active) as 0 | 1))
  }

  const handleBackClick = () => {
    navigate(ADMIN_SALONS)
  }

  useEffect(() => {
    dispatch(getAdminCurrentSalonRequest(Number(id)))
  }, [dispatch, id])

  return (
    <Content className={style.adminSalonProfilePage}>
      <section className={style.header}>
        <ArrowIcon className={style.back} onClick={handleBackClick} />

        <h1>{t('ADMIN.PROFILE_SALON.TITLE')}</h1>

        <UIButton
          className={style.btnLock}
          handler={handleLockClick}
          label={t(!active ? 'COMMON.UNLOCK' : 'COMMON.LOCK')}
          icon={!active ? <UnlockIcon /> : <LockIcon />}
          disabled={isLockLoading}
          type="text"
          isGreen
        />
      </section>

      <Spin spinning={isLoading}>
        <AdminSalonSectionOne profile={profile} />
        <AdminSalonSectionTwo
          date={profile?.subscribe?.finished_at}
          isFreeTrial={!!profile?.manual_free_trial}
        />
        <AdminSalonSectionThree address={profile?.address} />
        <AdminSalonSectionFour images={profile?.images} />
        <AdminSalonSectionFive times={profile?.times} />
        <AdminSalonSectionSix
          id={id}
          documents={profile?.documents}
          confirmed={profile?.is_confirmed}
          isConfirmLoading={isConfirmLoading}
          documentLoading={documentLoading}
        />
        <AdminSalonSectionSeven text={profile?.about} />
        <AdminSalonSectionEight
          id={id}
          value={profile?.manual_free_trial}
          isLoading={isSubscriptionLoading}
        />
      </Spin>
    </Content>
  )
}

export default ViewAdminSalonProfile

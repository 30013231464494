import {
  CLEAR_CLIENTS_ERROR,
  GET_MANAGER_CLIENTS,
  GET_MANAGER_CLIENTS_FAILURE,
  GET_MANAGER_CLIENTS_SUCCESS,
  GET_MANAGER_USERS,
  GET_MANAGER_USERS_FAILURE,
  GET_MANAGER_USERS_SUCCESS,
} from './Clients.constant'
import { IManagerClientState, ManagerClientsActionTypes } from './Clients.type'

const initialState: IManagerClientState = {
  isLoading: false,
  errorMessage: null,
  data: [],
  meta: {
    page: 1,
    perPage: 0,
    total: 0,
  },
}

export default function managerClientsReducer(
  state = initialState,
  action: ManagerClientsActionTypes,
): IManagerClientState {
  switch (action.type) {
    case GET_MANAGER_CLIENTS:
    case GET_MANAGER_USERS:
      return { ...state, isLoading: true }
    case GET_MANAGER_CLIENTS_SUCCESS:
    case GET_MANAGER_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        meta: {
          page: action.payload.meta.current_page,
          perPage: action.payload.meta.per_page,
          total: action.payload.meta.total,
        },
        isLoading: false,
      }
    case GET_MANAGER_CLIENTS_FAILURE:
    case GET_MANAGER_USERS_FAILURE:
      return { ...state, ...action.payload, isLoading: false }
    case CLEAR_CLIENTS_ERROR:
      return {
        ...state,
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}

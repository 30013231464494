import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UIButton } from 'ui';
import styles from '../style.module.scss';

type PropsType = {
  handleDelete?: () => void;
  handleSave: () => void;
  isAddAppointment?: boolean;
  isBtnDisabled?: boolean;
  isLoading?: boolean;
  total?: string;
};

const UIAppointmendarSidebarFooter: FC<PropsType> = ({
  handleDelete,
  handleSave,
  isAddAppointment,
  total,
  isBtnDisabled,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['mobile-padding']}></div>

      <div className={styles.footer}>
        <div className={styles.total}>
          {t('COMMON.TOTAL')}: <span>${total}</span>
        </div>

        <div className={styles.btn}>
          <UIButton
            label={t('COMMON.SAVE')}
            handler={handleSave}
            disabled={isLoading || isBtnDisabled}
          />

          {!isAddAppointment && (
            <UIButton
              label={t('COMMON.DELETE')}
              type="outline"
              handler={handleDelete}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UIAppointmendarSidebarFooter;

import { FC, ReactElement, useLayoutEffect, useEffect, memo } from 'react'

import 'antd/dist/antd.css'

import { Layout } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { getDefaultAdminSelectedKeys } from 'entities/admin'
import { sidebar } from 'modules/admin/constants'
import { IRootState } from 'store/Root.reducer'
import { WidgetSidebar } from 'widgets'

import styles from './style.module.scss'
import { getAdminSidebarNotificationsRequest } from 'store/Admin/Notifications/Notifications.action'
import { ArrayHelper } from 'utils/helpers'

type PropsType = {
  children: ReactElement
}

const LayoutAdmin: FC<PropsType> = ({ children }) => {
  const param = useParams()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const userType = useSelector((state: IRootState) => state.auth.user?.type)
  const { notificationSidebar } = useSelector(
    (state: IRootState) => state.adminNotifications,
  )

  useEffect(() => {
    const path = getDefaultAdminSelectedKeys(pathname, param)[0] || ''

    document.title = `Beauty Booking${path ? ` - ${t(path)}` : ''}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useLayoutEffect(() => {
    if (userType === 'salon') {
      window.location.replace('/profile-settings/profile-salon')
    } else if (userType === 'customer') {
      window.location.replace('/')
    }
  }, [userType, navigate])

  useEffect(() => {
    dispatch(getAdminSidebarNotificationsRequest())
  }, [dispatch, pathname])

  return (
    <Layout className={styles.wrapper} hasSider>
      <Sider className={styles.menu}>
        <WidgetSidebar
          items={ArrayHelper.getNotification(sidebar, notificationSidebar)}
          defaultSelectedKeys={getDefaultAdminSelectedKeys(pathname, param)}
        />
      </Sider>

      <Layout className={styles.layout}>{children}</Layout>
    </Layout>
  )
}

export default memo(LayoutAdmin)

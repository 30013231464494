export const GET_BASKET_REQUEST = '@basket/GET_BASKET_REQUEST';
export const GET_BASKET_SUCCESS = '@basket/GET_BASKET_SUCCESS';
export const GET_BASKET_FAILURE = '@basket/GET_BASKET_FAILURE';

export const GET_BASKET_TIME_REQUEST = '@basket/GET_BASKET_TIME_REQUEST';
export const GET_BASKET_TIME_SUCCESS = '@basket/GET_BASKET_TIME_SUCCESS';
export const GET_BASKET_TIME_FAILURE = '@basket/GET_BASKET_TIME_FAILURE';

export const GET_BASKET_CALCULATE_TIME_REQUEST =
  '@basket/GET_BASKET_CALCULATE_TIME_REQUEST';
export const GET_BASKET_CALCULATE_TIME_SUCCESS =
  '@basket/GET_BASKET_CALCULATE_TIME_SUCCESS';
export const GET_BASKET_CALCULATE_TIME_FAILURE =
  '@basket/GET_BASKET_CALCULATE_TIME_FAILURE';

export const CREATE_BASKET_SERVICE_REQUEST =
  '@basket/CREATE_BASKET_SERVICE_REQUEST';
export const CREATE_BASKET_SERVICE_SUCCESS =
  '@basket/CREATE_BASKET_SERVICE_SUCCESS';
export const CREATE_BASKET_SERVICE_FAILURE =
  '@basket/CREATE_BASKET_SERVICE_FAILURE';

export const UPDATE_BASKET_SERVICE_REQUEST =
  '@basket/UPDATE_BASKET_SERVICE_REQUEST';
export const UPDATE_BASKET_SERVICE_SUCCESS =
  '@basket/UPDATE_BASKET_SERVICE_SUCCESS';
export const UPDATE_BASKET_SERVICE_FAILURE =
  '@basket/UPDATE_BASKET_SERVICE_FAILURE';

export const DELETE_BASKET_SERVICE_REQUEST =
  '@basket/DELETE_BASKET_SERVICE_REQUEST';
export const DELETE_BASKET_SERVICE_SUCCESS =
  '@basket/DELETE_BASKET_SERVICE_SUCCESS';
export const DELETE_BASKET_SERVICE_FAILURE =
  '@basket/DELETE_BASKET_SERVICE_FAILURE';

export const GET_BASKET_TOTAL_REQUEST = '@basket/GET_BASKET_TOTAL_REQUEST';
export const GET_BASKET_TOTAL_SUCCESS = '@basket/GET_BASKET_TOTAL_SUCCESS';
export const GET_BASKET_TOTAL_FAILURE = '@basket/GET_BASKET_TOTAL_FAILURE';

export const CREATE_SALON_ORDER_REQUEST = '@basket/CREATE_SALON_ORDER_REQUEST';
export const CREATE_SALON_ORDER_SUCCESS = '@basket/CREATE_SALON_ORDER_SUCCESS';
export const CREATE_SALON_ORDER_FAILURE = '@basket/CREATE_SALON_ORDER_FAILURE';

export const CLEAR_BASKET_ACTION_SUCCESS =
  '@basket/CLEAR_BASKET_ACTION_SUCCESS';

export const CLEAR_BASKET_STORE = '@basket/CLEAR_BASKET_STORE';

export const SET_IS_TIME_REQUEST = '@basket/SET_IS_TIME_REQUEST';

import { FC, useState, SyntheticEvent, useEffect, memo } from 'react'

import { Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import TextBlock from 'modules/admin/Faq/TextBlock'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import { SEO_INPUTS } from 'utils'
import {
  TemplateBodyType,
  TextBody,
  TextPagesData,
} from 'views/admin/Pages/index.type'

import style from './style.module.scss'
import { INITAL_SEO_STATE } from '../CustomerTemplate/index.type'
import { TextTemplateRows } from '../ui'
import { addPrefixSeo } from 'utils/helpers/adminTemplate'

const INITAL_DATA_STATE: TextBody = {
  title: '',
  description: '',
}

const INITIAL_STATE: TextPagesData = {
  data: [INITAL_DATA_STATE],
  ...INITAL_SEO_STATE,
  seo_h1: '',
}

type PropsType = { onSave?: (data: TemplateBodyType) => void }

const TextTemplate: FC<PropsType> = ({ onSave }) => {
  const { t } = useTranslation()
  const { currentPage, isLoading } = useSelector(
    (store: IRootState) => store.adminPages,
  )
  const [state, setState] = useState<TextPagesData>(
    (currentPage?.content as TextPagesData) || INITIAL_STATE,
  )

  const handleAddNewRow = () => {
    setState((prevState) => ({
      ...prevState,
      data: [...(prevState.data as TextBody[]), INITAL_DATA_STATE],
    }))
  }

  const handleRemoveRow = (index: number) => {
    setState((prevState) => ({
      ...prevState,
      data: (prevState.data as TextBody[]).filter(
        (_, stateIndex) => stateIndex !== index,
      ),
    }))
  }

  const handleSave = () => {
    if (onSave) onSave(state)
  }

  const handleChange = (index: number, event: SyntheticEvent) => {
    setState((prevState) => {
      const newState = { ...prevState }
      const newStateData = [...(prevState.data as TextBody[])]
      const { name, value } = event.target as HTMLInputElement

      newStateData[index] = { ...newStateData[index], [name]: value }

      newState.data = newStateData

      return newState
    })
  }

  const handleSeoChange = (event: SyntheticEvent) => {
    const { value, name } = event.target as HTMLTextAreaElement

    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  useEffect(() => {
    if (currentPage?.content) {
      setState({
        ...currentPage?.content,
        ...addPrefixSeo(currentPage?.seo || {}),
      } as TextPagesData)
    }
  }, [currentPage?.content, currentPage?.seo])

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical" className={style.textTemplate}>
        <TextTemplateRows
          state={state.data as TextBody[]}
          onChange={handleChange}
          onRowRemove={handleRemoveRow}
        />

        <UIButton
          handler={handleAddNewRow}
          label={t('ADMIN.PAGES.TEMPLATE.TEXT.ADD_TEXT')}
          icon={<PlusIcon />}
          type="text"
        />

        <div className={'seo-block'}>
          {SEO_INPUTS.map(({ name, title: seoTitle }, index) => (
            <TextBlock
              key={index}
              rows={7}
              name={name}
              title={t(seoTitle)}
              value={state[name] as string}
              onChange={handleSeoChange}
            />
          ))}
        </div>

        <div className={style.actions}>
          <UIButton label={t('COMMON.SAVE')} handler={handleSave} />
        </div>
      </Form>
    </Spin>
  )
}

export default memo(TextTemplate)

import { DocumentStatus } from 'entities/common'
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_SALONS_ERROR,
  GET_ADMIN_CURRENT_SALON_REQUEST,
  GET_ADMIN_CURRENT_SALON_SUCCESS,
  GET_ADMIN_SALONS_REQUEST,
  GET_ADMIN_SALONS_FAILURE,
  GET_ADMIN_SALONS_SUCCESS,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_FAILURE,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_SUCCESS,
  SET_ADMIN_CURRENT_SALON_REQUEST,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_FAILURE,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_SUCCESS,
  SET_ADMIN_CURRENT_SALON_FAILURE,
  SET_ADMIN_CURRENT_SALON_SUCCESS,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_SUCCESS,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_FAILURE,
} from './Salons.constant'
import { IAdminSalonResponse, IAdminSalonResponseData } from './Salons.type'

export const getAdminSalonsRequest = (query: string) => ({
  type: GET_ADMIN_SALONS_REQUEST,
  payload: query,
})

export const getAdminSalonsSuccess = (body: IAdminSalonResponse) => ({
  type: GET_ADMIN_SALONS_SUCCESS,
  payload: body,
})

export const getAdminSalonsFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_SALONS_FAILURE,
  payload: error,
})

export const getAdminCurrentSalonRequest = (id: number) => ({
  type: GET_ADMIN_CURRENT_SALON_REQUEST,
  payload: id,
})

export const getAdminCurrentSalonSuccess = (
  salon: IAdminSalonResponseData,
) => ({
  type: GET_ADMIN_CURRENT_SALON_SUCCESS,
  payload: salon,
})

export const getAdminCurrentSalonFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_SALONS_FAILURE,
  payload: error,
})

export const setAdminCurrentSalonRequest = (id: number, active: 1 | 0) => ({
  type: SET_ADMIN_CURRENT_SALON_REQUEST,
  payload: { id, active },
})

export const setAdminCurrentSalonSuccess = (body: 1 | 0) => ({
  type: SET_ADMIN_CURRENT_SALON_SUCCESS,
  payload: body,
})

export const setAdminCurrentSalonFailure = (error: ResponseFailureType) => ({
  type: SET_ADMIN_CURRENT_SALON_FAILURE,
  payload: error,
})

export const setAdminCurrentSalonDocumentConfirmationRequest = (
  id: number,
  confirmed: DocumentStatus,
) => ({
  type: SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST,
  payload: { id, confirmed },
})

export const setAdminCurrentSalonDocumentConfirmationSuccess = (
  value: DocumentStatus,
) => ({
  type: SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_SUCCESS,
  payload: value,
})

export const setAdminCurrentSalonDocumentConfirmationFailure = (
  error: ResponseFailureType,
) => ({
  type: SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_FAILURE,
  payload: error,
})
export const setAdminCurrentSalonToggleSubscriptionRequest = (
  id: number,
  manual_free_trial: number,
) => ({
  type: SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST,
  payload: { id, manual_free_trial },
})

export const setAdminCurrentSalonToggleSubscriptionSuccess = (
  value: number,
) => ({
  type: SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_SUCCESS,
  payload: value,
})

export const setAdminCurrentSalonToggleSubscriptionFailure = (
  error: ResponseFailureType,
) => ({
  type: SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_FAILURE,
  payload: error,
})

export const removeAdminCurrentSalonDocumentRequest = (documentId: number) => ({
  type: REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST,
  payload: documentId,
})

export const removeAdminCurrentSalonDocumentSuccess = (documentId: number) => ({
  type: REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_SUCCESS,
  payload: documentId,
})

export const removeAdminCurrentSalonDocumentFailure = (
  error: ResponseFailureType,
) => ({
  type: REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_FAILURE,
  payload: error,
})

export const clearSalonsError = () => ({
  type: CLEAR_SALONS_ERROR,
})

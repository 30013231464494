import { FC } from 'react';

import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import classNames from 'classnames';

import UIAntDatePicker from 'ui/Ant/DatePicker';

import styles from './style.module.scss';

type PropsType = {
  className?: string;
  disable?: boolean;
  disabledDate?: (date: Date) => boolean;
  format?: string;
  getPopupContainer?: ((arg: HTMLElement) => HTMLElement) | undefined;
  inputReadOnly?: boolean;
  label?: string;
  necessaryLabel?: string;
  onChange: (date: any, dateString: string) => void;
  open?: boolean;
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year';
  place?: PickerLocale;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
  popupClassName?: string;
  status?: '' | 'error' | 'warning';
  value?: Date | null;
};

const UIDatePicker: FC<PropsType> = ({
  label,
  onChange,
  necessaryLabel,
  format,
  value,
  placement,
  disabledDate,
  picker,
  popupClassName,
  status,
  className,
  open,
  getPopupContainer,
  disable,
  inputReadOnly = true,
}) => {
  return (
    <div className={styles.wrapper}>
      {label && (
        <p className={styles.label}>
          {label}{' '}
          {necessaryLabel && (
            <span className={styles.necessaryLabel}>{necessaryLabel}</span>
          )}
        </p>
      )}

      <UIAntDatePicker
        popupClassName={classNames('popupCalendar', popupClassName)}
        className={className}
        onChange={onChange}
        format={format}
        value={value}
        placement={placement}
        disabledDate={disabledDate}
        picker={picker}
        status={status}
        open={open}
        getPopupContainer={getPopupContainer}
        disabled={disable}
        inputReadOnly={inputReadOnly}
      />
    </div>
  );
};

export default UIDatePicker;

import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { SettingsHttp, SubscriptionHttp } from 'services/http'

import { AdminSettingsRequestDataTypeSettings } from 'store/Admin/Settings/Settings.type'
import { getRequestErrors } from 'utils/helpers/requests'
import { AdminSettingsBlockSubscriptionsType } from 'views/admin/Settings/index.type'

import {
  cancelManagerFailure,
  cancelManagerSuccess,
  getManagerHistorySubscriptionFailure,
  getManagerHistorySubscriptionSuccess,
  getManagerSubscriptionFailure,
  getManagerSubscriptionSuccess,
  subscribeManagerFailure,
  subscribeManagerSuccess,
  getManagerSubscriptionRequest,
  updateManagerHistorySubscriptionFailure,
  updateManagerHistorySubscriptionSuccess,
  getManagerHistorySubscriptionRequest,
} from './Subscription.action'
import {
  CANCEL_MANAGER_PLAN_REQUEST,
  GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
  GET_MANAGER_SUBSCRIPTION_REQUEST,
  SUBSCRIBE_MANAGER_PLAN_REQUEST,
  UPDATE_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
} from './Subscription.constant'
import {
  ICancelManagerPlanRequest,
  IGetManagerHistorySubscriptionRequest,
  IGetManagerHistorySubscriptionResponse,
  IManagerSubscribe,
  ISubscribeManagerPlanRequest,
  IUpdateManagerHistorySubscriptionRequest,
} from './Subscription.type'

function* workerGetManagerSubscription() {
  try {
    const {
      data: { data, settings, subscribe },
    }: {
      data: {
        data: AdminSettingsBlockSubscriptionsType[]
        settings: AdminSettingsRequestDataTypeSettings
        subscribe: IManagerSubscribe | null
      }
    } = yield call(SettingsHttp.getPlanSubscriptions)

    yield put(
      getManagerSubscriptionSuccess({
        subscription_plans: data,
        settings: { ...settings },
        subscribe,
      }),
    )
  } catch (error) {
    yield getRequestErrors(getManagerSubscriptionFailure, error as string)
  }
}

function* watchGetManagerSubscription() {
  yield takeLatest(
    GET_MANAGER_SUBSCRIPTION_REQUEST,
    workerGetManagerSubscription,
  )
}

function* workerSubscribeManagerPlan({
  payload,
}: ISubscribeManagerPlanRequest) {
  try {
    const response: {
      data: { url: string }
    } = yield call(SubscriptionHttp.managerPlanSubscribe, payload)

    window.location.href = response.data.url

    yield put(subscribeManagerSuccess())
  } catch (error) {
    yield getRequestErrors(subscribeManagerFailure, error as string)
  }
}

function* watchSubscribeManagerPlan() {
  yield takeLatest(SUBSCRIBE_MANAGER_PLAN_REQUEST, workerSubscribeManagerPlan)
}

function* workerCancelManagerPlan({ payload }: ICancelManagerPlanRequest) {
  try {
    const response: {
      data: unknown
    } = yield call(SubscriptionHttp.managerPlanCancel, payload)

    yield put(cancelManagerSuccess(response.data))
    yield put(getManagerSubscriptionRequest())
  } catch (error) {
    yield getRequestErrors(cancelManagerFailure, error as string)
  }
}

function* watchCancelManagerPlan() {
  yield takeLatest(CANCEL_MANAGER_PLAN_REQUEST, workerCancelManagerPlan)
}

function* workerGetManagerHistorySubscription({
  payload,
}: IGetManagerHistorySubscriptionRequest) {
  try {
    const response: {
      data: IGetManagerHistorySubscriptionResponse
    } = yield call(SubscriptionHttp.managerHistory, payload)

    yield put(getManagerHistorySubscriptionSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(
      getManagerHistorySubscriptionFailure,
      error as string,
    )
  }
}

function* watchGetManagerHistorySubscription() {
  yield takeLatest(
    GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
    workerGetManagerHistorySubscription,
  )
}

function* workerUpdateManagerHistorySubscription({
  payload,
}: IUpdateManagerHistorySubscriptionRequest) {
  try {
    yield call(SubscriptionHttp.updateSubscription, payload)

    yield put(updateManagerHistorySubscriptionSuccess())
    yield put(getManagerHistorySubscriptionRequest())
    yield put(getManagerSubscriptionRequest())
  } catch (error) {
    yield getRequestErrors(
      updateManagerHistorySubscriptionFailure,
      error as string,
    )
  }
}

function* watchUpdateManagerHistorySubscription() {
  yield takeLatest(
    UPDATE_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
    workerUpdateManagerHistorySubscription,
  )
}

export const managerSubscriptionWatchers = [
  fork(watchGetManagerSubscription),
  fork(watchSubscribeManagerPlan),
  fork(watchCancelManagerPlan),
  fork(watchGetManagerHistorySubscription),
  fork(watchUpdateManagerHistorySubscription),
]

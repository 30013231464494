/* eslint-disable no-case-declarations */
import { ResponseFailureType } from 'store/Auth/Auth.type';

import {
  SET_VALIDATION_ERROR,
  RESET_VALIDATION_ERROR,
  UPDATE_APPOINTMENT_AVAILABLE_REQUEST,
  UPDATE_APPOINTMENT_SEARCH_REQUEST,
  UPDATE_APPOINTMENT_AVAILABLE_SUCCESS,
  UPDATE_APPOINTMENT_SEARCH_FAILURE,
  UPDATE_APPOINTMENT_AVAILABLE_FAILURE,
  RESET_AVAILABLE_TIME,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_SCHEDULE_SUCCESS,
  UPDATE_SEARCH_CLIENT_SUCCESS,
  UPDATE_SEARCH_CONTACT_SUCCESS,
  UPDATE_SEARCH_SERVICE_SUCCESS,
  UPDATE_SEARCH_STAFF_SUCCESS,
  CLEAR_STORE,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_SUCCESS,
  SET_QUERY,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_MOBILE_SUCCESS,
  SET_REPEAT_REQUEST,
  RESET_ERROR,
  GET_ONE_SERVICE_SUCCESS,
  GET_HAS_CLIENT_FAILURE,
  GET_HAS_CLIENT_SUCCESS,
} from './Appointments.constant';
import {
  AppointmentsActionTypes,
  IAppointmentState,
  MethodSearch,
} from './Appointments.type';

const initialState: IAppointmentState = {
  appointmentsError: [],
  isLoading: false,
  isGetLoading: false,
  availableTime: [],
  staffList: [],
  isUpdateLoading: false,
  isSearchLoading: false,
  appointmens: [],
  errors: {},
  errorMessage: null,
  schedule: {},
  clientList: [],
  contactList: [],
  serviceList: [],
  isServiceLoading: false,
  isContactLoading: false,
  isClientLoading: false,
  isStaffLoading: false,
  isAppointmentAction: false,
  query: '',
  appointmensMobile: [],
  isRepeatRequest: false,
  service: null,
};

export default function appointmentsReducer(
  state = initialState,
  action: AppointmentsActionTypes,
): IAppointmentState {
  switch (action.type) {
    case SET_VALIDATION_ERROR:
      return {
        ...state,
        appointmentsError: action.payload,
      };
    case RESET_VALIDATION_ERROR:
      return {
        ...state,
        appointmentsError: [],
      };
    case UPDATE_APPOINTMENT_SEARCH_REQUEST:
      return {
        ...state,
        ...(action.payload.method === MethodSearch.CLIENT && {
          isClientLoading: true,
        }),
        ...(action.payload.method === MethodSearch.SERVICE && {
          isServiceLoading: true,
        }),
        ...(action.payload.method === MethodSearch.CONTACT && {
          isContactLoading: true,
        }),
        ...(action.payload.method === MethodSearch.STAFF && {
          isStaffLoading: true,
        }),
      };
    case UPDATE_APPOINTMENT_AVAILABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_SEARCH_CLIENT_SUCCESS:
      return {
        ...state,
        clientList: action.payload,
        isClientLoading: false,
      };

    case UPDATE_SEARCH_CONTACT_SUCCESS:
      return {
        ...state,
        isContactLoading: false,
        contactList: action.payload,
      };

    case UPDATE_SEARCH_SERVICE_SUCCESS:
      return {
        ...state,
        isServiceLoading: false,
        serviceList: action.payload,
      };

    case UPDATE_SEARCH_STAFF_SUCCESS:
      return {
        ...state,
        isStaffLoading: false,
        staffList: action.payload,
      };

    case UPDATE_APPOINTMENT_AVAILABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableTime: action.payload,
      };
    case UPDATE_APPOINTMENT_SEARCH_FAILURE:
      return {
        ...state,
        ...(action.payload as unknown as ResponseFailureType),
        isClientLoading: false,
        isServiceLoading: false,
        isContactLoading: false,
        isStaffLoading: false,
      };
    case UPDATE_APPOINTMENT_AVAILABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      };
    case RESET_AVAILABLE_TIME:
      return {
        ...state,
        availableTime: [],
      };
    case UPDATE_APPOINTMENT_REQUEST:
    case DELETE_APPOINTMENT_REQUEST:
      return {
        ...state,
        isUpdateLoading: true,
        isAppointmentAction: false,
      };
    case UPDATE_APPOINTMENT_SUCCESS:
    case DELETE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isUpdateLoading: false,
        isAppointmentAction: true,
        appointmentsError:
          state.appointmentsError?.filter(
            (item) => item.id !== action.payload,
          ) || [],
      };
    case UPDATE_APPOINTMENT_FAILURE:
    case DELETE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isUpdateLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
        isAppointmentAction: false,
      };
    case GET_APPOINTMENT_REQUEST:
      return {
        ...state,
        isGetLoading: true,
      };
    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        appointmens: action.payload,
      };
    case GET_APPOINTMENT_MOBILE_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        appointmensMobile: action.payload,
      };
    case GET_APPOINTMENT_SCHEDULE_SUCCESS:
      return {
        ...state,
        isGetLoading: false,
        schedule: action.payload,
      };
    case GET_APPOINTMENT_FAILURE:
      return {
        ...state,
        isGetLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      };
    case CREATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        isUpdateLoading: true,
        isAppointmentAction: false,
      };
    case CREATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isUpdateLoading: false,
        isAppointmentAction: true,
      };
    case CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        isUpdateLoading: false,
        isAppointmentAction: false,
        ...(action.payload as unknown as ResponseFailureType),
      };
    case GET_ONE_SERVICE_SUCCESS:
      return {
        ...state,
        service: action.payload,
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };

    case GET_HAS_CLIENT_FAILURE:
      return {
        ...state,
        ...(action.payload as unknown as ResponseFailureType),
      };
    case GET_HAS_CLIENT_SUCCESS:
      const { hasClient, ...errors } = state.errors as {
        [key: string]: string;
      };

      return {
        ...state,
        errors: {
          ...errors,
          ...(!!action.payload.message && {
            hasClient: [action.payload.message],
          }),
        },
      };

    case CLEAR_STORE:
      return {
        ...state,
        clientList: [],
        contactList: [],
        serviceList: [],
        availableTime: [],
        staffList: [],
        isAppointmentAction: false,
        service: null,
      };
    case SET_REPEAT_REQUEST:
      return {
        ...state,
        isRepeatRequest: action.payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      };

    default:
      return state;
  }
}

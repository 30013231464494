import { FC, memo, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DocumentStatus } from 'entities/common';
import {
  removeAdminCurrentSalonDocumentRequest,
  setAdminCurrentSalonDocumentConfirmationRequest,
} from 'store/Admin/Salons/Salons.action';
import { AdminSalonResponseDataProfileType } from 'store/Admin/Salons/Salons.type';
import { UISwitch } from 'ui';

import style from './style.module.scss';
import Card from './ui/Card';

type PropsType = {
  confirmed: DocumentStatus;
  documentLoading: null | number;
  documents?: AdminSalonResponseDataProfileType[];
  id: number;
  isConfirmLoading: boolean;
};

const AdminSalonSectionSix: FC<PropsType> = ({
  documents,
  confirmed,
  documentLoading,
  isConfirmLoading,
  id,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmed, setConfirmed] = useState(false);

  const handleRemove = (documentId: number) => {
    dispatch(removeAdminCurrentSalonDocumentRequest(documentId));
  };

  const handleDownload = (path: string) => {
    window.open(path);
  };

  const handleDocumentsConfirm = () => {
    dispatch(
      setAdminCurrentSalonDocumentConfirmationRequest(
        id,
        !isConfirmed ? DocumentStatus.ACCEPT : DocumentStatus.REJECTED,
      ),
    );
  };

  useEffect(() => {
    if (confirmed) {
      setConfirmed(confirmed === DocumentStatus.ACCEPT);
    }
  }, [confirmed]);

  return (
    <section className={style.six}>
      <h2>6. {t('ADMIN.PROFILE_SALON.SECTION_6.TITLE')}</h2>

      <div className={style.switchWrapper}>
        <span>{t('COMMON.REJECTED')}</span>
        <UISwitch
          checked={isConfirmed}
          loading={isConfirmLoading}
          onChange={handleDocumentsConfirm}
          className={style.switch}
          size={'default'}
          disabled={!documents || (!!documents && !documents.length)}
        />
        <span>{t('COMMON.CONFIRMED')}</span>
      </div>

      <div className={style.cardWrapper}>
        {documents?.map((item) => (
          <Card
            key={item.id}
            {...item}
            onRemove={handleRemove}
            onDownload={handleDownload}
            isLoading={documentLoading === item.id}
          />
        ))}
      </div>
    </section>
  );
};

export default memo(AdminSalonSectionSix);

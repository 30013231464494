/* eslint-disable @typescript-eslint/no-unsafe-return */
import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_SALONS_ERROR,
  GET_ADMIN_CURRENT_SALON_REQUEST,
  GET_ADMIN_CURRENT_SALON_FAILURE,
  GET_ADMIN_CURRENT_SALON_SUCCESS,
  GET_ADMIN_SALONS_REQUEST,
  GET_ADMIN_SALONS_FAILURE,
  GET_ADMIN_SALONS_SUCCESS,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_FAILURE,
  REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_SUCCESS,
  SET_ADMIN_CURRENT_SALON_REQUEST,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_FAILURE,
  SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_SUCCESS,
  SET_ADMIN_CURRENT_SALON_FAILURE,
  SET_ADMIN_CURRENT_SALON_SUCCESS,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_SUCCESS,
  SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_FAILURE,
} from './Salons.constant'
import {
  AdminSalonActionsTypes,
  AdminSalonState,
  IAdminSalonResponseData,
} from './Salons.type'

const initialState: AdminSalonState = {
  currentSalon: {} as IAdminSalonResponseData,
  errors: {},
  errorMessage: null,
  isLoading: false,
  isLockLoading: false,
  isConfirmLoading: false,
  isSubscriptionLoading: false,
  documentLoading: null,
  data: [],
  meta: {
    page: 0,
    perPage: 0,
    total: 0,
  },
}

export default function adminSalonsReducer(
  state = initialState,
  action: AdminSalonActionsTypes,
): AdminSalonState {
  switch (action.type) {
    case GET_ADMIN_SALONS_REQUEST:
    case GET_ADMIN_CURRENT_SALON_REQUEST:
      return { ...state, isLoading: true }
    case GET_ADMIN_SALONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.data,
        meta: {
          page: action.payload?.meta?.current_page,
          perPage: action.payload?.meta?.per_page,
          total: action.payload?.meta?.total,
        },
      }
    case GET_ADMIN_CURRENT_SALON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentSalon: action.payload,
      }
    case GET_ADMIN_SALONS_FAILURE:
    case GET_ADMIN_CURRENT_SALON_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case SET_ADMIN_CURRENT_SALON_REQUEST:
      return { ...state, isLockLoading: true }
    case SET_ADMIN_CURRENT_SALON_SUCCESS:
      return {
        ...state,
        isLockLoading: false,
        currentSalon: {
          ...state.currentSalon,
          active: action.payload,
        },
      }
    case SET_ADMIN_CURRENT_SALON_FAILURE:
      return {
        ...state,
        isLockLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST:
      return { ...state, isConfirmLoading: true }
    case SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isConfirmLoading: false,
        currentSalon: {
          ...state.currentSalon,
          profile: {
            ...state.currentSalon.profile,
            is_confirmed: action.payload,
          },
        },
      }
    case SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_FAILURE:
      return {
        ...state,
        isConfirmLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isSubscriptionLoading: true,
      }
    case SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriptionLoading: false,
        currentSalon: {
          ...state.currentSalon,
          profile: {
            ...state.currentSalon.profile,
            manual_free_trial: action.payload,
          },
        },
      }
    case SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        ...action.payload,
        isSubscriptionLoading: false,
      }
    case REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST:
      return {
        ...state,
        documentLoading: action.payload,
      }
    case REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentLoading: null,
        currentSalon: {
          ...state.currentSalon,
          profile: {
            ...state.currentSalon.profile,
            documents: state.currentSalon.profile.documents?.filter(
              (item) => item.id !== action.payload,
            ),
          },
        },
      }
    case REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_FAILURE:
      return {
        ...state,
        documentLoading: null,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case CLEAR_SALONS_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}

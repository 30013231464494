import { ChangeEvent, FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as DolarSVG } from 'assets/images/common/redesign/dolar.svg'
import { getSubCategoryRequest } from 'store/Manager/Services'
import { UICustomCard, UISelect, UITimePicker } from 'ui'
import UITextInput from 'ui/TextInput'
import { FORMAT_TIME_HOUR_WITH_MIN, STANDART_DATE } from 'utils'
import { ArrayHelper, DateHelper } from 'utils/helpers'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const ServicesInfo: FC<PropsType> = ({
  fullCategories,
  values,
  setValue,
  subcategories,
  errors,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleCategoryChange = (fullCategory: {
    label: string
    value: number
  }) => {
    dispatch(getSubCategoryRequest(+fullCategory.value))

    setValue((prevState) => ({
      ...prevState,
      category: { id: fullCategory.value, name: fullCategory.label },
      sections: [],
    }))
  }

  const handleSubCategoryChange = (
    sections: { label: string; value: number }[],
  ) => {
    setValue((prevState) => ({
      ...prevState,
      sections: sections.map((item) => ({
        id: item.value,
        name: item.label,
      })),
    }))
  }

  const handleChangePrice =
    (type: 'price' | 'discount_price') =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: inputValue } = event.target
      const reg = /^\d*(\.\d*)?$/

      if (reg.test(inputValue) || inputValue === '') {
        setValue((prevState) => ({
          ...prevState,
          [type]: inputValue.match(/(\d.*[,.]\d{2}).*/)?.[1] || inputValue,
        }))
      }
    }

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setValue((prevState) => ({
        ...prevState,
        duration: DateHelper.toFormat(date, FORMAT_TIME_HOUR_WITH_MIN),
      }))
    }
  }

  return (
    <UICustomCard
      title={
        <>
          <span>1. </span> {t('MANAGER.SERVICES.GENERAL_INFO')}
        </>
      }
    >
      <div className={styles.inputWrapp}>
        <UISelect
          options={ArrayHelper.getSelectOption(fullCategories)}
          value={values.category?.name}
          label={t('MANAGER.SERVICES.SELECT_CATEGORY')}
          necessaryLabel={`(${t('COMMON.NECESSARY')})`}
          placeholder={t('MANAGER.SERVICES.SELECT_CATEGORIES')}
          error={errors?.category_id}
          onChange={(_, fullCategory) =>
            handleCategoryChange(
              fullCategory as { label: string; value: number },
            )
          }
        />

        <UISelect
          mode="multiple"
          onChange={(_, sections) =>
            handleSubCategoryChange(
              sections as { label: string; value: number }[],
            )
          }
          value={ArrayHelper.getSelectOption(values.sections)}
          options={ArrayHelper.getSelectOption(subcategories)}
          label={t('MANAGER.SERVICES.SELECT_SUBCATEGORY')}
          necessaryLabel={`(${t('COMMON.NECESSARY')})`}
          placeholder={t('MANAGER.SERVICES.SELECT_SUBCATEGORIES')}
          error={errors?.sections}
          showSearch={false}
        />

        <UITextInput
          text={t('MANAGER.SERVICES.SERVICE_NAME')}
          placeholder={t('MANAGER.SERVICES.SERVICE_NAME')}
          necessaryLabel={`(${t('COMMON.NECESSARY')})`}
          onChange={(e) => setValue({ ...values, name: e.target.value })}
          value={values.name}
          error={errors?.name}
        />

        <UITimePicker
          label={t('MANAGER.SERVICES.DURATION')}
          placeholder="08:00"
          necessaryLabel={`(${t('COMMON.NECESSARY')})`}
          {...(values.duration && {
            defaultValuePicker: new Date(
              `${STANDART_DATE} ${values.duration}`,
            ) as unknown as string,
          })}
          onChange={handleDateChange}
          error={errors?.duration}
        />

        <UITextInput
          prefix={<DolarSVG />}
          text={t('MANAGER.SERVICES.PRICING')}
          necessaryLabel={`(${t('COMMON.NECESSARY')})`}
          placeholder="0.00"
          value={values.price}
          onChange={handleChangePrice('price')}
          error={errors?.price}
        />

        <UITextInput
          prefix={<DolarSVG />}
          text={t('MANAGER.SERVICES.DISCOUNTED_PRICING')}
          placeholder="0.00"
          pattern="[0-9]*"
          value={values.discount_price}
          error={errors?.discount_price}
          onChange={handleChangePrice('discount_price')}
          {...(values.price &&
            values.discount_price &&
            +values.price <= +values.discount_price && {
              status: 'error',
            })}
        />
      </div>
    </UICustomCard>
  )
}

export default ServicesInfo

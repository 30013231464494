import { FC, ReactNode, useEffect, useState } from 'react'

import { Spin, Upload, UploadFile } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/lib/upload'
import cn from 'classnames'

import { ReactComponent as UploadSVG } from 'assets/images/common/redesign/uploadImage.svg'
import { FullMedia } from 'entities/common'
import { SalonDataType } from 'views/manager/ProfileSalon/index.type'
import WidgetModalImageEditor from 'widgets/Modal/ImageEditor'

import style from './style.module.scss'

type PropsType = {
  btnUpload?: ReactNode
  className?: string
  fileData?: FullMedia | null
  handleChange?:
    | ((info: UploadChangeParam<UploadFile<any>>) => void)
    | undefined
  heightRatio?: number
  isCircle?: boolean
  isHideBtn?: boolean
  isLoading?: boolean
  isResetFileList?: boolean
  linkType?: string
  main?: boolean
  name?: string
  pathImg?: string
  setIsResetFileList?: (arg: boolean) => void
  setValues?: (value: React.SetStateAction<any>) => void
  type?: 'text' | 'picture-card'
  values?: SalonDataType
  widthRatio?: number
}

const getSrcFromFile = (file: UploadFile<any>) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.readAsDataURL(file.originFileObj as RcFile)
    reader.onload = () => resolve(reader.result)
  })
}

const UIUpload: FC<PropsType> = ({
  fileData,
  setValues,
  values,
  isLoading,
  pathImg,
  className,
  widthRatio = 1,
  heightRatio = 1,
  isCircle,
  btnUpload,
  setIsResetFileList,
  isResetFileList,
  linkType,
  type = 'picture-card',
  handleChange,
  isHideBtn = true,
  name,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const onRemove = () => {
    if (setValues) {
      setValues({ ...values, logo: { id: null, path: '' } })
    }

    setFileList([])
  }

  useEffect(() => {
    if (isResetFileList && setIsResetFileList) {
      setFileList([])
      setIsResetFileList(false)
    }
  }, [isResetFileList, setIsResetFileList])

  useEffect(() => {
    if (pathImg) {
      setFileList([{ url: pathImg } as UploadFile])
    }
  }, [pathImg])

  useEffect(() => {
    if (fileData) {
      setFileList([
        { url: fileData.path, id: fileData.id } as unknown as UploadFile,
      ])
    }
  }, [fileData])

  const uploadButton = (
    <div className={style.uploadButton}>
      {isLoading && <Spin />}
      {!isLoading && (
        <>
          <UploadSVG />
        </>
      )}
    </div>
  )

  const handleUpload = async (file: UploadFile<any>) => {
    const src = file.url || ((await getSrcFromFile(file)) as string)
    const imgWindow = window.open(src)

    if (imgWindow) {
      const image = new Image()

      image.src = src
      imgWindow.document.write(image.outerHTML)
    } else {
      window.location.href = src
    }
  }

  return (
    <div className={cn(style.uploadWrap, className)}>
      <WidgetModalImageEditor
        widthRatio={widthRatio}
        heightRatio={heightRatio}
        isCircle={isCircle}
        linkType={linkType}
        name={name}
      >
        <Upload
          accept=".jpg, .jpeg"
          listType={type}
          customRequest={(file) => handleUpload(file.file as RcFile)}
          onChange={handleChange}
          fileList={fileList}
          maxCount={1}
          onRemove={onRemove}
        >
          {isHideBtn
            ? !fileList.length && (btnUpload || uploadButton)
            : btnUpload || uploadButton}
        </Upload>
      </WidgetModalImageEditor>
    </div>
  )
}

export default UIUpload

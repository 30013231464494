import AlertsList from 'widgets/AlertsList';
import WidgetModal from 'widgets/Modal';
import Routes from 'widgets/Routes';
import ValidationListener from 'widgets/Validation';

const App = () => {
  return (
    <div className="App">
      <Routes />
      <AlertsList />
      <WidgetModal />
      <ValidationListener />
    </div>
  );
};

export default App;

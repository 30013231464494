/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { FC, memo } from 'react';

import { Select } from 'antd';

import { VALIDATION_CLASS } from 'utils';

import { PropsType } from './index.type';
import styles from './style.module.scss';
import { StringHelper } from 'utils/helpers';
import classNames from 'classnames';

const UISelect: FC<PropsType> = ({
  disabled,
  options,
  placeholder,
  onChange,
  defaultValue,
  value,
  onFocus,
  className,
  mode,
  label,
  necessaryLabel,
  showSearch,
  isLoading,
  onSearch,
  filterOption,
  onBlur,
  error,
  dropdownRender,
  classNameWrapper,
}) => {
  return (
    <div className={classNames(styles.select, classNameWrapper)}>
      {label && (
        <div className={styles.label}>
          {label}
          {'  '}
          {necessaryLabel && (
            <span className={styles.necessaryLabel}>{necessaryLabel}</span>
          )}
        </div>
      )}

      <Select
        disabled={disabled}
        onChange={onChange}
        className={classNames(styles.select, className, {
          [VALIDATION_CLASS]: error,
        })}
        defaultValue={defaultValue}
        onFocus={onFocus}
        placeholder={placeholder}
        value={value}
        options={options}
        onBlur={onBlur}
        mode={mode}
        showSearch={showSearch}
        loading={isLoading}
        onSearch={onSearch}
        filterOption={filterOption}
        status={error ? 'error' : undefined}
        dropdownRender={dropdownRender}
      />

      {error && (
        <p className="text-error desc-1">
          {StringHelper.getFisrtMessage(error)}
        </p>
      )}
    </div>
  );
};

export default memo(UISelect);

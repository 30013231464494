import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_NOTIFICATION_ERROR,
  GET_ADMIN_NOTIFICATIONS_REQUEST,
  GET_ADMIN_NOTIFICATIONS_FAILURE,
  GET_ADMIN_NOTIFICATIONS_SUCCESS,
  GET_ADMIN_SIDEBAR_NOTIFICATIONS_FAILURE,
  GET_ADMIN_SIDEBAR_NOTIFICATIONS_SUCCESS,
  GET_ADMIN_SIDEBAR_NOTIFICATIONS_REQUEST,
} from './Notifications.constant'
import {
  AdminNotificationsResponseType,
  IAdminNotificationsType,
} from './Notifications.type'

export const getAdminNotificationsRequest = (queryString: string) => ({
  type: GET_ADMIN_NOTIFICATIONS_REQUEST,
  payload: queryString,
})

export const getAdminNotificationsSuccess = (
  data: AdminNotificationsResponseType,
) => ({
  type: GET_ADMIN_NOTIFICATIONS_SUCCESS,
  payload: data,
})

export const getAdminNotificationsFailure = (error: ResponseFailureType) => ({
  type: GET_ADMIN_NOTIFICATIONS_FAILURE,
  payload: error,
})

export const clearNotificationError = () => ({
  type: CLEAR_NOTIFICATION_ERROR,
})

export const getAdminSidebarNotificationsRequest = () => ({
  type: GET_ADMIN_SIDEBAR_NOTIFICATIONS_REQUEST,
})

export const getAdminSidebarNotificationsSuccess = (
  payload: IAdminNotificationsType,
) => ({
  type: GET_ADMIN_SIDEBAR_NOTIFICATIONS_SUCCESS,
  payload,
})

export const getAdminSidebarNotificationsFailure = (
  payload: ResponseFailureType,
) => ({
  type: GET_ADMIN_SIDEBAR_NOTIFICATIONS_FAILURE,
  payload,
})

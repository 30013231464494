/* eslint-disable no-unsafe-optional-chaining */
import { ChangeEvent, FC, memo, useMemo, useEffect, useState } from 'react';

import { ReactComponent as ArrowSVG } from 'assets/images/common/redesign/arrowBottom.svg';
import { ReactComponent as PlusSVG } from 'assets/images/common/redesign/plusCircle.svg';
import { ReactComponent as WarningSVG } from 'assets/images/common/redesign/alert-circle.svg';

import { Spin, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAppointmentRequest,
  getHasClientRequest,
  updateAppointmentSearchRequest,
} from 'store/Manager/Appointments/Appointments.action';
import { MethodSearch } from 'store/Manager/Appointments/Appointments.type';
import { IRootState } from 'store/Root.reducer';
import { UIButton, UIDatePicker, UIPhoneInput } from 'ui';
import UITextInput from 'ui/TextInput';
import { FORMAT_DATE, FORMAT_DATE_WEEK_DAY_MONTH } from 'utils';
import { DateHelper } from 'utils/helpers';

import { PropsType } from './index.type';
import styles from '../style.module.scss';
import { INITIAL_SERVICE, INITIAL_STATIC_VALUES } from './constant';
import { CloseOutlined } from '@ant-design/icons';
import {
  clearBasketActionSuccess,
  clearBasketStore,
  createSalonOrderRequest,
  deleteBasketServiceRequest,
  getBasketRequest,
  getBasketTimeRequest,
  getBasketTotalRequest,
  setIsTimeRequest,
} from 'store/Manager/Basket/Basket.action';
import UIAppointmendarSidebarAddCart from './Cart';
import UIAppointmendarSidebarAddTime from './Time';
import {
  IBasketResponseItem,
  ICreateSalonOrderDataRequest,
} from 'store/Manager/Basket/Basket.type';
import UIAppointmendarSidebarFooter from '../Footer';
import CustomSearch from 'ui/CustomSearch';

const UIAppointmendarSidebarAdd: FC<PropsType> = ({
  setIsDrawer,
  eventDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    basket: {
      basketTime,
      basket,
      errors,
      isLoading,
      total,
      isActionSuccess,
      isSaveSuccess,
      isTimeRequest,
    },
    appointments: { isClientLoading, clientList, errors: appointmentsError },
  } = useSelector((store: IRootState) => ({
    basket: store.basket,
    appointments: store.appointments,
  }));

  const validateErrors = errors as { [field: string]: string[] };

  const [activeTab, setActiveTab] = useState('1');
  const [tabDate, setTabDate] = useState<Date | string>(
    eventDate || new Date(),
  );
  const [values, setValues] = useState<Partial<IBasketResponseItem>[]>([]);
  const [staticValues, setStaticValues] = useState(INITIAL_STATIC_VALUES);

  useEffect(() => {
    if (eventDate) {
      setTabDate(eventDate);
    }
  }, [eventDate]);

  const [selectTime, setSelectTime] = useState<Date | string | null>(null);

  const dateFromTo = DateHelper.toFormat(tabDate, FORMAT_DATE);
  const query = `?from=${dateFromTo}&to=${dateFromTo}`;

  const handleClientChange = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(tabDate, FORMAT_DATE),
        },
        method: MethodSearch.CLIENT,
      }),
    );
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const disabledDate = (date: Date) => {
    const ONE_DAY = 86300000;

    return date && date.valueOf() <= new Date().valueOf() - ONE_DAY;
  };

  const onDateChange = (date: string) => {
    setTabDate(date);
  };

  const handleAddService = () => {
    setValues((prevState) => [
      ...prevState,
      { ...INITIAL_SERVICE, id: `new${prevState.length + 1}` },
    ]);
  };

  const handleServiceDelete = (id: number | string) => {
    if (String(id).includes('new')) {
      setValues((prevState) => prevState.filter((item) => item.id !== id));
    } else {
      dispatch(deleteBasketServiceRequest(Number(id)));
    }
  };

  const handleCheckHasClient = () => {
    if (
      staticValues?.contact_name &&
      staticValues?.contact_phone?.replace(/\D+/g, '').length === 10
    ) {
      dispatch(
        getHasClientRequest({
          name: staticValues?.contact_name,
          phone: `52-${staticValues?.contact_phone}`,
        }),
      );
    }
  };

  const handleSave = () => {
    const objToRequest = {
      date_from: DateHelper.toFormat(tabDate, FORMAT_DATE),
      time_from: DateHelper.toFormat(selectTime || new Date(), 'HH:mm'),
      ...(activeTab === '1' && {
        client:
          {
            id: staticValues.client_id,
            type: staticValues.clientType,
          } || null,
      }),
      ...(activeTab === '2' && {
        contact_name: staticValues.contact_name.trim(),
        contact_phone: `52-${staticValues.contact_phone}`,
      }),
    };

    if (selectTime) {
      dispatch(
        createSalonOrderRequest(objToRequest as ICreateSalonOrderDataRequest),
      );
    }
  };

  useEffect(() => {
    if (query && basket?.items?.length) {
      dispatch(getBasketTimeRequest(query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tabDate]);

  useEffect(() => {
    if (basket?.items && isTimeRequest) {
      if (query) {
        dispatch(getBasketTimeRequest(query));
        dispatch(setIsTimeRequest(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basket?.items, isTimeRequest]);

  useEffect(() => {
    dispatch(getBasketRequest());
    dispatch(getBasketTotalRequest());
  }, [dispatch]);

  useEffect(() => {
    if (basket?.items) {
      setValues(basket?.items);
    }
  }, [basket?.items]);

  useEffect(() => {
    if (isActionSuccess) {
      if (query) {
        dispatch(clearBasketActionSuccess());
      }
    }
  }, [dispatch, isActionSuccess, query]);

  useEffect(() => {
    const queryAppointment = `from=${dateFromTo}&to=${dateFromTo}`;

    if (isSaveSuccess) {
      dispatch(getAppointmentRequest({ query: queryAppointment }));
      setIsDrawer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSaveSuccess, query, setIsDrawer]);

  useEffect(() => {
    return () => {
      dispatch(clearBasketStore());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectTime(null);
    setValues((prevState) => {
      return prevState.map((item) => ({
        ...item,
        time: { from: null, to: null },
      }));
    });
  }, [tabDate, isActionSuccess]);

  const itemsList = [
    {
      key: '1',
      label: t('MANAGER.STAFF.ADD_CLIENT'),
      children: (
        <>
          <div className={styles.wrappPicker}>
            <UIDatePicker
              format={FORMAT_DATE_WEEK_DAY_MONTH}
              value={tabDate as Date}
              onChange={onDateChange}
              className={styles.datePicker}
              disabledDate={disabledDate}
            />
            <ArrowSVG />
          </div>

          {/* TODO remove requests onFocus & clear onBlur */}
          <CustomSearch
            label={t('MANAGER.STAFF.CLIENT_NAME')}
            placeholder={t('MANAGER.STAFF.FULL_NAME_PLACEHOLDER')}
            options={clientList?.map((item) => ({
              value: item.id,
              label: item.name,
              subtitle: item.phone,
              type: item.type,
            }))}
            onSelect={(value, obj) => {
              setStaticValues({
                ...staticValues,
                client_id: (Number(value) as unknown as null) || null,
                contact_name: obj.label,
                ...(!!obj.subtitle && { contact_phone: obj.subtitle }),
                clientType: (obj as unknown as { type: string }).type,
              });
            }}
            onFocus={handleClientChange}
            select={
              staticValues.client_id
                ? {
                    label: staticValues.contact_name,
                    value: staticValues.client_id,
                    subtitle: staticValues.contact_phone,
                    type: staticValues.clientType,
                  }
                : undefined
            }
            isLoading={isClientLoading}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('MANAGER.STAFF.ADD_CONTACT'),
      children: (
        <>
          <div className={styles.wrappPicker}>
            <UIDatePicker
              format={FORMAT_DATE_WEEK_DAY_MONTH}
              className={styles.datePicker}
              value={tabDate as Date}
              onChange={(date) => onDateChange(date as string)}
              disabledDate={disabledDate}
            />
            <ArrowSVG />
          </div>

          <UITextInput
            text={t('MANAGER.STAFF.FULL_NAME')}
            placeholder={t('MANAGER.STAFF.FULL_NAME_PLACEHOLDER')}
            className={styles.mb20}
            value={staticValues.contact_name?.trimStart()}
            onChange={(e) =>
              setStaticValues((prevState) => ({
                ...prevState,
                contact_name: e.target.value,
              }))
            }
            onBlur={handleCheckHasClient}
            error={validateErrors?.contact_name}
          />

          <UIPhoneInput
            label={t('COMMON.PHONE_NUMBER')}
            className={styles.mb20}
            value={staticValues.contact_phone}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setStaticValues((prevState) => ({
                ...prevState,
                contact_phone: e.target.value,
              }))
            }
            onBlur={handleCheckHasClient}
            error={validateErrors?.contact_phone}
          />

          {!!appointmentsError?.['hasClient' as keyof typeof errors] && (
            <div className={styles['tab__warning-alert']}>
              <WarningSVG className={styles['tab__warning-alert__icon']} />

              <span>
                {appointmentsError?.['hasClient' as keyof typeof errors]?.[0]}
              </span>
            </div>
          )}
        </>
      ),
    },
  ];

  const setDisableBtn = useMemo(() => {
    if (activeTab === '1') {
      return !(staticValues.client_id && selectTime);
    }

    if (activeTab === '2') {
      return !(staticValues.contact_name && selectTime);
    }

    return false;
  }, [
    selectTime,
    staticValues.client_id,
    staticValues.contact_name,
    activeTab,
  ]);

  return (
    <>
      <Tabs
        className={styles.tab}
        defaultActiveKey="1"
        onChange={(key) => handleTabChange(key)}
        activeKey={activeTab}
        items={itemsList}
      />
      <Spin spinning={isLoading}>
        {values?.map((item, index) => {
          return (
            <div className={styles.servicesWrapper} key={item.id}>
              <UIAppointmendarSidebarAddCart
                key={item.id}
                {...item}
                setValues={setValues}
                values={values}
                tabDate={tabDate}
              />

              {values?.lastIndexOf(values?.[values?.length - 1]) !== index && (
                <div className={styles.dashLine}></div>
              )}

              {index !== 0 && (
                <div
                  className={styles.serviceDelete}
                  onClick={() => handleServiceDelete(item?.id || 0)}
                >
                  <CloseOutlined />
                </div>
              )}
            </div>
          );
        })}
      </Spin>

      <UIButton
        type="text"
        className={styles.serviceAddBtn}
        handler={handleAddService}
        icon={<PlusSVG />}
        label={t('MANAGER.BUTTON.ADD_SERVICE')}
      />

      <UIAppointmendarSidebarAddTime
        basketTime={basketTime}
        tabDate={tabDate}
        setSelectTime={setSelectTime}
        selectTime={selectTime}
        isLoading={isLoading}
      />

      <UIAppointmendarSidebarFooter
        isLoading={isLoading}
        total={total?.total}
        handleSave={handleSave}
        isAddAppointment={true}
        isBtnDisabled={setDisableBtn}
      />
    </>
  );
};

export default memo(UIAppointmendarSidebarAdd);

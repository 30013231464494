import { FC, memo } from 'react'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'

import { UIDatePicker, UITextArea, UITimePicker, UIUpload } from 'ui'
import { FORMAT_DATE, FORMAT_TIME_HOUR_WITH_MIN } from 'utils'
import { DateHelper } from 'utils/helpers'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const BlogArticlecContent: FC<PropsType> = ({ values, setValues }) => {
  const { t } = useTranslation()

  const handleTimeChange = (date: Date | null) => {
    setValues({
      ...values,
      timeAdded: DateHelper.toFormat(date as Date, FORMAT_TIME_HOUR_WITH_MIN),
    })
  }

  const handleDateChange = (date: Date | null) => {
    setValues({
      ...values,
      dateAdded: DateHelper.toFormat(date as Date, FORMAT_DATE),
    })
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>{t('ADMIN.BLOG.TOP_CONTENT')}</p>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />

      <p className={styles.label}>{t('ADMIN.BLOG.MAIN_PICTURE')}</p>
      <UIUpload className={styles.upload} />

      <p className={styles.label}>{t('ADMIN.BLOG.CONTENT')}</p>
      <Editor
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />

      <p className={styles.label}>{t('ADMIN.BLOG.ANNOUNCEMENT')}</p>
      <UITextArea
        placeholder={t('ADMIN.BLOG.ANNOUNCEMENT')}
        className={styles.announcement}
        onChange={(e) => setValues({ ...values, announcement: e.target.value })}
      />

      <p className={styles.label}>{t('ADMIN.BLOG.TIME_OF_ADDITION')}</p>
      <div className={styles.timeWrapp}>
        <UIDatePicker
          format={FORMAT_DATE}
          onChange={(date: Date) => handleDateChange(date)}
        />

        <UITimePicker onChange={(date) => handleTimeChange(date)} />
      </div>
    </div>
  )
}

export default memo(BlogArticlecContent)

import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import UIBusinessHours from 'ui/BusinessHours'

import { PropsType } from './index.type'

const ProfileSalonBusinessHoursList: FC<PropsType> = ({
  customize,
  setValues,
  values,
  setIsHourError,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {!customize && (
        <div>
          {[t('MANAGER.PROFILE_SALON.MONDAY_FRIDAY')].map((text, index) => {
            return (
              <UIBusinessHours
                setValues={setValues}
                values={values}
                day={index + 1}
                customize
                text={text}
                key={text}
                setIsHourError={setIsHourError}
              />
            )
          })}
        </div>
      )}

      {customize &&
        [
          t('MANAGER.PROFILE_SALON.MONDAY'),
          t('MANAGER.PROFILE_SALON.TUESDAY'),
          t('MANAGER.PROFILE_SALON.WEDNESDAY'),
          t('MANAGER.PROFILE_SALON.THURSDAY'),
          t('MANAGER.PROFILE_SALON.FRIDAY'),
        ].map((text, index) => {
          return (
            <UIBusinessHours
              setValues={setValues}
              setIsHourError={setIsHourError}
              values={values}
              day={index + 1}
              text={text}
              key={text}
            />
          )
        })}

      <div>
        {[
          t('MANAGER.PROFILE_SALON.SATURDAY'),
          t('MANAGER.PROFILE_SALON.SUNDAY'),
        ].map((text, index) => {
          return (
            <UIBusinessHours
              setValues={setValues}
              values={values}
              day={6 + index}
              text={text}
              key={text}
              setIsHourError={setIsHourError}
            />
          )
        })}
      </div>
    </>
  )
}

export default ProfileSalonBusinessHoursList

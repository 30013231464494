export const GET_MANAGER_CLIENTS = '@clients/GET_MANAGER_CLIENTS'
export const GET_MANAGER_CLIENTS_SUCCESS =
  '@clients/GET_MANAGER_CLIENTS_SUCCESS'
export const GET_MANAGER_CLIENTS_FAILURE =
  '@clients/GET_MANAGER_CLIENTS_FAILURE'

export const GET_MANAGER_USERS = '@clients/GET_MANAGER_USERS'
export const GET_MANAGER_USERS_SUCCESS = '@clients/GET_MANAGER_USERS_SUCCESS'
export const GET_MANAGER_USERS_FAILURE = '@clients/GET_MANAGER_USERS_FAILURE'

export const CLEAR_CLIENTS_ERROR = '@clients/CLEAR_CLIENTS_ERROR'

export const UPDATE_MANAGER_PROFILE_REQUEST =
  '@salon/UPDATE_MANAGER_PROFILE_REQUEST'
export const UPDATE_MANAGER_PROFILE_SUCCESS =
  '@salon/UPDATE_MANAGER_PROFILE_SUCCESS'
export const UPDATE_MANAGER_PROFILE_FAILURE =
  '@salon/UPDATE_MANAGER_PROFILE_FAILURE'

export const GET_MANAGER_PROFILE_REQUEST = '@salon/GET_MANAGER_PROFILE_REQUEST'
export const GET_MANAGER_PROFILE_SUCCESS = '@salon/GET_MANAGER_PROFILE_SUCCESS'
export const GET_MANAGER_PROFILE_FAILURE = '@salon/GET_MANAGER_PROFILE_FAILURE'

export const GET_MANAGER_PHONE_REQUEST = '@salon/GET_MANAGER_PHONE_REQUEST'
export const GET_MANAGER_PHONE_SUCCESS = '@salon/GET_MANAGER_PHONE_SUCCESS'
export const GET_MANAGER_PHONE_FAILURE = '@salon/GET_MANAGER_PHONE_FAILURE'

export const CREATE_MANAGER_PHONE_REQUEST =
  '@salon/CREATE_MANAGER_PHONE_REQUEST'
export const CREATE_MANAGER_PHONE_SUCCESS =
  '@salon/CREATE_MANAGER_PHONE_SUCCESS'
export const CREATE_MANAGER_PHONE_FAILURE =
  '@salon/CREATE_MANAGER_PHONE_FAILURE'

export const DELETE_MANAGER_PHONE_REQUEST =
  '@salon/DELETE_MANAGER_PHONE_REQUEST'
export const DELETE_MANAGER_PHONE_SUCCESS =
  '@salon/DELETE_MANAGER_PHONE_SUCCESS'
export const DELETE_MANAGER_PHONE_FAILURE =
  '@salon/DELETE_MANAGER_PHONE_FAILURE'

export const UPDATE_MANAGER_PHONE_REQUEST =
  '@salon/UPDATE_MANAGER_PHONE_REQUEST'
export const UPDATE_MANAGER_PHONE_SUCCESS =
  '@salon/UPDATE_MANAGER_PHONE_SUCCESS'
export const UPDATE_MANAGER_PHONE_FAILURE =
  '@salon/UPDATE_MANAGER_PHONE_FAILURE'

export const RESET_PHONE = '@salon/RESET_PHONE'

export const RESET_ERROR = '@salon/RESET_ERROR'

export const SET_IMAGE_LIST = '@salon/SET_IMAGE_LIST'

import { FC, useEffect, useState, useCallback, useMemo } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as PlusSVG } from 'assets/images/common/redesign/plusCircle.svg'
import { RequestEditShiftType } from 'modules/manager/staff/Shifts/index.type'
import { updateShiftRequest } from 'store/Manager/Shift/Shift.action'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomModal, UISwitch, UITimePicker } from 'ui'
import {
  FORMAT_DATE_CUSTOM,
  FORMAT_TIME_HOUR_WITH_MIN,
  STANDART_DATE,
} from 'utils'
import { DateHelper, WeekTimeHelper } from 'utils/helpers'

import WidgetModalEditShiftBreak from './Break'
import { ExtendedPropsType } from './index.type'
import styles from './style.module.scss'

type PropsType = {
  isLoading: boolean
  valueEvent: ExtendedPropsType | null
}

const WidgetModalEditShift: FC<PropsType> = ({ valueEvent, isLoading }) => {
  const { isRepeatRequest } = useSelector(
    (state: IRootState) => state.appointments,
  )

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isSwitch, setIsSwitch] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [values, setValues] = useState<ExtendedPropsType | null>(null)

  const objToRequest: RequestEditShiftType = useMemo(
    () => ({
      staff_id: values?.shiftId,
      from: values?.date || '',
      work: {
        from: values?.work?.from || null,
        to: values?.work?.to || null,
      },
      break:
        values?.break?.map((item) => ({
          from: item.from || '',
          to: item.to || '',
        })) || [],
    }),
    [
      values?.break,
      values?.date,
      values?.shiftId,
      values?.work?.from,
      values?.work?.to,
    ],
  )

  const handleBack = () => {
    dispatch(hideModal())
  }

  const renderTitle = () => {
    return (
      <h2 className={styles.title}>
        {t('COMMON.EDIT_SHIFTS')} -&#160;
        <span className={styles.titleName}>{valueEvent?.staffName} -</span>
        {DateHelper.toFormat(valueEvent?.date, FORMAT_DATE_CUSTOM)}
      </h2>
    )
  }

  const handleWorkChange = (time: Date | null, name: 'from' | 'to') => {
    if (time) {
      setValues({
        ...values,
        work: {
          ...values?.work,
          [name]: DateHelper.toFormat(time, FORMAT_TIME_HOUR_WITH_MIN) || '',
        },
      })
    }
  }

  const handleBreakChange = useCallback(
    (time: Date | null, name: 'from' | 'to', index: number) => {
      if (time) {
        setValues({
          ...values,
          break: values?.break?.map((item, idx: number) => {
            if (idx === index) {
              // eslint-disable-next-line no-param-reassign
              item[name] = DateHelper.toFormat(time, FORMAT_TIME_HOUR_WITH_MIN)
            }

            return item
          }),
        })
      }
    },
    [values],
  )

  const handleClick = () => {
    if (valueEvent) {
      setValues({
        ...values,
        break: [...(values?.break || []), { from: null, to: null }],
      })
    }
  }

  const handleSwitchChange = () => {
    setIsSwitch(false)
    setValues({
      ...values,
      shiftId: values?.shiftId,
      date: values?.date,
      work: {
        from: null,
        to: null,
      },
      break: [],
    })
  }

  const handleSave = () => {
    dispatch(updateShiftRequest(objToRequest))
  }

  useEffect(() => {
    if (isRepeatRequest) {
      dispatch(updateShiftRequest(objToRequest))
    }
  }, [dispatch, isRepeatRequest, objToRequest])

  useEffect(() => {
    setValues({
      ...valueEvent,
      work: {
        from:
          valueEvent?.work?.from &&
          DateHelper.toFormat(
            new Date(`${STANDART_DATE} ${valueEvent?.work?.from}`),
            FORMAT_TIME_HOUR_WITH_MIN,
          ),
        to:
          valueEvent?.work?.to &&
          DateHelper.toFormat(
            new Date(`${STANDART_DATE} ${valueEvent?.work?.to}`),
            FORMAT_TIME_HOUR_WITH_MIN,
          ),
      },
      break: valueEvent?.break?.map((item) => ({
        from: DateHelper.toFormat(
          new Date(`${STANDART_DATE} ${item?.from}`),
          FORMAT_TIME_HOUR_WITH_MIN,
        ),
        to: DateHelper.toFormat(
          new Date(`${STANDART_DATE} ${item?.to}`),
          FORMAT_TIME_HOUR_WITH_MIN,
        ),
      })),
    })
  }, [valueEvent])

  const timeStart = values?.work?.from

  const timeEnd = values?.work?.to

  return (
    <UICustomModal title={renderTitle()}>
      <Spin spinning={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.switch}>
            <UISwitch
              checked={!!(timeStart || timeEnd) || isSwitch}
              onChange={handleSwitchChange}
              disabled={!(timeStart || timeEnd)}
            />
          </div>
          <div className={styles.text}>{t('MANAGER.STAFF.SHIFTS')}</div>
          <UITimePicker
            placeholder={t('COMMON.SELECT')}
            format="HH:mm"
            value={
              (timeStart as unknown as Date) &&
              new Date(`${STANDART_DATE} ${timeStart}`)
            }
            onChange={(e: Date | null) => handleWorkChange(e, 'from')}
            disabledTime={() =>
              WeekTimeHelper.onDisabledTime(
                timeEnd ? WeekTimeHelper.disabledTimeStart(timeEnd) : [],
                timeEnd && timeStart
                  ? WeekTimeHelper.disabledTimeStart(timeEnd, timeStart, true)
                  : [],
              )
            }
            {...(timeStart &&
              timeEnd &&
              timeStart >= timeEnd && { status: 'error' })}
          />
          <span className={styles.dash}></span>
          <UITimePicker
            placeholder={t('COMMON.SELECT')}
            format="HH:mm"
            value={
              (timeEnd as unknown as Date) &&
              new Date(`${STANDART_DATE} ${timeEnd}`)
            }
            onChange={(e: Date | null) => handleWorkChange(e, 'to')}
            disabledTime={() =>
              WeekTimeHelper.onDisabledTime(
                timeStart ? WeekTimeHelper.disabledTimeEnd(timeStart) : [],
                timeEnd && timeStart
                  ? WeekTimeHelper.disabledTimeEnd(timeStart, timeEnd, true)
                  : [],
              )
            }
          />
          <UIButton
            type="text"
            label={t('MANAGER.PROFILE_SALON.ADD_BREAK')}
            disabled={!(timeStart && timeEnd)}
            handler={handleClick}
            icon={<PlusSVG />}
            isGreen
          />
        </div>
        <WidgetModalEditShiftBreak
          handleBreakChange={handleBreakChange}
          setIsDisabled={setIsDisabled}
          valueEvent={values}
          setValueEvent={setValues}
        />
        <div className={styles.line}></div>
        <div className={styles.btn}>
          <UIButton
            handler={handleBack}
            type="outline"
            label={t('ADMIN.BUTTON.CANCEL')}
          />
          <UIButton
            handler={handleSave}
            disabled={isDisabled}
            label={t('ADMIN.BUTTON.SAVE')}
          />
        </div>
      </Spin>
    </UICustomModal>
  )
}

export default WidgetModalEditShift

import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'

import { RightOutlined } from '@ant-design/icons'
import cn from 'classnames'

import useResponsive from 'hooks/useResponsive'

import styles from './style.module.scss'

export type PropsType = {
  activeId?: string
  categories: { id: string; name: string }[]
  handler: (id: string) => void
  setIsMobileOpen: Dispatch<SetStateAction<boolean>>
}

const OrdersCards: FC<PropsType> = ({
  activeId,
  handler,
  categories,
  setIsMobileOpen,
}) => {
  const { isMobile, isTablet } = useResponsive()

  const handlerClick = useCallback(
    (id: string) => {
      if (isMobile || isTablet) {
        setIsMobileOpen(true)
      }

      handler(id)
    },
    [handler, isMobile, isTablet, setIsMobileOpen],
  )

  const renderBlock = useMemo(
    () =>
      categories.map((item) => (
        <div
          className={cn(styles.wrapper, styles.border, {
            [styles.activeBorder]: activeId === item.id,
          })}
          onClick={() => handlerClick(item.id)}
          key={item.id}
        >
          <div className={styles.block}>
            <div className={styles.wrapperName}>
              <div className={styles.name}>{item.name}</div>
            </div>
          </div>

          <RightOutlined />
        </div>
      )),
    [activeId, categories, handlerClick],
  )

  return <div className={styles.listWrapp}>{renderBlock}</div>
}

export default OrdersCards

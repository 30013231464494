/* eslint-disable @typescript-eslint/naming-convention */
import {
  FC,
  memo,
  useState,
  SyntheticEvent,
  useMemo,
  Fragment,
  useEffect,
} from 'react'

import { Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import TextBlock from 'modules/admin/Faq/TextBlock'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import { OnUploadFileChange } from 'ui/UploadFile/index.type'
import { SEO_INPUTS } from 'utils'
import { AboutUsBody, TemplateBodyType } from 'views/admin/Pages/index.type'

import style from './style.module.scss'
import { INITAL_SEO_STATE } from '../CustomerTemplate/index.type'
import TemplateField from '../ui/TemplateField'
import {
  InputTypes,
  TemplateFieldsPropsTypeChange,
  TYPE_FILE,
  TYPE_TEXTAREA,
} from '../ui/TemplateField/index.type'
import { addPrefixSeo } from 'utils/helpers/adminTemplate'

const INITIAL_STATE: AboutUsBody = {
  section_1_title: '',
  section_1_description: '',
  section_1_image_1: undefined,
  section_1_image_2: undefined,
  section_2_title: '',
  section_2_description: '',
  section_2_image: undefined,
  section_3_title: '',
  section_3_description: '',
  section_3_image: undefined,
  ...INITAL_SEO_STATE,
}

type PropTypes = {
  onSave: (body: TemplateBodyType) => void
}

const AboutUsTemplate: FC<PropTypes> = ({ onSave }) => {
  const { t } = useTranslation()
  const { currentPage, isLoading } = useSelector(
    (store: IRootState) => store.adminPages,
  )
  const [state, setState] = useState<AboutUsBody>(
    (currentPage?.content as AboutUsBody) || INITIAL_STATE,
  )
  const {
    section_1_title,
    section_1_description,
    section_1_image_1,
    section_1_image_2,
    section_2_description,
    section_2_title,
    section_2_image,
    section_3_description,
    section_3_title,
    section_3_image,
  } = state

  const handleChange = (event: SyntheticEvent): void => {
    const { name, value } = event.target as HTMLInputElement

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFileChange: OnUploadFileChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSave = () => {
    onSave(state)
  }

  const inputs = useMemo(
    () => [
      {
        label: t('COMMON.TITLE_H1'),
        value: section_1_title,
        name: 'section_1_title',
        sectionNumber: 1,
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_1_description,
        name: 'section_1_description',
        type: TYPE_TEXTAREA,
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
        name: 'section_1_image_1',
        type: TYPE_FILE,
        selectedName: String(
          section_1_image_1?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 2 }),
        name: 'section_1_image_2',
        type: TYPE_FILE,
        selectedName: String(
          section_1_image_2?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
      {
        label: t('COMMON.TITLE'),
        value: section_2_title,
        name: 'section_2_title',
        sectionNumber: 2,
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_2_description,
        name: 'section_2_description',
        type: TYPE_TEXTAREA,
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 3 }),
        name: 'section_2_image',
        type: TYPE_FILE,
        sectionNumber: 3,
        selectedName: String(section_2_image?.path?.split('/')?.slice(-1)?.[0]),
      },
      {
        label: t('COMMON.TITLE'),
        value: section_3_title,
        name: 'section_3_title',
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_3_description,
        name: 'section_3_description',
        type: TYPE_TEXTAREA,
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 4 }),
        name: 'section_3_image',
        type: TYPE_FILE,
        selectedName: String(section_3_image?.path?.split('/')?.slice(-1)?.[0]),
      },
    ],
    [
      section_1_title,
      section_1_description,
      section_1_image_1,
      section_1_image_2,
      section_2_title,
      section_2_description,
      section_2_image,
      section_3_title,
      section_3_description,
      section_3_image,
      t,
    ],
  )

  useEffect(() => {
    if (currentPage?.content) {
      setState({
        ...currentPage?.content,
        ...addPrefixSeo(currentPage?.seo || {}),
      } as AboutUsBody)
    }
  }, [currentPage?.content, currentPage?.seo])

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical" className={style.aboutUsTemplate}>
        {inputs.map(({ type, sectionNumber, ...item }, index) => (
          <Fragment key={index}>
            {sectionNumber && (
              <h2>{t('COMMON.SECTION_NUMBER', { number: sectionNumber })}</h2>
            )}

            <TemplateField
              type={type as InputTypes}
              onChange={
                (type === TYPE_FILE
                  ? handleFileChange
                  : handleChange) as TemplateFieldsPropsTypeChange
              }
              {...item}
            />
          </Fragment>
        ))}

        <div className={'seo-block'}>
          {SEO_INPUTS.map(({ name, title: seoTitle }, index) => (
            <TextBlock
              key={index}
              rows={7}
              name={name}
              title={t(seoTitle)}
              value={(state[name] as string) || ''}
              onChange={handleChange}
            />
          ))}
        </div>

        <UIButton label={t('COMMON.SAVE')} handler={handleSave} />
      </Form>
    </Spin>
  )
}

export default memo(AboutUsTemplate)

import { FC, memo, SyntheticEvent } from 'react'

import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { ReactComponent as TrashIcon } from 'assets/images/common/trash.svg'
import { AdminTextTemplateDataType } from 'entities/admin'
import { UIButton, UITextArea } from 'ui'
import UITextInput from 'ui/TextInput'

import style from './style.module.scss'

type PropsType = {
  onChange: (index: number, event: SyntheticEvent) => void
  onRowRemove: (index: number) => void
  state: AdminTextTemplateDataType[]
}

const TextTemplateRows: FC<PropsType> = ({ state, onRowRemove, onChange }) => {
  const { t } = useTranslation()

  const handleChange = (index: number) => (event: SyntheticEvent) => {
    onChange(index, event)
  }

  const handleRemoveRow = (index: number) => () => {
    onRowRemove(index)
  }

  return (
    <>
      {state?.map(({ title, description }, index) => (
        <div key={index} className={style.row}>
          <p>{index + 1}.</p>

          <UITextInput
            name={'title'}
            value={title}
            onChange={handleChange(index)}
            text={t('COMMON.TITLE')}
            className={style.input}
            placeholder={t('COMMON.TITLE')}
          />

          <Form.Item label={t('COMMON.DESCRIPTION')}>
            <UITextArea
              name={'description'}
              value={description}
              onChange={handleChange(index)}
              className={style.textarea}
              placeholder={t('COMMON.DESCRIPTION')}
              rows={6}
            />
          </Form.Item>

          <UIButton
            icon={<TrashIcon />}
            handler={handleRemoveRow(index)}
            type="text"
            isRed
            label={t('COMMON.DELETE')}
          />
        </div>
      ))}
    </>
  )
}

export default memo(TextTemplateRows)

import { FC, memo, useMemo } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ReactComponent as OkBlackSVG } from 'assets/images/common/redesign/okBlack.svg'
import { AdminSettingsBlockSubscriptionsType } from 'views/admin/Settings/index.type'

import styles from './style.module.scss'

const SubscribeContent: FC<
  AdminSettingsBlockSubscriptionsType & {
    isFreeTrial: boolean
    reminder_subscription_plan_deprecated_time: number
    subscription_plan_free_trial_days: number
  }
> = ({ details, description, subscribe, price }) => {
  const { t } = useTranslation()

  const expiredWarning = useMemo(() => {
    if (subscribe) {
      if (price === t('COMMON.MANUAL')) {
        return null
      }

      if (subscribe?.expired) {
        return (
          <p className={styles.warning}>
            {t('MANAGER.SUBSCRIPTION.EXPIRED')}{' '}
            {format(
              new Date(subscribe?.finished_at || new Date()),
              'MMM dd, yyyy',
            )}
          </p>
        )
      }
    }

    return null
  }, [price, subscribe, t])

  return (
    <div className={styles.contentWrapp}>
      <p className={styles.info}>{description}</p>

      {expiredWarning}

      <div className={styles.list}>
        {details?.map((item) => (
          <p key={item}>
            <OkBlackSVG /> {item}
          </p>
        ))}
      </div>
    </div>
  )
}

export default memo(SubscribeContent)

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SelectOption } from 'entities/common';
import { ServiceType } from 'entities/manager/service';
import { StaffType } from 'entities/manager/staff';
import {
  ChangeEvent,
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateAppointmentSearchRequest } from 'store/Manager/Appointments/Appointments.action';
import { MethodSearch } from 'store/Manager/Appointments/Appointments.type';
import {
  createBasketServiceRequest,
  updateBasketServiceRequest,
} from 'store/Manager/Basket/Basket.action';
import { IBasketResponseItem } from 'store/Manager/Basket/Basket.type';
import { IRootState } from 'store/Root.reducer';
import { UISelect } from 'ui';
import { SelectOptionType } from 'ui/AppointmentsSidebar/index.type';
import { FORMAT_DATE, STANDART_DATE } from 'utils';
import { DateHelper } from 'utils/helpers';
import styles from '../../style.module.scss';
import CustomSearch, { SearchOptionType } from 'ui/CustomSearch';

type PropsType = {
  errors?:
    | object
    | {
        [key: string]: string[];
      };
  setValues: Dispatch<SetStateAction<Partial<IBasketResponseItem>[]>>;
  tabDate: string | Date;
  values: Partial<IBasketResponseItem>[];
};

const UIAppointmendarSidebarAddCart: FC<
  PropsType & Partial<IBasketResponseItem>
> = ({ errors, setValues, values, tabDate, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isServiceLoading, isStaffLoading, staffList, serviceList } =
    useSelector((state: IRootState) => state.appointments);

  const [localStaffList, setlocalStaffList] = useState<StaffType[]>([]);
  const [localServiceList, setLocalServiceList] = useState<ServiceType[]>([]);

  const handleServiceChange = (value: string) => {
    const staffId = values.find((item) => item?.id === props?.id)?.staff?.id;

    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(tabDate, FORMAT_DATE),
          ...(staffId && { staff_id: Number(staffId) }),
        },
        method: MethodSearch.SERVICE,
      }),
    );
  };

  const handleStaffChange = (value: string) => {
    const serviceId = values.find((item) => item?.id === props.id)?.service?.id;

    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(tabDate, FORMAT_DATE),
          ...(serviceId && { service_id: Number(serviceId) }),
        },
        method: MethodSearch.STAFF,
      }),
    );
  };

  const handleServiceSelectChange = (
    optionValue: string | number,
    obj: SearchOptionType,
  ) => {
    setValues((prevState) => {
      return prevState.map((item) => {
        if (item?.id === props?.id) {
          return {
            ...item,
            service: {
              ...item?.service,
              id: String(optionValue),
              name: obj.label,
            },
          };
        }

        return item;
      });
    });

    values.forEach((item) => {
      const objToRequest = {
        service_id: Number(optionValue),
        staff_id: Number(item.staff?.id),
      };

      if (item?.id === props.id && !String(props.id).includes('new')) {
        if (optionValue && item?.staff?.id) {
          dispatch(
            updateBasketServiceRequest({
              ...objToRequest,
              id: Number(item.id),
            }),
          );
        }
      } else if (
        String(props.id).includes('new') &&
        item?.id === props.id &&
        item?.staff?.id &&
        optionValue
      ) {
        dispatch(createBasketServiceRequest(objToRequest));
      }
    });
  };

  const handleStaffSelectChange = (val: number, obj: SelectOption) => {
    setValues((prevState) => {
      return prevState.map((item) => {
        if (item?.id === props?.id) {
          return {
            ...item,
            staff: { ...item?.staff, id: String(val), name: obj.label },
          };
        }

        return item;
      });
    });

    values.forEach((item) => {
      const objToRequest = {
        service_id: Number(item?.service?.id),
        staff_id: Number(val),
      };

      if (item?.id === props.id && !String(props.id).includes('new')) {
        if (item?.service?.id && val) {
          dispatch(
            updateBasketServiceRequest({
              ...objToRequest,
              id: Number(item?.id),
            }),
          );
        }
      } else if (String(props.id).includes('new') && item?.id === props.id) {
        if (item?.service?.id && val) {
          dispatch(createBasketServiceRequest(objToRequest));
        }
      }
    });
  };

  useEffect(() => {
    if (serviceList) {
      setLocalServiceList(serviceList);
    }
  }, [serviceList]);

  useEffect(() => {
    if (staffList) {
      setlocalStaffList(staffList);
    }
  }, [staffList]);

  return (
    <div>
      <CustomSearch
        label={t('MANAGER.BUTTON.ADD_SERVICE')}
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_SERVICE')}
        options={localServiceList?.map((item) => {
          const hour = DateHelper.toFormat(
            new Date(`${STANDART_DATE} ${item.duration}`),
            'H',
          );
          const minute = item.duration.split(':')?.[1];
          const subtitle = `${hour !== '0' && hour ? `${hour}h ` : ''}${
            minute ? `${minute}m` : ''
          }`;

          return {
            value: item.id,
            label: item.name,
            subtitle,
            inFrontOf: `$${item.price.price}`,
          };
        })}
        onSelect={handleServiceSelectChange}
        onBlur={() => setLocalServiceList([])}
        onFocus={handleServiceChange}
        select={
          props.service?.id
            ? {
                value: props.service?.id as number,
                label: props.service?.name as string,
                subtitle: props.service?.duration as string,
                inFrontOf: `$${props?.service?.price?.price}`,
              }
            : undefined
        }
        isLoading={isServiceLoading}
      />

      <div className={styles.timepick}>
        <UISelect
          placeholder={t('COMMON.SELECT')}
          label={t('COMMON.START_TIME')}
          disabled
          value={props?.time?.from?.slice(0, -3) || ''}
        />

        <span className={styles.dash}></span>

        <UISelect
          placeholder={t('COMMON.SELECT')}
          disabled
          value={props?.time?.to?.slice(0, -3) || ''}
          label={t('COMMON.END_TIME')}
        />
      </div>

      <UISelect
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_MASTER')}
        label={t('MANAGER.BUTTON.ADD_MASTER')}
        showSearch
        className={styles.select}
        value={props?.staff?.name}
        onSearch={(e) => handleStaffChange(e)}
        onFocus={(e) => {
          handleStaffChange(
            (e as unknown as ChangeEvent<HTMLInputElement>).target.value,
          );
        }}
        options={localStaffList?.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={(value, obj) =>
          handleStaffSelectChange(value as number, obj as SelectOption)
        }
        onBlur={() => setlocalStaffList([])}
        isLoading={isStaffLoading}
        filterOption={(inputValue: string, option: SelectOptionType) =>
          option?.label
            ?.toLowerCase()
            ?.includes(inputValue?.toLowerCase()) as boolean
        }
      />
    </div>
  );
};

export default memo(UIAppointmendarSidebarAddCart);

import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_MANAGER_PROFILE_SUCCESS,
  GET_MANAGER_PROFILE_REQUEST,
  GET_MANAGER_PROFILE_FAILURE,
  CREATE_MANAGER_PHONE_REQUEST,
  CREATE_MANAGER_PHONE_SUCCESS,
  CREATE_MANAGER_PHONE_FAILURE,
  DELETE_MANAGER_PHONE_REQUEST,
  DELETE_MANAGER_PHONE_SUCCESS,
  DELETE_MANAGER_PHONE_FAILURE,
  RESET_PHONE,
  RESET_ERROR,
  SET_IMAGE_LIST,
  UPDATE_MANAGER_PROFILE_REQUEST,
  UPDATE_MANAGER_PROFILE_SUCCESS,
  UPDATE_MANAGER_PROFILE_FAILURE,
} from './Salon.constant'
import { SalonActionsTypes, SalonState } from './Salon.type'

const initialState: SalonState = {
  profile: null,
  isLoading: false,
  message: '',
  isPhoneLoading: false,
  phoneData: null,
  images: [],
  errors: {},
  errorMessage: null,
  isImageLoading: false,
}

export default function salonReducer(
  state = initialState,
  action: SalonActionsTypes,
): SalonState {
  switch (action.type) {
    case UPDATE_MANAGER_PROFILE_REQUEST:
    case GET_MANAGER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MANAGER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.data,
        message: action.payload.message,
        isLoading: false,
      }
    case GET_MANAGER_PROFILE_FAILURE:
      return { ...state, isLoading: false }
    case CREATE_MANAGER_PHONE_REQUEST:
    case DELETE_MANAGER_PHONE_REQUEST:
      return {
        ...state,
        isPhoneLoading: true,
      }
    case CREATE_MANAGER_PHONE_SUCCESS:
      return {
        ...state,
        isPhoneLoading: false,
        phoneData: action.payload,
      }
    case CREATE_MANAGER_PHONE_FAILURE:
    case DELETE_MANAGER_PHONE_FAILURE:
      return {
        ...state,
        isPhoneLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case UPDATE_MANAGER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errors: {},
      }
    case UPDATE_MANAGER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case DELETE_MANAGER_PHONE_SUCCESS:
      return {
        ...state,
        isPhoneLoading: false,
      }
    case RESET_PHONE:
      return {
        ...state,
        phoneData: null,
      }
    case RESET_ERROR:
      return {
        ...state,
        errors: {},
        errorMessage: null,
      }
    case SET_IMAGE_LIST:
      return {
        ...state,
        profile: {
          ...state.profile,
          images: action.payload,
        },
      }
    default:
      return state
  }
}

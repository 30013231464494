import { FC } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { IGetOrderHistoryResponse } from 'store/Manager/Orders/Orders.type'

import styles from './style.module.scss'

const OrderdsHistoryCards: FC<IGetOrderHistoryResponse> = ({
  date_from,
  service,
  staff,
  time,
  note,
  user,
  price,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.listWrapp}>
      <div className={styles.wrapper}>
        <div>
          <div className={styles.left}>
            <p className={styles.name}>{user.name || '---'}</p>
            <p className={styles.phone}>
              {user?.phone || user?.profile?.phone || '---'}
            </p>
            {/* Backlog */}
            {/* <div className={styles.visitStatus}>
                <UISwitch checked={item.isVisit} />
                <p className={cn({ [styles.visitInactive]: !item.isVisit })}>
                  {item.isVisit
                    ? t('MANAGER.ORDERS.VISIT')
                    : t('MANAGER.ORDERS.NOT_VISIT')}
                </p>
              </div> */}
          </div>
          <div className={styles.rigth}>
            <div className={styles.row}>
              <p className={styles.operationName}>{service?.name || '---'}</p>

              <p className={styles.date}>
                {format(
                  new Date(`${date_from} ${time.from}`),
                  'HH:mm, EEEE, dd MMMM',
                )}
              </p>
            </div>

            <div className={styles.row}>
              <p>{t('MANAGER.ORDERS.MASTER')}</p>
              <p>{staff?.name || '---'}</p>
            </div>

            <div className={styles.row}>
              <p>{t('MANAGER.ORDERS.TOTAL')}</p>

              <p>${price?.price}</p>
            </div>

            {note && <p className={styles.answer}>{note}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderdsHistoryCards

import { FC, memo, SyntheticEvent, useMemo } from 'react'

import classNames from 'classnames'

import { PropsType } from './index.type'
import style from './style.module.scss'
import TextBlock from '../TextBlock'

const FaqTextBlock: FC<PropsType> = ({
  questionTitle,
  answerTitle,
  className,
  setState,
  state,
  questionError,
  answerError,
  disabled,
}) => {
  const handleChange = (event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement

    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const blocks = useMemo(
    () => [
      { title: questionTitle, name: 'question', error: questionError },
      { title: answerTitle, name: 'answer', error: answerError },
    ],
    [answerError, answerTitle, questionError, questionTitle],
  )

  return (
    <div className={classNames(style.textBlock, className)}>
      {blocks.map(({ error, name, title }, index) => (
        <TextBlock
          key={index}
          onChange={handleChange}
          title={title}
          value={state[name as keyof typeof state]}
          name={name}
          rows={20}
          error={error}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

export default memo(FaqTextBlock)

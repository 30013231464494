import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { UICheckbox, UICustomCard } from 'ui';

import ServiceStaffCheckBox from './Checkbox';
import { PropsType } from './index.type';

const ServicesStaff: FC<PropsType> = ({
  isChecked,
  staff,
  values,
  setValue,
  service,
  setIsChecked,
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = () => {
    setIsChecked(true);

    setValue((prevState) => ({
      ...prevState,
      staff: staff?.map((item) => item.id) || [],
    }));
  };

  return (
    <UICustomCard
      title={
        <>
          <span>2. </span> {t('MANAGER.SERVICES.STAFF')}
        </>
      }
      titleRight={
        <UICheckbox
          checked={isChecked || staff?.length === values?.staff?.length}
          onChange={handleCheckboxChange}
        >
          {t('MANAGER.SERVICES.SELECT_ALL')}
        </UICheckbox>
      }
    >
      <div>
        <ServiceStaffCheckBox
          fullStaff={staff}
          setValue={setValue}
          values={values}
          serviceStaff={service?.staff}
          setIsChecked={setIsChecked}
        />
      </div>
    </UICustomCard>
  );
};

export default ServicesStaff;

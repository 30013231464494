import { FC, ChangeEvent, memo, useState } from 'react';

import classNames from 'classnames';
import InputMask from 'react-input-mask';

import { ReactComponent as WarningSVG } from 'assets/images/common/redesign/warning.svg';
import { VALIDATION_CLASS } from 'utils';

import styles from './style.module.scss';
import { StringHelper } from 'utils/helpers';

export type PhoneNumberInputType = {
  number: string;
};

export type PropsType = {
  className?: string;
  disabled?: boolean;
  error?: string | string[];
  help?: string;
  isLowerSelectWidth?: boolean;
  label?: string;
  name?: string;
  necessaryLabel?: string;
  onBlur?: () => void;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onInputChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onSelectChange?: (value: string) => void;
  rules?: any;
  value?: string;
  withoutLabel?: boolean;
};

const UIPhoneInput: FC<PropsType> = ({
  error,
  onChange,
  value,
  help,
  disabled,
  label,
  necessaryLabel,
  className,
  onBlur,
  onFocus,
}) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = () => {
    setFocused(true);

    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setFocused(false);

    if (onBlur) {
      onBlur();
    }
  };

  const classes = {
    wrapper: classNames(styles.wrapper, className, {
      [VALIDATION_CLASS]: error,
    }),
    prefix: classNames({
      [styles.border]: focused,
      [styles.disabled]: disabled,
    }),
    input: classNames({ [styles.disabled]: disabled }),
    error: classNames(styles.text, {
      [styles.errorText]: error,
    }),
  };

  return (
    <div className={classes.wrapper}>
      <div className={styles.label}>
        {label} <span className={styles.necessaryLabel}>{necessaryLabel}</span>
      </div>

      <div className={styles.input}>
        <span className={classes.prefix}>+52-</span>

        <InputMask
          mask={'99-9999-9999'}
          placeholder={'__-____-____'}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          className={classes.input}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {help && !error && <span className={styles.text}>{help}</span>}
        {error && (
          <div className={classes.error}>
            <WarningSVG />
            {StringHelper.getFisrtMessage(error)}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(UIPhoneInput);

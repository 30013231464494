import * as React from 'react'

import { PickerTimeProps } from 'antd/es/date-picker/generatePicker'

import UIAntDatePicker from '../DatePicker'

export type UIAntTimePickerProps = Omit<PickerTimeProps<Date>, 'picker'>

const UIAntTimePicker = React.forwardRef<any, UIAntTimePickerProps>(
  (props, ref) => {
    return (
      <UIAntDatePicker {...props} picker="time" mode={undefined} ref={ref} />
    )
  },
)

UIAntTimePicker.displayName = 'UIAntTimePicker'

export default UIAntTimePicker

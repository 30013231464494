/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo, useCallback, useEffect, useState } from 'react';

/* eslint-disable import/order */ // disable for order of imports
import FullCalendar, {
  FormatterInput,
  EventContentArg,
  EventSourceInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import esLocale from '@fullcalendar/core/locales/es';

import styles from './style.module.scss';
import { PropsType } from './index.type';
import { ARRAY_HOUR_MIN, FORMAT_DATE, FORMAT_DAY, FORMAT_WEEK } from 'utils';
import { DateHelper } from 'utils/helpers';
import classNames from 'classnames';
import useResponsive from 'hooks/useResponsive';
import { ModeType } from 'ui/CalendarHeader/index.type';
import useWindowSize from 'hooks/useWindowSize';

const use24HoursFormat: FormatterInput = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: false,
};

const renderTitle = (e: EventContentArg, className?: string) => {
  const event = e?.event?.extendedProps;

  return (
    <div
      className={styles.wrappEvent}
      {...(e?.backgroundColor && {
        style: { backgroundColor: e?.backgroundColor },
      })}
    >
      <div className="popover">
        <p className="popoverDate">
          {event?.startTime} -<span>{event?.endTime}</span>
        </p>
        <div
          className="popoverBg"
          {...(e?.backgroundColor && {
            style: { backgroundColor: e?.backgroundColor },
          })}
        ></div>
        <div className="popoverTitle">
          <p>{event?.title}</p>
          <p>{event?.description}</p>
        </div>
      </div>
      <p className={classNames(styles.title, className, 'popoverHide')}>
        {e?.timeText}
        <span> {event?.title}</span> {event?.description && '-'}{' '}
        {event?.description}
      </p>
    </div>
  );
};

const AppointmentsCalendar: FC<PropsType> = ({
  onSelect,
  onClick,
  onEventClick,
  calendarRef,
  events,
  monthDisableDate,
  selectAllow,
  setMode,
  selectable = true,
  mode,
  headerDate,
}) => {
  const { width } = useWindowSize();
  const { isMobile, isIpad, isTablet } = useResponsive();

  const [modeViews, setModeViews] = useState('');
  const [timeNow, setTimeNow] = useState('');
  const [positionTime, setPositionTime] = useState('');

  const getPosition = () => {
    const HOUR_NOW = new Date().getHours();
    const TIME_NOW = new Date().getMinutes() - 8;

    return ((HOUR_NOW * 60 + TIME_NOW) / 1440) * 100;
  };

  const renderPositionTime = () => {
    return (
      <div className={styles['time-now-wrapper']} style={{ top: positionTime }}>
        {timeNow}
      </div>
    );
  };

  const navLinkDayClick = useCallback(
    (date: Date) => {
      if (!((isMobile || isTablet) && modeViews === ModeType.MONTH)) {
        setMode(ModeType.DAY);
        (calendarRef.current as unknown as InstanceType<typeof FullCalendar>)
          .getApi()
          .changeView('timeGridDay', date);
      }
    },
    [calendarRef, isMobile, modeViews, setMode, isTablet],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setPositionTime(`${getPosition()}%`);
      setTimeNow(DateHelper.toFormat(new Date(), 'HH:mm'));
    }, 200);

    return () => clearInterval(interval);
  }, []);

  const isShowTimeLine =
    DateHelper.toFormat(headerDate, FORMAT_DATE) ===
    DateHelper.toFormat(new Date(), FORMAT_DATE);

  return (
    <div
      className={classNames(styles.calendar, {
        [styles.calendarMonth]: modeViews === ModeType.MONTH,
        [styles.calendarDayMobile]: mode === ModeType.DAY,
        [styles.calendarDay]: mode === ModeType.DAY,
        [styles.calendarWeekMobile]: mode === ModeType.WEEK,
      })}
    >
      <div className={styles.timeMobile}>
        {ARRAY_HOUR_MIN.map((item) => (
          <div key={item}>{item}</div>
        ))}

        {width <= 1024 && mode === ModeType.DAY && isShowTimeLine && (
          <>{renderPositionTime()}</>
        )}
      </div>

      {width > 1024 && mode === ModeType.DAY && isShowTimeLine && (
        <>{renderPositionTime()}</>
      )}

      <FullCalendar
        locale={esLocale}
        ref={calendarRef}
        themeSystem="bootstrap5"
        viewDidMount={(data) => {
          setModeViews(data.view.type);
        }}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
        ]}
        headerToolbar={{
          left: '',
          right: '',
          center: '',
        }}
        eventClick={onEventClick}
        initialView="timeGridDay"
        views={{
          timeGridDay: {
            slotDuration: '00:10:00',
            slotLabelInterval: { hours: 1 },
            eventContent: renderTitle,
          },
          timeGridWeek: {
            slotDuration: '00:10:00',
            slotLabelInterval: { hours: 1 },
            dayHeaderContent: ({ date }) => {
              return (
                <div className={styles.weekTitle}>
                  <span>{DateHelper.toFormat(date, FORMAT_DAY)}</span>{' '}
                  {DateHelper.toFormat(date, FORMAT_WEEK)}
                </div>
              );
            },
            eventContent: renderTitle,
          },
          dayGridMonth: {
            eventLimit: 1,
            ...(!isMobile && !isTablet && { dayMaxEvents: 6 }),
            dayHeaderFormat: {
              weekday: isMobile || isIpad || isTablet ? 'short' : 'long',
            },
            dayCellContent: (e) => {
              return (
                <div
                  className={classNames({
                    disableDate: !!monthDisableDate?.find(
                      (i) => i?.valueOf() === e?.date.valueOf(),
                    ),
                  })}
                >
                  <span>{e?.dayNumberText}</span>
                </div>
              );
            },
            eventContent: renderTitle,
          },
        }}
        navLinkDayClick={navLinkDayClick}
        navLinks={true}
        firstDay={1}
        selectable={selectable}
        selectMirror={true}
        dayMaxEvents={true}
        select={onSelect}
        dateClick={onClick}
        slotLabelFormat={use24HoursFormat}
        eventTimeFormat={use24HoursFormat}
        events={events as EventSourceInput}
        editable={false}
        {...(selectAllow && {
          selectAllow: (selectInfo) => selectAllow(selectInfo),
        })}
        selectLongPressDelay={0}
        eventLongPressDelay={0}
        longPressDelay={1}
        showNonCurrentDates={false}
      />
    </div>
  );
};

export default memo(AppointmentsCalendar);

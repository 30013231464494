import { FORMAT_DATE } from 'utils';
import { DateHelper } from 'utils/helpers';

import { ShiftType } from '.';

export const getStaffCalendarStartTime = (
  shifts: ShiftType[],
  calendarDate: string,
) => {
  let startTime = '24:00:00';

  shifts.forEach((shift) =>
    shift.schedule.forEach((week) => {
      if (
        calendarDate &&
        DateHelper.toFormat(calendarDate, FORMAT_DATE) === week.date
      ) {
        const time = week?.work?.from || '';

        if (time !== '' && startTime.split(':')[0] > time.split(':')[0]) {
          startTime = time;
        }
      }
    }),
  );

  return startTime === '24:00:00' ? '10:00:00' : startTime;
};

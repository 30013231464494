import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';

import { EventClickArg, EventSourceInput } from '@fullcalendar/react';

import { FORMAT_TIME_HOUR_WITH_MIN } from 'utils';
import { DateHelper } from 'utils/helpers';

import styles from './style.module.scss';

type ExtendedProps = {
  backgroundColor: string;
  end: string;
  extendedProps: {
    description: string;
    endTime: string;
    startTime: string;
    started_at: string;
    title: string;
  };
  start: string;
};

export type PropsType = {
  events?: EventSourceInput[];
  setEventData: Dispatch<EventClickArg | null | undefined>;
  setEventDate: Dispatch<Date>;
  setIsAddAppointment: Dispatch<SetStateAction<boolean>>;
  setIsDrawer: (arg: boolean) => void;
};

const AppointmentsMonthEvent: FC<PropsType> = ({
  events,
  setEventData,
  setEventDate,
  setIsDrawer,
  setIsAddAppointment,
}) => {
  const handleClick = useCallback(
    (item: EventClickArg) => {
      const itemEvent = item as unknown as ExtendedProps;

      setEventData({
        event: {
          ...item,
          extendedProps: {
            ...itemEvent.extendedProps,
            endTime: DateHelper.toFormat(
              itemEvent.end,
              FORMAT_TIME_HOUR_WITH_MIN,
            ),
            startTime: DateHelper.toFormat(
              itemEvent.start,
              FORMAT_TIME_HOUR_WITH_MIN,
            ),
          },
        },
      } as unknown as EventClickArg);
      setEventDate(new Date(itemEvent.start));
      setIsDrawer(true);
      setIsAddAppointment(false);
    },
    [setEventData, setEventDate, setIsAddAppointment, setIsDrawer],
  );

  const renderEvent = useMemo(
    () =>
      events?.map((item, index) => {
        const props = (item as ExtendedProps).extendedProps;

        return (
          <div
            key={index}
            className={styles.item}
            onClick={() => handleClick(item as EventClickArg)}
          >
            <p className={styles.time}>
              {props.startTime} -<span>{props.endTime}</span>
            </p>
            <div
              style={{
                backgroundColor: (item as ExtendedProps).backgroundColor,
              }}
              className={styles.color}
            ></div>
            <div className={styles.title}>
              <p>{props.title}</p>
              <p>{props.description}</p>
            </div>
          </div>
        );
      }),
    [events, handleClick],
  );

  return (
    <>
      {events?.length ? <div className={styles.list}>{renderEvent}</div> : null}
    </>
  );
};

export default AppointmentsMonthEvent;

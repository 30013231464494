import { FC, memo } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { setAdminCurrentSalonToggleSubscriptionRequest } from 'store/Admin/Salons/Salons.action'
import { UISwitch } from 'ui'

import style from './style.module.scss'

type PropTypes = { id: number; isLoading: boolean; value?: number }

const AdminSalonSectionEight: FC<PropTypes> = ({ value, id, isLoading }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleChange = () => {
    dispatch(setAdminCurrentSalonToggleSubscriptionRequest(id, Number(!value)))
  }

  return (
    <section className={style.eight}>
      <h2>8. {t('ADMIN.PROFILE_SALON.SECTION_8.TITLE')}</h2>

      <UISwitch checked={!!value} onChange={handleChange} loading={isLoading} />
    </section>
  )
}

export default memo(AdminSalonSectionEight)

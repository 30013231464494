import { FC, ReactElement, useLayoutEffect, useState, useEffect } from 'react'
import 'antd/dist/antd.css'

import { Layout } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { ManagerSidebarContextProvider } from 'context'
import { getDefaultManagerSelectedKeys } from 'entities/manager'
import useWindowSize from 'hooks/useWindowSize'
import { sidebar } from 'modules/manager/constants'
import { IRootState } from 'store/Root.reducer'
import UIAppointmentsSidebar from 'ui/AppointmentsSidebar'
import { MANAGER_APPOINTMENTS } from 'utils'
import { WidgetSidebar } from 'widgets'
import ManagerHeader from 'widgets/ManagerHeader'

import styles from './style.module.scss'
import { getManagerNotificationsRequest } from 'store/Manager/Notifications/Notifications.action'
import { ArrayHelper } from 'utils/helpers'

type PropsType = {
  children: ReactElement
}

const LayoutManager: FC<PropsType> = ({ children }) => {
  const param = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { width, height } = useWindowSize()
  const dispatch = useDispatch()
  const isShowHeader = width <= 1024
  const { userType, appointmentsError, notification } = useSelector(
    (store: IRootState) => ({
      userType: store.auth.user?.type,
      appointmentsError: store.appointments.appointmentsError,
      notification: store.managerNotification.notification,
    }),
  )
  const [isDrawer, setDrawer] = useState(false)
  const [collapsed, setCollapsed] = useState(isShowHeader)

  const handleOpenMenu = () => setCollapsed(false)
  const handleCloseMenu = () => setCollapsed(true)
  const handleToggleMenu = () => setCollapsed((prevState) => !prevState)

  const menuWidth = isShowHeader ? '100%' : 88

  useEffect(() => {
    if (isShowHeader) {
      handleCloseMenu()
    } else {
      handleOpenMenu()
    }
  }, [isShowHeader])

  useEffect(() => {
    const path = getDefaultManagerSelectedKeys(pathname, param)[0] || ''

    document.title = `Beauty Booking${path ? ` - ${t(path)}` : ''}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useLayoutEffect(() => {
    if (userType === 'customer') {
      window.location.replace('/')
    } else if (userType === 'admin') {
      window.location.replace('/')
    }
  }, [userType, navigate])

  useLayoutEffect(() => {
    if (appointmentsError?.length && pathname !== MANAGER_APPOINTMENTS) {
      setDrawer(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentsError?.length])

  useEffect(() => {
    dispatch(getManagerNotificationsRequest())
  }, [dispatch, pathname])

  return (
    <ManagerSidebarContextProvider
      value={{
        onCloseMenu: handleCloseMenu,
        onOpenMenu: handleOpenMenu,
        onToggleMenu: handleToggleMenu,
        isShowHeader,
        width,
        height,
        isMenuOpen: !collapsed,
      }}
    >
      <Layout className={styles.layout}>
        {isShowHeader && (
          <Layout.Header className={styles.header}>
            <ManagerHeader />
          </Layout.Header>
        )}

        <Layout className={styles.wrappContent}>
          <Layout.Sider
            className={styles.wrappMenu}
            width={menuWidth}
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
          >
            <WidgetSidebar
              items={ArrayHelper.getNotification(sidebar, notification)}
              defaultSelectedKeys={getDefaultManagerSelectedKeys(
                pathname,
                param,
              )}
            />
          </Layout.Sider>

          <Layout.Content className={cn(styles.content)}>
            {children}
            {isDrawer && (
              <UIAppointmentsSidebar setIsDrawer={setDrawer} isValidateError />
            )}
          </Layout.Content>
        </Layout>
      </Layout>
    </ManagerSidebarContextProvider>
  )
}

export default LayoutManager

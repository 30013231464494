import {
  CANCEL_MANAGER_PLAN_FAILURE,
  CANCEL_MANAGER_PLAN_REQUEST,
  CANCEL_MANAGER_PLAN_SUCCESS,
  CLEAR_MANAGER_SUBSCRIPTION_ERRORS,
  GET_MANAGER_SUBSCRIPTION_FAILURE,
  GET_MANAGER_SUBSCRIPTION_REQUEST,
  GET_MANAGER_SUBSCRIPTION_SUCCESS,
  SUBSCRIBE_MANAGER_PLAN_FAILURE,
  SUBSCRIBE_MANAGER_PLAN_REQUEST,
  SUBSCRIBE_MANAGER_PLAN_SUCCESS,
  GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
  GET_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS,
  GET_MANAGER_HISTORY_SUBSCRIPTION_FAILURE,
  UPDATE_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS,
  UPDATE_MANAGER_HISTORY_SUBSCRIPTION_FAILURE,
} from './Subscription.constant'
import {
  IManagerSubscriptionState,
  ManagerSubscriptionActionTypes,
} from './Subscription.type'

const INITIAL_STATE: IManagerSubscriptionState = {
  error: {},
  isLoading: false,
  isHistoryLoading: false,
  subscriptions: [],
  errorMessage: null,
  currentSubscriptionLoading: null,
  settings: {
    reminder_subscription_plan_deprecated_status: 0,
    reminder_subscription_plan_deprecated_time: 0,
    subscription_plan_free_trial_days: 0,
    subscription_plan_free_trial_description: '',
    subscription_plan_free_trial_details: [],
    subscription_plan_free_trial_name: '',
    subscription_plan_free_trial_status: 0,
  },
  history: null,
  subscribe: null,
}

export default function managerSubscriptionReducer(
  state = INITIAL_STATE,
  action: ManagerSubscriptionActionTypes,
): IManagerSubscriptionState {
  switch (action.type) {
    case GET_MANAGER_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MANAGER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscriptions: action.payload.subscription_plans,
        settings: action.payload.settings,
        subscribe: action.payload.subscribe || null,
      }
    case GET_MANAGER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case CANCEL_MANAGER_PLAN_REQUEST:
    case SUBSCRIBE_MANAGER_PLAN_REQUEST:
      return {
        ...state,
        currentSubscriptionLoading: action.payload,
      }
    case SUBSCRIBE_MANAGER_PLAN_SUCCESS:
      return {
        ...state,
        currentSubscriptionLoading: null,
      }
    case CANCEL_MANAGER_PLAN_SUCCESS:
      return {
        ...state,
        currentSubscriptionLoading: null,
      }
    case CANCEL_MANAGER_PLAN_FAILURE:
    case SUBSCRIBE_MANAGER_PLAN_FAILURE:
    case UPDATE_MANAGER_HISTORY_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        ...action.payload,
        currentSubscriptionLoading: null,
      }
    case GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isHistoryLoading: true,
      }
    case GET_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isHistoryLoading: false,
        history: {
          ...action.payload,
          data: [...(state.history?.data || []), ...action.payload.data],
        },
      }
    case GET_MANAGER_HISTORY_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        isHistoryLoading: false,
      }
    case UPDATE_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CLEAR_MANAGER_SUBSCRIPTION_ERRORS:
      return {
        ...state,
        error: {},
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}

import { call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  addAdminFaqApi,
  deleteAdminFaqApi,
  editAdminFaqApi,
  getAdminCurrentFaqApi,
  getAdminFaqApi,
} from 'services/http'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  addAdminFaqFailure,
  addAdminFaqSuccess,
  deleteAdminFaqFailure,
  deleteAdminFaqSuccess,
  editAdminFaqFailure,
  editAdminFaqSuccess,
  getAdminCurrentFaqFailure,
  getAdminCurrentFaqSuccess,
  getAdminFaqFailure,
  getAdminFaqSuccess,
} from './Faq.action'
import {
  ADD_ADMIN_FAQ_REQUEST,
  DELETE_ADMIN_FAQ_REQUEST,
  EDIT_ADMIN_FAQ_REQUEST,
  GET_ADMIN_CURRENT_FAQ_REQUEST,
  GET_ADMIN_FAQ_REQUEST,
} from './Faq.constant'
import {
  IAddAdminFaqRequest,
  IAdminFaqResponse,
  IAdminFaqResponseData,
  IDeleteAdminFaqRequest,
  IEditAdminFaqRequest,
  IGetAdminCurrentFaqRequest,
  IGetAdminFaqRequest,
} from './Faq.type'

function* workerGetAdminFaq({ payload }: IGetAdminFaqRequest) {
  try {
    const response: { data: IAdminFaqResponse } = yield call(
      getAdminFaqApi,
      payload,
    )

    yield put(getAdminFaqSuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getAdminFaqFailure, error as string)
  }
}

function* watchGetAdminFaq() {
  yield takeLatest(GET_ADMIN_FAQ_REQUEST, workerGetAdminFaq)
}

function* workerGetAdminCurrentFaq({ payload }: IGetAdminCurrentFaqRequest) {
  try {
    const response: { data: { data: IAdminFaqResponseData } } = yield call(
      getAdminCurrentFaqApi,
      Number(payload),
    )

    yield put(getAdminCurrentFaqSuccess(response?.data?.data))
  } catch (error) {
    yield getRequestErrors(getAdminCurrentFaqFailure, error as string)
  }
}

function* watchGetAdminCurrentFaq() {
  yield takeLatest(GET_ADMIN_CURRENT_FAQ_REQUEST, workerGetAdminCurrentFaq)
}

function* workerAddAdminFaq({ payload }: IAddAdminFaqRequest) {
  try {
    yield call(addAdminFaqApi, payload)

    yield put(addAdminFaqSuccess())
  } catch (error) {
    yield getRequestErrors(addAdminFaqFailure, error as string)
  }
}

function* watchAddAdminFaq() {
  yield takeLatest(ADD_ADMIN_FAQ_REQUEST, workerAddAdminFaq)
}

function* workerEditAdminFaq({ payload }: IEditAdminFaqRequest) {
  try {
    yield call(editAdminFaqApi, payload)

    yield put(editAdminFaqSuccess())
  } catch (error) {
    yield getRequestErrors(editAdminFaqFailure, error as string)
  }
}

function* watchEditAdminFaq() {
  yield takeLatest(EDIT_ADMIN_FAQ_REQUEST, workerEditAdminFaq)
}

function* workerDeleteAdminFaq({ payload }: IDeleteAdminFaqRequest) {
  try {
    yield call(deleteAdminFaqApi, payload)

    yield put(deleteAdminFaqSuccess())
  } catch (error) {
    yield getRequestErrors(deleteAdminFaqFailure, error as string)
  }
}

function* watchDeleteAdminFaq() {
  yield takeLatest(DELETE_ADMIN_FAQ_REQUEST, workerDeleteAdminFaq)
}

export const adminFaqWatchers = [
  fork(watchGetAdminFaq),
  fork(watchGetAdminCurrentFaq),
  fork(watchAddAdminFaq),
  fork(watchEditAdminFaq),
  fork(watchDeleteAdminFaq),
]

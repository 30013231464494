import { TimeFromTo } from 'entities/common';
import {
  AppointmentType,
  ValidationErrorType,
} from 'entities/manager/appointments';
import { ServiceType } from 'entities/manager/service';
import { StaffType } from 'entities/manager/staff';
import { ResponseFailureType } from 'store/Auth/Auth.type';

import {
  SET_VALIDATION_ERROR,
  RESET_VALIDATION_ERROR,
  UPDATE_APPOINTMENT_SEARCH_FAILURE,
  UPDATE_APPOINTMENT_SEARCH_REQUEST,
  UPDATE_APPOINTMENT_AVAILABLE_REQUEST,
  UPDATE_APPOINTMENT_AVAILABLE_SUCCESS,
  UPDATE_APPOINTMENT_AVAILABLE_FAILURE,
  RESET_AVAILABLE_TIME,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_FAILURE,
  GET_APPOINTMENT_SCHEDULE_SUCCESS,
  UPDATE_SEARCH_STAFF_SUCCESS,
  UPDATE_SEARCH_SERVICE_SUCCESS,
  UPDATE_SEARCH_CONTACT_SUCCESS,
  UPDATE_SEARCH_CLIENT_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  CLEAR_STORE,
  SET_QUERY,
  DELETE_APPOINTMENT_FAILURE,
  DELETE_APPOINTMENT_SUCCESS,
  DELETE_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_MOBILE_SUCCESS,
  SET_REPEAT_REQUEST,
  RESET_ERROR,
  GET_ONE_SERVICE_REQUEST,
  GET_ONE_SERVICE_SUCCESS,
  GET_ONE_SERVICE_FAILURE,
  GET_HAS_CLIENT_REQUEST,
  GET_HAS_CLIENT_SUCCESS,
  GET_HAS_CLIENT_FAILURE,
} from './Appointments.constant';

export type SearchClientType = {
  email: string | null;
  id: number;
  name: string;
  phone: string;
  type: string;
};

export type IAppointmentState = {
  appointmens: AppointmentType[];
  appointmensMobile: AppointmentType[];
  appointmentsError: ValidationErrorType[];
  availableTime: string[];
  clientList: SearchClientType[];
  contactList: SearchClientType[];
  errorMessage: string | null;
  errors: { [key: string]: string[] } | object;
  isAppointmentAction: boolean;
  isClientLoading: boolean;
  isContactLoading: boolean;
  isGetLoading: boolean;
  isLoading: boolean;
  isRepeatRequest: boolean;
  isSearchLoading: boolean;
  isServiceLoading: boolean;
  isStaffLoading: boolean;
  isUpdateLoading: boolean;
  query: string;
  schedule: { [key: string]: TimeFromTo };
  service: Partial<ServiceType> | null;
  serviceList: ServiceType[];
  staffList: StaffType[];
};

export type AppointmentSearchValues = {
  from: string;
  search: string;
  service_id?: number;
  staff_id?: number;
  time?: {
    from?: string;
    to?: string;
  };
};

export type AppointmentAvailableValues = {
  appointment_id?: number;
  from: string;
  service_id?: number;
  staff_id?: number;
};

export type AppointmentValues = {
  client_id?: number;
  contact_name?: string;
  contact_phone?: string;
  from: string;
  service_id: number;
  staff_id: number;
  started_at: string;
};

export type AppointmentCreateValues = {
  client_id?: number;
  contact_name?: string;
  contact_phone?: string;
  from: string;
  service_id?: number;
  staff_id?: number;
  started_at?: string;
};

export enum MethodSearch {
  CLIENT = 'client',
  CONTACT = 'contact',
  SERVICE = 'service',
  STAFF = 'staff',
}

interface ISetValidationError {
  payload: ValidationErrorType[];
  type: typeof SET_VALIDATION_ERROR;
}

interface IResetValidationError {
  type: typeof RESET_VALIDATION_ERROR;
}

interface IResetAvailableTime {
  type: typeof RESET_AVAILABLE_TIME;
}

export interface IUpdateAppointmentSearchResponse {
  data: any;
}
export interface IUpdateAppointmentSearchRequest {
  payload: { body: AppointmentSearchValues; method: MethodSearch };
  type: typeof UPDATE_APPOINTMENT_SEARCH_REQUEST;
}

interface IUpdateSearchClientSuccess {
  payload: SearchClientType[];
  type: typeof UPDATE_SEARCH_CLIENT_SUCCESS;
}

interface IUpdateSearchContactSuccess {
  payload: any;
  type: typeof UPDATE_SEARCH_CONTACT_SUCCESS;
}

interface IUpdateSearchServiceSuccess {
  payload: ServiceType[];
  type: typeof UPDATE_SEARCH_SERVICE_SUCCESS;
}

interface IUpdateSearchStaffSuccess {
  payload: StaffType[];
  type: typeof UPDATE_SEARCH_STAFF_SUCCESS;
}

interface IUpdateAppointmentSearchFailure {
  payload: ResponseFailureType;
  type: typeof UPDATE_APPOINTMENT_SEARCH_FAILURE;
}

export interface IUpdateAppointmentAvailableRequest {
  payload: AppointmentAvailableValues;
  type: typeof UPDATE_APPOINTMENT_AVAILABLE_REQUEST;
}

interface IUpdateAppointmentAvailableSuccess {
  payload: string[];
  type: typeof UPDATE_APPOINTMENT_AVAILABLE_SUCCESS;
}

interface IUpdateAppointmentAvailableFailure {
  payload: ResponseFailureType;
  type: typeof UPDATE_APPOINTMENT_AVAILABLE_FAILURE;
}

export interface IUpdateAppointmentRequest {
  payload: { body: AppointmentValues; id: number };
  type: typeof UPDATE_APPOINTMENT_REQUEST;
}

interface IUpdateAppointmentSuccess {
  payload: number;
  type: typeof UPDATE_APPOINTMENT_SUCCESS;
}

interface IUpdateAppointmentFailure {
  payload: ResponseFailureType;
  type: typeof UPDATE_APPOINTMENT_FAILURE;
}

export interface IGetAppointmentResponse {
  data: AppointmentType[];
  schedule: {
    [key: string]: TimeFromTo;
  };
}
export interface IGetAppointmentRequest {
  payload: {
    query: string;
    type?: string;
  };
  type: typeof GET_APPOINTMENT_REQUEST;
}

interface IGetAppointmentSuccess {
  payload: AppointmentType[];
  type: typeof GET_APPOINTMENT_SUCCESS;
}

interface IGetAppointmentMobileSuccess {
  payload: AppointmentType[];
  type: typeof GET_APPOINTMENT_MOBILE_SUCCESS;
}

interface IGetAppointmentScheduleSuccess {
  payload: {
    [key: string]: TimeFromTo;
  };
  type: typeof GET_APPOINTMENT_SCHEDULE_SUCCESS;
}

interface IGetAppointmentFailure {
  payload: ResponseFailureType;
  type: typeof GET_APPOINTMENT_FAILURE;
}

export interface ICreateAppointmentRequest {
  payload: AppointmentCreateValues;
  type: typeof CREATE_APPOINTMENT_REQUEST;
}

interface ICreateAppointmentSuccess {
  type: typeof CREATE_APPOINTMENT_SUCCESS;
}

interface ICreateAppointmentFailure {
  payload: ResponseFailureType;
  type: typeof CREATE_APPOINTMENT_FAILURE;
}

export interface IDeleteAppointmentRequest {
  payload: number;
  type: typeof DELETE_APPOINTMENT_REQUEST;
}

interface IDeleteAppointmentSuccess {
  payload: number;
  type: typeof DELETE_APPOINTMENT_SUCCESS;
}

interface IDeleteAppointmentFailure {
  payload: ResponseFailureType;
  type: typeof DELETE_APPOINTMENT_FAILURE;
}

export interface IGetOneServicesRequest {
  payload: number;
  type: typeof GET_ONE_SERVICE_REQUEST;
}

interface IGetOneServiceSuccess {
  payload: ServiceType;
  type: typeof GET_ONE_SERVICE_SUCCESS;
}

interface IGetOneServiceFailure {
  payload: ResponseFailureType;
  type: typeof GET_ONE_SERVICE_FAILURE;
}

interface ISetQuery {
  payload: string;
  type: typeof SET_QUERY;
}

interface IClearStore {
  type: typeof CLEAR_STORE;
}

interface ISetRepeatRequest {
  payload: boolean;
  type: typeof SET_REPEAT_REQUEST;
}

interface IResetError {
  type: typeof RESET_ERROR;
}

export interface IGetHasClientRequestPayload {
  name: string;
  phone: string;
}

export interface IGetHasClientRequest {
  payload: IGetHasClientRequestPayload;
  type: typeof GET_HAS_CLIENT_REQUEST;
}

export interface IGetHasClientSuccess {
  payload: { message: string };
  type: typeof GET_HAS_CLIENT_SUCCESS;
}

export interface IGetHasClientFailure {
  payload: ResponseFailureType;
  type: typeof GET_HAS_CLIENT_FAILURE;
}

export type AppointmentsActionTypes =
  | ISetValidationError
  | IResetValidationError
  | IUpdateAppointmentSearchRequest
  | IUpdateAppointmentSearchFailure
  | IUpdateAppointmentAvailableRequest
  | IUpdateAppointmentAvailableSuccess
  | IUpdateAppointmentAvailableFailure
  | IResetAvailableTime
  | IUpdateAppointmentRequest
  | IUpdateAppointmentSuccess
  | IUpdateAppointmentFailure
  | IGetAppointmentRequest
  | IGetAppointmentSuccess
  | IGetAppointmentFailure
  | IGetAppointmentScheduleSuccess
  | IUpdateSearchClientSuccess
  | IUpdateSearchContactSuccess
  | IUpdateSearchServiceSuccess
  | IUpdateSearchStaffSuccess
  | ICreateAppointmentRequest
  | ICreateAppointmentSuccess
  | ICreateAppointmentFailure
  | IClearStore
  | ISetQuery
  | IDeleteAppointmentRequest
  | IDeleteAppointmentSuccess
  | IDeleteAppointmentFailure
  | IGetAppointmentMobileSuccess
  | ISetRepeatRequest
  | IResetError
  | IGetOneServicesRequest
  | IGetOneServiceSuccess
  | IGetOneServiceFailure
  | IGetHasClientRequest
  | IGetHasClientSuccess
  | IGetHasClientFailure;

import { ChangeEvent, FC, memo, useEffect } from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowSVG } from 'assets/images/common/redesign/arrowBottom.svg';
import { SelectOption } from 'entities/common';
import {
  resetError,
  updateAppointmentAvailableRequest,
  updateAppointmentSearchRequest,
} from 'store/Manager/Appointments/Appointments.action';
import { MethodSearch } from 'store/Manager/Appointments/Appointments.type';
import { IRootState } from 'store/Root.reducer';
import { UIDatePicker, UISelect } from 'ui';
import {
  FORMAT_DATE,
  FORMAT_DATE_WEEK_DAY_MONTH,
  FORMAT_TIME_HOUR_WITH_MIN,
  STANDART_DATE,
} from 'utils';
import { DateHelper } from 'utils/helpers';

import { PropsType } from './index.type';
import { SelectOptionType } from '../index.type';
import styles from '../style.module.scss';
import { addAlert } from 'store/Alert';

const UIAppointmentsSidebarValidate: FC<PropsType> = ({
  isValidateError,
  values,
  setValues,
}) => {
  const { availableTime, isLoading, isStaffLoading, staffList, errorMessage } =
    useSelector((state: IRootState) => state.appointments);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const endTime = DateHelper.toFormat(
    new Date(`${STANDART_DATE} ${values.started_at}:00`).valueOf() +
      (new Date(`${STANDART_DATE} ${values.serviceDuration}`).valueOf() -
        new Date(`${STANDART_DATE} 00:00:00`).valueOf()),
    FORMAT_TIME_HOUR_WITH_MIN,
  );

  const handleSearch = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(values.from, FORMAT_DATE),
          // appointment_id: values.id || 0,
          time: {
            from: values.started_at || '',
            to: endTime.slice(0, 5),
          },
        },
        method: MethodSearch.STAFF,
      }),
    );
  };

  const isDisable = !values.id;

  const disabledDate = (date: Date) => {
    const ONE_DAY = 86300000;

    return date && date.valueOf() <= new Date().valueOf() - ONE_DAY;
  };

  const handleFocusTime = () => {
    dispatch(
      updateAppointmentAvailableRequest({
        staff_id: values.staff_id || 0,
        appointment_id: values.id || 0,
        from: DateHelper.toFormat(values.from, FORMAT_DATE),
      }),
    );
  };

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      );
    }

    return () => {
      dispatch(resetError());
    };
  }, [errorMessage, dispatch]);

  return (
    <div className={styles.validateContent}>
      <div
        className={cn(styles.wrappPicker, {
          [styles.wrappPickerError]: isValidateError,
        })}
      >
        <UIDatePicker
          format={FORMAT_DATE_WEEK_DAY_MONTH}
          value={new Date(values.from || new Date())}
          onChange={(date) => {
            setValues({
              ...values,
              from: date,
            });
          }}
          className={styles.datePicker}
          disable={isDisable}
          disabledDate={disabledDate}
        />
        <ArrowSVG />
      </div>

      <div className={styles.timepick}>
        <UISelect
          label={t('COMMON.START_TIME')}
          value={values.started_at}
          onFocus={handleFocusTime}
          isLoading={isLoading}
          options={availableTime.map((item, index) => ({
            value: index,
            label: item.slice(0, 5),
          }))}
          onChange={(_, obj) => {
            setValues({
              ...values,
              started_at: (obj as SelectOption).label,
            });
          }}
          disabled={isDisable}
        />

        <span className={styles.dash}></span>

        <UISelect
          label={t('COMMON.END_TIME')}
          {...(values.started_at && {
            value: endTime,
          })}
          onFocus={handleFocusTime}
          disabled
        />
      </div>

      <UISelect
        label={t('MANAGER.BUTTON.ADD_MASTER')}
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_MASTER')}
        value={values.staffName}
        showSearch
        options={staffList?.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onFocus={(e) =>
          handleSearch(
            (e as unknown as ChangeEvent<HTMLInputElement>).target.value,
          )
        }
        isLoading={isStaffLoading}
        onChange={(value, obj) => {
          setValues({
            ...values,
            staff_id: value,
            staffName: (obj as SelectOption).label,
          });
        }}
        disabled={isDisable}
        onSearch={(value) => handleSearch(value)}
        filterOption={(inputValue: string, option: SelectOptionType) =>
          option?.label
            ?.toLowerCase()
            ?.includes(inputValue?.toLowerCase()) as boolean
        }
      />
    </div>
  );
};

export default memo(UIAppointmentsSidebarValidate);

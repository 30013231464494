import { FC, useState, useEffect, memo } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { TimeWeekType } from 'entities/manager/staff'
import {
  updateScheduleRequest,
  resetStore as resetShiftStore,
} from 'store/Manager/Shift/Shift.action'
import {
  getOneStaffRequest,
  resetStaffTime,
  resetStore,
} from 'store/Manager/Staff'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomModal, UIDatePicker, UISelect } from 'ui'
import { FORMAT_DATE } from 'utils'

import { HourType, INITIAL_VALUES } from './constant'
import { DayTextType, PropsType, ValuesType } from './index.type'
import WidgetModalStaffEditHourRow from './Row'
import styles from './style.module.scss'

const WidgetModalStaffEditHour: FC<PropsType> = ({ staffId, isShift }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    staffSchedule: stafTime,
    staff,
    isStaffAction,
    errorMessage,
    isShiftAction,
    isLoading,
    isRepeatRequest,
  } = useSelector((state: IRootState) => ({
    ...state.staff,
    isLoading: state.shift.isLoading,
    isShiftAction: state.shift.isShiftAction,
    isRepeatRequest: state.appointments.isRepeatRequest,
  }))
  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)
  const [isDisable, setIsDisable] = useState(true)

  const handleCancel = () => {
    dispatch(hideModal())
  }

  const handleChange = (type: number) => {
    const getType = (index: number) => {
      switch (index) {
        case 1:
          return HourType.IMMEDIATELY

        case 2:
          return HourType.NEXT_WEEK

        case 3:
          return HourType.CUSTOM

        default:
          return HourType.IMMEDIATELY
      }
    }

    setValues({ ...values, type: getType(type) })
  }

  const handleOk = () => {
    if (staffId) {
      dispatch(updateScheduleRequest({ body: values, id: staffId }))
      dispatch(getOneStaffRequest(staffId))
    }

    setValues({ ...values, type: HourType.IMMEDIATELY })
  }

  const disabledDate = (date: Date) =>
    date && date.valueOf() < new Date().valueOf()

  const weekArray = [
    {
      label: t('MANAGER.PROFILE_SALON.MONDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'monday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.TUESDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'tuesday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.WEDNESDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'wednesday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.THURSDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'thursday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.FRIDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'friday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.SATURDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'saturday',
    },
    {
      label: t('MANAGER.PROFILE_SALON.SUNDAY'),
      break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
      name: 'sunday',
    },
  ]

  useEffect(() => {
    if (isRepeatRequest && staffId) {
      dispatch(updateScheduleRequest({ body: values, id: staffId }))
    }
  }, [dispatch, isRepeatRequest, staffId, values])

  useEffect(() => {
    if ((isStaffAction || isShiftAction) && staffId) {
      dispatch(getOneStaffRequest(staffId))
    }
  }, [dispatch, isShiftAction, isStaffAction, staffId])

  useEffect(() => {
    if ((isStaffAction || isShiftAction) && !errorMessage) {
      dispatch(resetStore())
      dispatch(resetShiftStore())
      setValues({ ...values, type: HourType.IMMEDIATELY })
      dispatch(hideModal())
    }
  }, [dispatch, errorMessage, isStaffAction, values, isShift, isShiftAction])

  useEffect(() => {
    if (stafTime) {
      const setInitilTime = (week: DayTextType) => ({
        ...((stafTime as unknown as TimeWeekType)?.[week]?.work && {
          work: {
            from: (stafTime as unknown as TimeWeekType)?.[week].work.from,
            to: (stafTime as unknown as TimeWeekType)?.[week].work.to,
          },
        }),
        ...((stafTime as unknown as TimeWeekType)?.[week]?.break && {
          break: {
            from: (stafTime as unknown as TimeWeekType)?.[week].break.from,
            to: (stafTime as unknown as TimeWeekType)?.[week].break.to,
          },
        }),
      })

      setValues({
        ...values,
        schedule: {
          friday: setInitilTime('friday'),
          monday: setInitilTime('monday'),
          saturday: setInitilTime('saturday'),
          sunday: setInitilTime('sunday'),
          thursday: setInitilTime('thursday'),
          tuesday: setInitilTime('tuesday'),
          wednesday: setInitilTime('wednesday'),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stafTime])

  useEffect(() => {
    return () => {
      dispatch(resetStaffTime())
    }
  }, [dispatch])

  return (
    <UICustomModal
      title={
        <>
          {t('MANAGER.STAFF.EDIT_HOURS')} - <span>{staff?.name}</span>
        </>
      }
    >
      <div className={styles.wrapper}>
        <Spin spinning={isLoading}>
          {weekArray.map((item, index) => {
            return (
              <WidgetModalStaffEditHourRow
                day={index}
                text={item.label as unknown as DayTextType}
                formName={item.name as unknown as DayTextType}
                key={index}
                values={values}
                setValues={setValues}
                setIsDisable={setIsDisable}
              />
            )
          })}

          <div className={styles.line}></div>

          <div className={styles.footer}>
            <div className={styles.select}>
              <p className={styles.selectTitle}>
                {t('MANAGER.STAFF.EFFECTIVE')}
              </p>

              <div>
                <UISelect
                  defaultValue={HourType.IMMEDIATELY}
                  value={values.type}
                  onChange={handleChange}
                  className={styles.type}
                  options={[
                    { value: 1, label: t('MANAGER.STAFF.IMMEDIATELY') },
                    { value: 2, label: t('MANAGER.STAFF.NEXT_WEEK') },
                    { value: 3, label: t('MANAGER.STAFF.CUSTOM') },
                  ]}
                />

                {values.type === HourType.CUSTOM && (
                  <UIDatePicker
                    format={FORMAT_DATE}
                    onChange={(_, dateString) => {
                      setValues({
                        ...values,
                        started_at: dateString,
                      })
                    }}
                    disabledDate={disabledDate}
                  />
                )}
              </div>
            </div>

            <div className={styles.btn}>
              <UIButton
                label={t('COMMON.CANCEL')}
                handler={handleCancel}
                type="outline"
              />

              <UIButton
                label={t('COMMON.SAVE')}
                disabled={isDisable}
                handler={handleOk}
              />
            </div>
          </div>
        </Spin>
      </div>
    </UICustomModal>
  )
}

export default memo(WidgetModalStaffEditHour)

import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_NOTIFICATION_ERROR,
  GET_ADMIN_NOTIFICATIONS_REQUEST,
  GET_ADMIN_NOTIFICATIONS_FAILURE,
  GET_ADMIN_NOTIFICATIONS_SUCCESS,
  GET_ADMIN_SIDEBAR_NOTIFICATIONS_SUCCESS,
  GET_ADMIN_SIDEBAR_NOTIFICATIONS_FAILURE,
} from './Notifications.constant'
import {
  IAdminNotificationsState,
  NotificationsActionsTypes,
} from './Notifications.type'

const initialState: IAdminNotificationsState = {
  errorMessage: null,
  isLoading: false,
  data: [],
  meta: {
    page: 1,
    perPage: 0,
    total: 0,
  },
  notificationSidebar: {},
}

export default function adminNotificationsReducer(
  state = initialState,
  action: NotificationsActionsTypes,
): IAdminNotificationsState {
  switch (action.type) {
    case GET_ADMIN_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true, errorMessage: null }
    case GET_ADMIN_NOTIFICATIONS_FAILURE:
    case GET_ADMIN_SIDEBAR_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        ...(action.payload as unknown as ResponseFailureType),
      }
    case GET_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        meta: {
          page: action.payload.meta.current_page,
          perPage: action.payload.meta.per_page,
          total: action.payload.meta.total,
        },
      }
    case GET_ADMIN_SIDEBAR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationSidebar: action.payload,
      }
    case CLEAR_NOTIFICATION_ERROR:
      return {
        ...state,
        errorMessage: null,
      }
    default:
      return { ...state }
  }
}

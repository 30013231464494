/* eslint-disable import/no-cycle */
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import { FC } from 'react'

import { Params } from 'react-router-dom'

import {
  ADMIN_BLOG,
  ADMIN_BLOG_ADD,
  ADMIN_BLOG_ADD_CATEGORY,
  ADMIN_BLOG_EDIT,
  ADMIN_BLOG_EDIT_CATEGORY,
  ADMIN_CATEGORIES,
  ADMIN_CUSTOMERS,
  ADMIN_CUSTOMER_PROFILE,
  ADMIN_HELP_FOR_BUSINESS,
  ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE,
  ADMIN_HELP_FOR_CUSTOMER,
  ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE,
  ADMIN_NOTIFICATION,
  ADMIN_PAGES,
  ADMIN_PAGES_EDIT,
  ADMIN_SALONS,
  ADMIN_SALONS_PROFILE,
  ADMIN_SEO,
  ADMIN_SEO_EDIT_CATEGORY,
  ADMIN_SEO_EDIT_SUBCATEGORY,
  ADMIN_SETTINGS,
} from 'utils'
import { StringHelper } from 'utils/helpers'

export type SidebarType = {
  Icon: FC
  ItemIcon?: FC
  className?: string
  label: string
  link?: string
  notification?: boolean
  notificationCount?: number | null
  notificationName?: MenuNotificationName
}

export type AdminTextTemplateDataType = {
  description: string
  title: string
}

export const getDefaultAdminSelectedKeys = (
  pathname: string,
  param: Readonly<Params<string>>,
) => {
  switch (pathname) {
    case ADMIN_CATEGORIES:
      return ['ADMIN.SIDEBAR.CATEGORIES']
    case ADMIN_CUSTOMERS:
    case StringHelper.replaceByTypes(ADMIN_CUSTOMER_PROFILE, param):
      return ['ADMIN.SIDEBAR.CUSTOMERS']
    case ADMIN_SALONS:
    case StringHelper.replaceByTypes(ADMIN_SALONS_PROFILE, param):
      return ['ADMIN.SIDEBAR.SALONS']
    case ADMIN_PAGES:
    case StringHelper.replaceByTypes(ADMIN_PAGES_EDIT, param, ['id', 'type']):
      return ['ADMIN.SIDEBAR.PAGES']
    case ADMIN_SETTINGS:
      return ['ADMIN.SIDEBAR.SETTINGS']
    case ADMIN_HELP_FOR_BUSINESS:
    case StringHelper.replaceByTypes(
      ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE,
      param,
    ):
      return ['ADMIN.SIDEBAR.HELP_FOR_BUSINESS']
    case ADMIN_HELP_FOR_CUSTOMER:
    case StringHelper.replaceByTypes(
      ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE,
      param,
    ):
      return ['ADMIN.SIDEBAR.HELP_FOR_CUSTOMERS']
    case ADMIN_SEO:
    case StringHelper.replaceByTypes(ADMIN_SEO_EDIT_CATEGORY, param, [
      'category',
    ]):
    case StringHelper.replaceByTypes(ADMIN_SEO_EDIT_SUBCATEGORY, param, [
      'category',
      'subcategory',
    ]):
      return ['ADMIN.SIDEBAR.SEO']
    case ADMIN_NOTIFICATION:
      return ['ADMIN.SIDEBAR.NOTIFICATION']
    case ADMIN_BLOG:
    case StringHelper.replaceByTypes(ADMIN_BLOG_EDIT, param, ['id']):
    case ADMIN_BLOG_ADD:
    case ADMIN_BLOG_ADD_CATEGORY:
    case StringHelper.replaceByTypes(ADMIN_BLOG_EDIT_CATEGORY, param, ['id']):
      return ['ADMIN.SIDEBAR.BLOG']

    default:
      return []
  }
}

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { FC, useContext } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { ReactComponent as LogoSVG } from 'assets/images/common/newSidebar/logo.svg'
import { ReactComponent as LogoutSVG } from 'assets/images/common/newSidebar/logout.svg'
import { ManagerSidebarContext } from 'context'
import { SidebarType } from 'entities/admin'
import { logoutRequest } from 'store/Auth'
import { CLIENT_LINK } from 'utils'

import styles from './style.module.scss'

type PropsType = {
  defaultSelectedKeys: string[]
  items: SidebarType[]
}

const WidgetSidebar: FC<PropsType> = ({ items, defaultSelectedKeys }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { onCloseMenu, isShowHeader } = useContext(ManagerSidebarContext)

  const handleLogout = () => {
    dispatch(logoutRequest())
  }

  const handleCloseMenu = () => {
    if (isShowHeader) {
      onCloseMenu()
    }
  }

  const handleLink = () => {
    window.location.href = CLIENT_LINK
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <div className={styles.logo}>
          <a onClick={handleLink}>
            <LogoSVG />
          </a>
        </div>

        {items.map(
          (
            { link, label, Icon, className, notification, notificationCount },
            index,
          ) => {
            return (
              <Link
                key={index}
                className={cn(styles.menuItem, className, {
                  [styles.menuActive]: defaultSelectedKeys?.find(
                    (item) => item === label,
                  ),
                  [styles.hideStroke]: label === 'MANAGER.SIDEBAR.CLIENTS',
                  [styles.menuNotification]: notification,
                })}
                to={link || ''}
                onClick={handleCloseMenu}
              >
                <Icon />
                {notificationCount ? (
                  <span className={styles.menuNotificationCount}>
                    {notificationCount}
                  </span>
                ) : null}
                <div className={styles.menuTitle}>{t(label)}</div>
              </Link>
            )
          },
        )}

        <div className={styles.logOut} onClick={handleLogout}>
          <LogoutSVG />
          <div className={styles.menuTitle}>{t('COMMON.LOG_OUT')}</div>
        </div>
      </div>
    </div>
  )
}

export default WidgetSidebar

import { FC, memo, useMemo } from 'react';

import { addDays, format, startOfWeek } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { PropsType, RenderType } from './index.type';
import style from './style.module.scss';

const AdminSalonSectionFive: FC<PropsType> = ({ times }) => {
  const { t } = useTranslation();

  const renderTimes: RenderType[] = useMemo(() => {
    if (!times) return [];

    const timesArray = [...times];
    const newTimes: RenderType[] = [];

    timesArray
      .sort((a, b) => a.day - b.day)
      .forEach(({ day, end, start, type }) => {
        const isBreak = type === 2;
        const time = {
          start: start.slice(0, start.length - 3),
          end: end.slice(0, end.length - 3),
        };
        const dayOfWeek: string = format(
          addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), day - 1),
          'EEEE',
        );

        newTimes[day - 1] = {
          ...(newTimes[day - 1] ? newTimes[day - 1] : {}),
          ...(isBreak && { break: time }),
          ...(!isBreak && { day: dayOfWeek, work: time }),
        } as RenderType;
      });

    return newTimes;
  }, [times]);

  return (
    <section className={style.five}>
      <h2>5. {t('ADMIN.PROFILE_SALON.SECTION_5.TITLE')}</h2>

      <div className={style.wrapper}>
        {renderTimes.map(
          ({ day, break: breakTime, work: { end, start } }, index) => (
            <div key={index} className={style.dayWrapper}>
              <p>{day}</p>

              <div className={style.work}>
                <span>{start}</span>
                <span>{end}</span>
              </div>

              {!!breakTime && (
                <div className={style.break}>
                  <p>{t('COMMON.BREAK')}</p>

                  <span>{breakTime.start}</span>
                  <span>{breakTime.end}</span>
                </div>
              )}
            </div>
          ),
        )}
      </div>
    </section>
  );
};

export default memo(AdminSalonSectionFive);

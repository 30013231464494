import { ChangeEvent, FC, memo, useState } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { ReactComponent as PlusSVG } from 'assets/images/common/redesign/plusCircle.svg'
import { createPhoneRequest, deletePhoneRequest } from 'store/Manager/Salon'
import { UIButton, UIPhoneInput } from 'ui'
import { VALIDATION_CLASS } from 'utils'

import { PropsType } from './index.type'
import ProfileSalonPhoneInput from './Input'
import styles from '../../../../views/manager/ProfileSalon/style.module.scss'

const ProfileSalonPhone: FC<PropsType> = ({
  numbers,
  setNumbers,
  setValues,
  values,
  errors,
}) => {
  const [isError, setIsError] = useState(false)
  const [number, setNumber] = useState<string>('')

  const { t } = useTranslation()

  const dispatch = useDispatch()

  const handleDeletePhone = (id: number) => {
    dispatch(deletePhoneRequest(id))
    setNumbers(numbers.filter((i) => i.id !== id))
    setValues({
      ...values,
      phones: values.phones?.filter((i) => i !== id),
    })
  }

  const handleAddPhone = () => {
    if (number?.replace(/[-_]/g, '').length === 10 && number) {
      dispatch(
        createPhoneRequest({
          number: `52-${number}`,
        }),
      )
      setNumber('')
    } else {
      setIsError(true)
    }
  }

  const handlerPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false)
    setNumber(e.target.value)
  }

  return (
    <>
      <span className={styles.phoneTitle}>
        {t('COMMON.PHONE_NUMBER')}{' '}
        <span className={styles.necessaryLabel}>
          {`(${t('COMMON.NECESSARY')})`}
        </span>
      </span>

      {errors?.phones && (
        <p
          className={classNames('text-error desc-1', {
            [VALIDATION_CLASS]: errors?.phones,
          })}
        >
          {errors?.phones}
        </p>
      )}

      {numbers?.map(({ number: phone, id }, i) => {
        return (
          <ProfileSalonPhoneInput
            numbers={numbers}
            number={phone}
            key={i}
            setNumbers={setNumbers}
            index={i}
            id={id || 0}
            onDelete={handleDeletePhone}
          />
        )
      })}

      <div className={styles.numberInput}>
        <UIPhoneInput
          value={number}
          onChange={(e) => handlerPhoneChange(e)}
          error={isError ? t('MANAGER.PROFILE_SALON.VALIDATION') : undefined}
        />
      </div>

      <div className={styles.button}>
        <UIButton
          type="text"
          label={t('MANAGER.PROFILE_SALON.PHONE_NUMBER')}
          icon={<PlusSVG />}
          handler={handleAddPhone}
        />
      </div>
    </>
  )
}

export default memo(ProfileSalonPhone)

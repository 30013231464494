import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import { UploadFile } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useSelector, useDispatch } from 'react-redux'

import { editCategoryRequest, setActiveCategory } from 'store/Admin'
import { clearMediaStore, resetLinkType } from 'store/Media/Media.action'
import { UPLOAD_LINK_CATEGORY } from 'store/Media/Media.constant'
import { IRootState } from 'store/Root.reducer'

import Input from '../Input'

const getSrcFromFile = (file: UploadFile<any>) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.readAsDataURL(file.originFileObj as RcFile)
    reader.onload = () => resolve(reader.result)
  })
}

type PropsType = {
  active: boolean
  id: number
  isEdit?: boolean
  name: string
  pathImg?: string
  setIsEdit: (bol: boolean) => void
}

const AdminUICategory: FC<PropsType> = ({
  name,
  active,
  id,
  pathImg,
  setIsEdit,
  isEdit,
}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState(name)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const {
    activeCategory,
    categories,
    isLoading: isCategoryLoading,
  } = useSelector((state: IRootState) => state.adminCategories)
  const { fileData, errors, isloading, linkType } = useSelector(
    (state: IRootState) => state.media,
  )

  const handleClick = () => {
    dispatch(setActiveCategory(id))
  }

  const handleSave = () => {
    setIsEdit(false)

    dispatch(
      editCategoryRequest({
        name: value,
        image:
          fileData?.id ||
          categories.find((i) => i.id === activeCategory)?.image?.id ||
          null,
        id,
      }),
    )
    dispatch(clearMediaStore())
    dispatch(resetLinkType())
  }

  const handleSwitch = (isChecked: boolean) => {
    dispatch(
      editCategoryRequest({
        active: isChecked ? 1 : 0,
        id,
        image:
          fileData?.id ||
          categories.find((i) => i.id === id)?.image?.id ||
          null,
      }),
    )
  }

  const handleActive = () => {
    setIsEdit(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleUpload = async (file: UploadFile<any>) => {
    const src = file.url || ((await getSrcFromFile(file)) as string)
    const imgWindow = window.open(src)

    if (imgWindow) {
      const image = new Image()

      image.src = src
      imgWindow.document.write(image.outerHTML)
    } else {
      window.location.href = src
    }
  }

  useEffect(() => {
    if (pathImg && id === activeCategory) {
      setFileList([{ url: pathImg } as UploadFile])
    } else {
      setFileList([])
    }
  }, [activeCategory, id, pathImg])

  const onRemove = () => {
    dispatch(
      editCategoryRequest({
        name: value,
        image: null,
        id,
      }),
    )
    setFileList([])
    dispatch(clearMediaStore())
  }

  useEffect(() => {
    if (fileData && linkType === UPLOAD_LINK_CATEGORY) {
      setFileList([
        { url: fileData.path, id: fileData.id } as unknown as UploadFile,
      ])
    }
  }, [fileData, linkType])

  useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setFileList([])
    }
  }, [errors])

  return (
    <Input
      isCategory
      isActive={id === activeCategory}
      isEdit={isEdit}
      name={name}
      active={active}
      value={value}
      onSave={handleSave}
      onChange={handleChange}
      onActive={handleActive}
      handleClick={handleClick}
      handleSwitch={handleSwitch}
      handleUpload={handleUpload}
      linkType={UPLOAD_LINK_CATEGORY}
      fileList={fileList}
      pathImg={pathImg}
      onRemove={onRemove}
      isloading={isloading}
      isCategoryLoading={isCategoryLoading}
      errors={errors}
      fileData={fileData}
    />
  )
}

export default React.memo(AdminUICategory)

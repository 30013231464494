import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import { ICustomer, ResponseType } from 'entities/common'
import { getRequestErrors } from 'utils/helpers/requests'

import {
  getManagerClientsFailureAction,
  getManagerClientsSuccessAction,
  getManagerUsersFailureAction,
  getManagerUsersSuccessAction,
} from './Clients.action'
import { GET_MANAGER_CLIENTS, GET_MANAGER_USERS } from './Clients.constant'
import {
  IGetManagerClientsAction,
  IGetManagerUsersAction,
  IManagerClientsUsersData,
} from './Clients.type'
import {
  getManagerClientsApi,
  getManagerUsersApi,
} from '../../../services/http/manager/Clients'

function* workerGetManagerClients({ payload }: IGetManagerClientsAction) {
  try {
    const response: { data: ResponseType<ICustomer[]> } = yield call(
      getManagerClientsApi,
      payload,
    )

    yield put(getManagerClientsSuccessAction(response.data))
  } catch (error) {
    yield getRequestErrors(getManagerClientsFailureAction, error as string)
  }
}

function* watchGetManagerClients() {
  yield takeLatest(GET_MANAGER_CLIENTS, workerGetManagerClients)
}

function* workerGetManagerUsers({ payload }: IGetManagerUsersAction) {
  try {
    const response: { data: ResponseType<IManagerClientsUsersData[]> } =
      yield call(getManagerUsersApi, payload)

    yield put(getManagerUsersSuccessAction(response.data))
  } catch (error) {
    yield getRequestErrors(getManagerUsersFailureAction, error as string)
  }
}

function* watchGetManagerUsers() {
  yield takeLatest(GET_MANAGER_USERS, workerGetManagerUsers)
}

export const ManagerClientsWatchers: ForkEffect[] = [
  fork(watchGetManagerClients),
  fork(watchGetManagerUsers),
]

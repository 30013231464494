export const GET_ADMIN_SALONS_REQUEST = '@salons/GET_ADMIN_SALONS_REQUEST'
export const GET_ADMIN_SALONS_SUCCESS = '@salons/GET_ADMIN_SALONS_SUCCESS'
export const GET_ADMIN_SALONS_FAILURE = '@salons/GET_ADMIN_SALONS_FAILURE'

export const GET_ADMIN_CURRENT_SALON_REQUEST =
  '@salons/GET_ADMIN_CURRENT_SALON_REQUEST'
export const GET_ADMIN_CURRENT_SALON_SUCCESS =
  '@salons/GET_ADMIN_CURRENT_SALON_SUCCESS'
export const GET_ADMIN_CURRENT_SALON_FAILURE =
  '@salons/GET_ADMIN_CURRENT_SALON_FAILURE'

export const SET_ADMIN_CURRENT_SALON_REQUEST =
  '@salons/SET_ADMIN_CURRENT_SALON_REQUEST'
export const SET_ADMIN_CURRENT_SALON_SUCCESS =
  '@salons/SET_ADMIN_CURRENT_SALON_SUCCESS'
export const SET_ADMIN_CURRENT_SALON_FAILURE =
  '@salons/SET_ADMIN_CURRENT_SALON_FAILURE'

export const SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST =
  '@salons/SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_REQUEST'
export const SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_SUCCESS =
  '@salons/SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_SUCCESS'
export const SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_FAILURE =
  '@salons/SET_ADMIN_CURRENT_SALON_DOCUMENT_CONFIRMATION_FAILURE'

export const SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST =
  '@salons/SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_REQUEST'
export const SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_SUCCESS =
  '@salons/SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_SUCCESS'
export const SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_FAILURE =
  '@salons/SET_ADMIN_CURRENT_SALON_TOGGLE_SUBSCRIPTION_FAILURE'

export const REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST =
  '@salons/REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_REQUEST'
export const REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_SUCCESS =
  '@salons/REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_SUCCESS'
export const REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_FAILURE =
  '@salons/REMOVE_ADMIN_CURRENT_SALON_DOCUMENT_FAILURE'

export const CLEAR_SALONS_ERROR = '@salons/CLEAR_SALONS_ERROR'

import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  CLEAR_CLIENTS_ERROR,
  GET_MANAGER_CLIENTS,
  GET_MANAGER_CLIENTS_FAILURE,
  GET_MANAGER_CLIENTS_SUCCESS,
  GET_MANAGER_USERS,
  GET_MANAGER_USERS_FAILURE,
  GET_MANAGER_USERS_SUCCESS,
} from './Clients.constant'

export const getManagerClientsAction = (query?: string) => ({
  type: GET_MANAGER_CLIENTS,
  payload: query,
})

export const getManagerClientsSuccessAction = (data: unknown) => ({
  type: GET_MANAGER_CLIENTS_SUCCESS,
  payload: data,
})

export const getManagerClientsFailureAction = (error: ResponseFailureType) => ({
  type: GET_MANAGER_CLIENTS_FAILURE,
  payload: error,
})

export const getManagerUsersAction = (query?: string) => ({
  type: GET_MANAGER_USERS,
  payload: query,
})

export const getManagerUsersSuccessAction = (data: unknown) => ({
  type: GET_MANAGER_USERS_SUCCESS,
  payload: data,
})

export const getManagerUsersFailureAction = (error: ResponseFailureType) => ({
  type: GET_MANAGER_USERS_FAILURE,
  payload: error,
})

export const clearClientsError = () => ({
  type: CLEAR_CLIENTS_ERROR,
})

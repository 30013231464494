import { useTranslation } from 'react-i18next'

import LayoutManagerSidebar from 'layouts/Manager/Sidebar'
import SubscriptionHeader from 'modules/manager/subscription/Header'
import SubscriptionInfo from 'modules/manager/subscription/Info'
import { ModalType } from 'entities/modal'
import { showModal } from 'store/Modal'

import styles from './style.module.scss'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const ViewManagerSubscription = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem('subscription')) {
      dispatch(
        showModal({
          modalType: ModalType.NOTIFY,
          modalProps: {
            notifyTitle: t('MANAGER.NOTIFICATION_SUBSCRIPTION'),
            handler: () => localStorage.removeItem('subscription'),
          },
        }),
      )
    }
  }, [dispatch, t])

  return (
    <LayoutManagerSidebar
      titleHeader={t('MANAGER.SUBSCRIPTION')}
      className={'bg-white p-0'}
      isMobileContentVisible
    >
      <SubscriptionHeader />

      <div className={styles.wrapper}>
        <SubscriptionInfo />
      </div>
    </LayoutManagerSidebar>
  )
}

export default ViewManagerSubscription

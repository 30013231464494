import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SelectOption } from 'entities/common';
import { ServiceType } from 'entities/manager/service';
import { StaffType } from 'entities/manager/staff';
import {
  updateAppointmentAvailableRequest,
  updateAppointmentSearchRequest,
} from 'store/Manager/Appointments/Appointments.action';
import { MethodSearch } from 'store/Manager/Appointments/Appointments.type';
import { IRootState } from 'store/Root.reducer';
import { ModeType } from 'ui/CalendarHeader/index.type';
import { FORMAT_DATE, FORMAT_TIME_HOUR_WITH_MIN, STANDART_DATE } from 'utils';
import { DateHelper } from 'utils/helpers';

import { ExtendedPropsType, PropsType } from './index.type';
import { SelectOptionType } from '../index.type';
import styles from '../style.module.scss';
import UIAppointmendarSidebarFooter from '../Footer';
import AddClientTab from '../AddClientTab';
import { UISelect } from 'ui';
import CustomSearch from 'ui/CustomSearch';

const getTimeWithRounding = (date: Date = new Date()) => {
  const minute = DateHelper.toFormat(date, 'mm');
  const hour = Number(DateHelper.toFormat(date, 'HH'));
  const formattedDate = DateHelper.toFormat(date, 'yyyy-MM-dd');
  const minutes = `${minute.slice(0, 1)}${
    Number(minute.slice(1)) > 0 && Number(minute.slice(1)) <= 5 ? 5 : 0
  }`;
  const time = `${Number(minute) >= 55 ? hour + 1 : hour}:${minutes}`;

  return new Date(`${formattedDate} ${time}`);
};

// TODO refactor
const UIAppointmendarSidebarContent: FC<PropsType> = ({
  eventDate,
  eventData,
  setValues,
  values,
  selectDate,
  mode,
  headerDate,
  isAddAppointment,
  isBtnDisabled,
  handleSave,
  handleDelete,
}) => {
  const {
    isServiceLoading,
    isClientLoading,
    isStaffLoading,
    isLoading,
    staffList,
    clientList,
    serviceList,
    availableTime,
    service,
    errors,
  } = useSelector((state: IRootState) => state.appointments);

  const [isStartTimeEdit, setIsStartTimeEdit] = useState(false);
  const [localStaffList, setlocalStaffList] = useState<StaffType[]>([]);
  const [localServiceList, setLocalServiceList] = useState<ServiceType[]>([]);

  useEffect(() => {
    if (staffList) {
      setlocalStaffList(staffList);
    }
  }, [staffList]);

  useEffect(() => {
    if (serviceList) {
      setLocalServiceList(serviceList);
    }
  }, [serviceList]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const extendedProps = eventData?.event?.extendedProps as ExtendedPropsType;

  const endTime = useMemo(() => {
    const durationAdd = ((service && [service]) || serviceList)?.find(
      (item) => item?.id === values.service_id,
    )?.duration;

    const durationEdit = extendedProps ? extendedProps?.serviceDuration : '';

    return values.started_at
      ? DateHelper.toFormat(
          new Date(`${STANDART_DATE} ${values.started_at}:00`).valueOf() +
            (new Date(
              `${STANDART_DATE} ${
                isAddAppointment ? durationAdd : durationEdit
              }`,
            ).valueOf() -
              new Date(`${STANDART_DATE} 00:00:00`).valueOf()),
          FORMAT_TIME_HOUR_WITH_MIN,
        )
      : '';
  }, [
    extendedProps,
    isAddAppointment,
    service,
    serviceList,
    values.service_id,
    values.started_at,
  ]);

  const handleClientChange = useCallback(
    (value: string) => {
      dispatch(
        updateAppointmentSearchRequest({
          body: {
            search: value,
            from: DateHelper.toFormat(eventDate, FORMAT_DATE),
          },
          method: MethodSearch.CLIENT,
        }),
      );
    },
    [dispatch, eventDate],
  );

  const handleServiceChange = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(eventDate, FORMAT_DATE),
          ...(values.staff_id && { staff_id: values.staff_id }),
          ...((values.time?.from || values.time?.to) && {
            time: {
              ...(values.time.to && { to: values.time.to }),
              ...(values.time.from && { from: values.time.from }),
            },
          }),
        },
        method: MethodSearch.SERVICE,
      }),
    );
  };

  const handleStaffChange = (value: string) => {
    dispatch(
      updateAppointmentSearchRequest({
        body: {
          search: value,
          from: DateHelper.toFormat(values?.from, FORMAT_DATE),
          ...(values.service_id && { service_id: values.service_id }),
          ...((values.started_at || values.time?.to) && {
            time: {
              from: values.started_at || '',
              ...((values.started_at &&
                values.service_id &&
                values.started_at !== values.finished_at && { to: endTime }) ||
                (values.finished_at &&
                  values.started_at !== values.finished_at && {
                    to: values.finished_at,
                  })),
            },
          }),
        },
        method: MethodSearch.STAFF,
      }),
    );
  };

  const handleFocusTime = () => {
    dispatch(
      updateAppointmentAvailableRequest({
        ...(values.service_id && { service_id: values.service_id }),
        ...(values.staff_id && { staff_id: values.staff_id }),
        ...(values?.id && { appointment_id: values.id }),
        from: DateHelper.toFormat(values.from, FORMAT_DATE),
      }),
    );
  };

  const disabledDate = (date: Date) => {
    const ONE_DAY = 86300000;

    return date && date.valueOf() <= new Date().valueOf() - ONE_DAY;
  };

  const getContentDate = useCallback(() => {
    if (mode === ModeType.DAY) {
      return headerDate;
    }

    return (
      (eventData?.event?.end as unknown as string) ||
      (eventDate as unknown as string)
    );
  }, [eventData?.event?.end, eventDate, headerDate, mode]);

  useEffect(() => {
    if (extendedProps?.startTime || extendedProps?.endTime) {
      setValues((prevState) => ({
        ...prevState,
        from:
          (eventData?.event?.end as unknown as string) ||
          (eventDate as unknown as string),
        started_at: extendedProps?.startTime,
        finished_at: extendedProps?.endTime,
        ...(extendedProps?.contactName && {
          contact_name: extendedProps?.contactName,
        }),
        ...(extendedProps?.contactPhone && {
          contact_phone: extendedProps?.contactPhone?.slice(3),
        }),
        ...(extendedProps?.staff?.value && {
          staff_id: Number(extendedProps?.staff?.value),
          staffName: extendedProps?.staff?.label,
        }),
        ...(extendedProps?.service?.value && {
          service_id: Number(extendedProps?.service?.value),
          serviceName: extendedProps?.service?.label,
        }),
        ...(extendedProps?.price?.price && {
          price: extendedProps?.price?.price,
        }),
        ...(extendedProps?.client_id && {
          client_id: extendedProps?.client_id,
        }),
        ...(extendedProps?.clientName && {
          clientName: extendedProps?.clientName,
        }),
        ...(extendedProps?.client && {
          clientName: extendedProps?.client?.name,
          clientType: extendedProps?.client?.type,
          contact_name: extendedProps?.client?.name,
          contact_phone: extendedProps?.client?.phone?.slice(3),
          client_id: extendedProps?.client?.id,
        }),
        ...(extendedProps?.contact && {
          clientName: extendedProps?.contact?.name,
          clientType: extendedProps?.contact?.type,
          contact_name: extendedProps?.contact?.name,
          contact_phone: extendedProps?.contact?.phone?.slice(3),
          client_id: extendedProps?.contact?.id,
        }),
        ...(eventData?.event?.id && {
          id: +(eventData?.event?.id || 0),
        }),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedProps, eventData?.event, eventDate]);

  useEffect(() => {
    if (
      (!extendedProps?.startTime || !extendedProps?.endTime) &&
      (selectDate?.start || selectDate?.end || eventDate)
    ) {
      const startedDate = getTimeWithRounding(
        new Date(selectDate?.start || new Date()),
      );
      const endedDate = getTimeWithRounding(
        new Date(selectDate?.end || new Date()),
      );

      const startedAt = selectDate?.start
        ? DateHelper.toFormat(startedDate, FORMAT_TIME_HOUR_WITH_MIN)
        : '';

      const finishedAt = selectDate?.end
        ? DateHelper.toFormat(endedDate, FORMAT_TIME_HOUR_WITH_MIN)
        : '';

      setValues((prevState) => ({
        ...prevState,
        from: getContentDate() as string,
        started_at: startedAt,
        finished_at: finishedAt,
        time: {
          from: startedAt,
          to: finishedAt,
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendedProps, eventDate, eventData?.event?.end]);

  return (
    <>
      <AddClientTab
        clientList={clientList}
        disabledDate={disabledDate}
        setValues={setValues}
        values={values}
        eventData={eventData}
        eventDate={eventDate}
        isClientLoading={isClientLoading}
        onFocus={handleClientChange}
      />

      {/* TODO remove requests onFocus & clear onBlur */}
      <CustomSearch
        label={t('MANAGER.BUTTON.ADD_SERVICE')}
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_SERVICE')}
        options={localServiceList?.map((item) => {
          const hour = DateHelper.toFormat(
            new Date(`${STANDART_DATE} ${item.duration}`),
            'H',
          );
          const minute = item.duration.split(':')?.[1];
          const subtitle = `${hour !== '0' && hour ? `${hour}h ` : ''}${
            minute ? `${minute}m` : ''
          }`;

          return {
            value: item.id,
            label: item.name,
            subtitle,
            inFrontOf: `$${item.price.price}`,
          };
        })}
        onSelect={(value, obj) => {
          setValues({
            ...values,
            service_id: Number(value) || null,
            serviceName: obj.label,
          });
        }}
        onBlur={() => setLocalServiceList([])}
        onFocus={handleServiceChange}
        select={
          values.service_id
            ? {
                value: values.service_id,
                label: values.serviceName as string,
                subtitle: values.serviceDuration as string,
                inFrontOf: `$${values.price}`,
              }
            : undefined
        }
        isLoading={isServiceLoading}
      />

      <div className={styles.timepick}>
        <UISelect
          placeholder={t('COMMON.SELECT')}
          label={t('COMMON.START_TIME')}
          value={
            values.started_at ||
            (selectDate?.start &&
              DateHelper.toFormat(
                DateHelper.addMinutes(selectDate?.start, 10),
                FORMAT_TIME_HOUR_WITH_MIN,
              ))
          }
          onFocus={handleFocusTime}
          isLoading={isLoading}
          options={availableTime.map((item, index) => ({
            value: index,
            label: item.slice(0, 5),
          }))}
          onChange={(_, obj) => {
            setValues({
              ...values,
              started_at: (obj as SelectOption).label,
              time: {
                ...values.time,
                from: (obj as SelectOption).label,
                to: '',
              },
              finished_at: '',
            });
            setIsStartTimeEdit(true);
          }}
          error={
            (errors as { [key: string]: string })
              ?.started_at as unknown as string
          }
        />

        <span className={styles.dash}></span>

        <UISelect
          placeholder={t('COMMON.SELECT')}
          label={t('COMMON.END_TIME')}
          {...(values.service_id
            ? {
                value: endTime,
              }
            : {
                value: isStartTimeEdit
                  ? ''
                  : selectDate?.end &&
                    DateHelper.toFormat(
                      selectDate?.end,
                      FORMAT_TIME_HOUR_WITH_MIN,
                    ),
              })}
          disabled
        />
      </div>

      <UISelect
        placeholder={t('MANAGER.APPOINTMENT.CHOOSE_MASTER')}
        label={t('MANAGER.BUTTON.ADD_MASTER')}
        showSearch
        className={styles.select}
        value={values.staffName || undefined}
        onSearch={(e) => handleStaffChange(e)}
        onFocus={(e) => {
          handleStaffChange(
            (e as unknown as ChangeEvent<HTMLInputElement>).target.value,
          );
        }}
        options={localStaffList?.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onChange={(value, obj) => {
          setValues({
            ...values,
            staff_id: value,
            staffName: (obj as SelectOption).label,
          });
        }}
        onBlur={() => setlocalStaffList([])}
        isLoading={isStaffLoading}
        filterOption={(inputValue: string, option: SelectOptionType) =>
          option?.label
            ?.toLowerCase()
            ?.includes(inputValue?.toLowerCase()) as boolean
        }
      />

      <UIAppointmendarSidebarFooter
        total={
          values?.price ||
          serviceList?.find((item) => item.id === values.service_id)?.price
            .price
        }
        handleSave={handleSave}
        handleDelete={handleDelete}
        isAddAppointment={isAddAppointment}
        isBtnDisabled={isBtnDisabled}
      />
    </>
  );
};

export default memo(UIAppointmendarSidebarContent);

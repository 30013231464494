import { SidebarType } from 'entities/admin'
import { MenuNotificationName } from 'modules/manager/constants/sidebar'
import { IManagerMenuType } from 'store/Manager/Notifications/Notifications.type'

export default (() => {
  const filterRepeat = (arr: number[]) =>
    arr.filter(
      (item: number, index: number) => arr.indexOf(item as never) === index,
    )

  function setKeyFromArray<T>(
    data: { [key: string]: any }[],
    payload: number,
    key: string,
    value?: any,
  ): T {
    const newData = [...data]
    const index = newData.findIndex((item) => item?.id === payload)

    if (index !== -1) {
      newData[index][key] = value || !newData[index]?.[key]
    }

    return newData as unknown as T
  }

  function setAllKeysFromArray<T extends { id?: string | number }>(
    data: T[],
    payload: number[],
    key: string,
    value: any,
  ): T[] {
    const newData: T[] = [...data]

    payload.forEach((itemId) => {
      const index: number = newData.findIndex(
        (item) => Number(item?.id) === Number(itemId),
      )

      if (index !== -1) {
        newData[index] = { ...newData[index], [key]: value }
      }
    })

    return newData
  }

  const getSelectOption = <T extends { id: number; name: string }>(
    array?: T[],
  ) => {
    return (array || []).map((i) => ({
      value: i.id,
      label: i.name,
    }))
  }

  const getNotification = (
    arraySidebar: SidebarType[],
    notification: IManagerMenuType,
  ) => {
    const arrKeyNotification = Object.keys(notification)

    return arraySidebar?.map((itemMenu) => {
      if (
        itemMenu.notificationName &&
        arrKeyNotification.find((key) => key === itemMenu.notificationName)
      ) {
        const notificationCount = itemMenu?.notificationCount

        return {
          ...itemMenu,
          notification: notification[itemMenu.notificationName],
          ...(itemMenu.notificationName === MenuNotificationName.FEEDBACKS &&
            notificationCount && {
              notificationCount: notification[
                itemMenu.notificationName
              ] as unknown as number,
            }),
        }
      }

      return itemMenu
    })
  }

  return {
    filterRepeat,
    setKeyFromArray,
    setAllKeysFromArray,
    getSelectOption,
    getNotification,
  }
})()

import { FC, useState, SyntheticEvent, useEffect, memo } from 'react'

import { Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import TextBlock from 'modules/admin/Faq/TextBlock'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UITextArea } from 'ui'
import UITextInput from 'ui/TextInput'
import { SEO_INPUTS } from 'utils'
import { ContactUsBody, TemplateBodyType } from 'views/admin/Pages/index.type'

import style from './style.module.scss'
import { INITAL_SEO_STATE } from '../CustomerTemplate/index.type'
import { addPrefixSeo } from 'utils/helpers/adminTemplate'

type PropsType = {
  onSave: (body: TemplateBodyType) => void
}

const INITIAL_STATE: ContactUsBody = {
  title: '',
  description: '',
  ...INITAL_SEO_STATE,
}

const ContactUsTemplate: FC<PropsType> = ({ onSave }) => {
  const { t } = useTranslation()
  const { currentPage, isLoading } = useSelector(
    (store: IRootState) => store.adminPages,
  )
  const [state, setState] = useState<ContactUsBody>(
    (currentPage?.content as ContactUsBody) || INITIAL_STATE,
  )

  const handleChange = (event: SyntheticEvent) => {
    const { name, value } = event.target as HTMLInputElement

    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSave = () => {
    onSave(state)
  }

  useEffect(() => {
    if (currentPage?.content) {
      setState({
        ...currentPage?.content,
        ...addPrefixSeo(currentPage?.seo || {}),
      } as ContactUsBody)
    }
  }, [currentPage])

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical" className={style.contactUsTemplate}>
        <UITextInput
          text={t('COMMON.TITLE_H1')}
          value={state?.title}
          name={'title'}
          onChange={handleChange}
          placeholder={t('COMMON.TITLE')}
          className={style.input}
        />

        <Form.Item label={t('COMMON.DESCRIPTION')}>
          <UITextArea
            value={state?.description}
            name={'description'}
            onChange={handleChange}
            placeholder={t('COMMON.DESCRIPTION')}
            className={style.textarea}
            rows={6}
          />
        </Form.Item>

        <div className={'seo-block'}>
          {SEO_INPUTS.map(({ name, title: seoTitle }, index) => (
            <TextBlock
              key={index}
              rows={7}
              name={name}
              title={t(seoTitle)}
              value={state[name]}
              onChange={handleChange}
            />
          ))}
        </div>

        <UIButton label={t('COMMON.SAVE')} handler={handleSave} />
      </Form>
    </Spin>
  )
}

export default memo(ContactUsTemplate)

import { FC, useMemo, useEffect } from 'react'

import { Form, Input, Radio, Spin } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowIcon } from 'assets/images/common/arrowLeft.svg'
import { ReactComponent as LockIcon } from 'assets/images/common/redesign/lock-outlined.svg'
import { ReactComponent as UnlockIcon } from 'assets/images/common/redesign/unlock-outlined.svg'
import {
  clearCustomerError,
  getAdminCurrentCustomerRequest,
  setAdminCurrentCustomerRequest,
} from 'store/Admin/Customer/Customer.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UIPhoneInput } from 'ui'
import { ADMIN_CUSTOMERS } from 'utils'

import style from './style.module.scss'

const ViewAdminCustomerProfile: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { currentCustomer, isLoading, errorMessage, isLockLoading } =
    useSelector((store: IRootState) => store.adminCustomer)
  const phoneNumber = currentCustomer?.profile?.phone
    ? currentCustomer?.profile?.phone.slice(3)
    : ''
  const id = useMemo(() => {
    const splittedPath = location.pathname.split('/')

    return Number(splittedPath[splittedPath.length - 1])
  }, [location.pathname])

  const handleLockClick = () => {
    dispatch(
      setAdminCurrentCustomerRequest(
        id,
        Number(!currentCustomer?.active) as 0 | 1,
      ),
    )
  }

  const handleBackClick = () => {
    navigate(ADMIN_CUSTOMERS)
  }

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }

    return () => {
      dispatch(clearCustomerError())
    }
  }, [dispatch, errorMessage])

  useEffect(() => {
    dispatch(getAdminCurrentCustomerRequest(id))
  }, [location.pathname, dispatch, id])

  return (
    <Content className={style.adminCustomerProfilePage}>
      <section className={style.header}>
        <ArrowIcon className={style.back} onClick={handleBackClick} />

        <h1>{t('ADMIN.PROFILE_CLIENT.TITLE')}</h1>

        <UIButton
          className={style.btnLock}
          handler={handleLockClick}
          label={t(!currentCustomer?.active ? 'COMMON.UNLOCK' : 'COMMON.LOCK')}
          icon={!currentCustomer?.active ? <UnlockIcon /> : <LockIcon />}
          disabled={isLockLoading}
          type="text"
          isGreen
        />
      </section>

      <Spin spinning={isLoading}>
        <section className={style.info}>
          <Form disabled={true} layout={'vertical'}>
            <Form.Item label={t('COMMON.FULL_NAME')}>
              <Input value={currentCustomer?.name} />
            </Form.Item>

            <Form.Item label={t('COMMON.YOUR_ARE')}>
              <Radio.Group value={'female'}>
                <Radio value="male">{t('COMMON.MALE')}</Radio>
                <Radio value="female">{t('COMMON.FEMALE')}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={t('COMMON.BIRTHDAY')}>
              <Input value={'16-12-1998'} />
            </Form.Item>

            <Form.Item label={t('COMMON.EMAIL')}>
              <Input value={currentCustomer?.email} />
            </Form.Item>

            <Form.Item label={t('COMMON.PHONE_NUMBER')}>
              <UIPhoneInput
                onChange={() => {}}
                disabled={true}
                value={phoneNumber || ''}
              />
            </Form.Item>
          </Form>
        </section>
      </Spin>
    </Content>
  )
}

export default ViewAdminCustomerProfile

import { FC, useEffect, useState, memo } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { UISwitch, UITimePicker } from 'ui'
import { FORMAT_TIME_SHORT, STANDART_DATE } from 'utils'
import { DateHelper, WeekTimeHelper } from 'utils/helpers'

import { PropsType } from './index.type'
import styles from './style.module.scss'
import { DayTextType, DayTime } from '../index.type'

const WidgetModalStaffEditHourRow: FC<PropsType> = ({
  text,
  formName,
  values,
  setValues,
  setIsDisable,
}) => {
  const { t } = useTranslation()
  const [isBreak, setIsBreak] = useState(false)
  const [isWeek, setIsWeek] = useState(true)

  const weekDay = values?.schedule[formName]

  const timeStart = (weekDay as DayTime)?.work?.from

  const timeEnd = (weekDay as DayTime)?.work?.to

  const breakStart = (weekDay as DayTime)?.break?.from

  const breakEnd = (weekDay as DayTime)?.break?.to

  const onChange = (time: Date | null, name: 'work' | 'break', key: string) => {
    if (time) {
      setValues((prev) => ({
        ...prev,
        schedule: {
          ...prev.schedule,
          [formName]: {
            ...prev?.schedule[formName],
            [name]: {
              ...(prev?.schedule[formName] as DayTime)?.[name],
              [key]: DateHelper.toFormat(time, FORMAT_TIME_SHORT),
            },
          },
        },
      }))
    }
  }

  const onChangeSwitch = (name: 'work' | 'break') => {
    if (name === 'work') {
      setIsWeek(!isWeek)
      setIsBreak(false)
      setValues((prev) => ({
        ...prev,
        schedule: {
          ...prev.schedule,
          [formName]: {},
        },
      }))
    } else if (name === 'break' && timeStart && timeEnd) {
      setIsBreak(!isBreak)
      setValues((prev) => ({
        ...prev,
        schedule: {
          ...prev.schedule,
          [formName]: {
            work: (prev?.schedule[formName] as DayTime)?.work,
          },
        },
      }))
    }
  }

  useEffect(() => {
    setIsDisable(false)

    Object.keys(values.schedule).forEach((item) => {
      const startT = (values.schedule[item as DayTextType] as DayTime)?.work
        ?.from
      const endT = (values.schedule[item as DayTextType] as DayTime)?.work?.to
      const startB = (values.schedule[item as DayTextType] as DayTime)?.break
        ?.from
      const endB = (values.schedule[item as DayTextType] as DayTime)?.break?.to

      if (
        (startT && startB && startT >= startB) ||
        (startB && endB && startB >= endB)
      ) {
        setIsDisable(true)
      } else if (startT && endT && startT >= endT) {
        setIsDisable(true)
      } else if ((startT && !endT) || (endT && !startT)) {
        setIsDisable(true)
      } else if ((startB && !endB) || (endB && !startB)) {
        setIsDisable(true)
      } else if (startB && startT && startB <= startT) {
        setIsDisable(true)
      } else if (endB && endT && endB >= endT) {
        setIsDisable(true)
      }
    })
  }, [setIsDisable, values.schedule])

  useEffect(() => {
    if (breakStart && breakEnd) {
      setIsBreak(true)
    } else if (!breakStart && !breakEnd) {
      setIsBreak(false)
    }
  }, [breakEnd, breakStart])

  useEffect(() => {
    if (timeStart && timeEnd) {
      setIsWeek(true)
    }
  }, [timeEnd, timeStart])

  return (
    <div className={styles.wrapper}>
      <UISwitch
        checked={!!(timeStart || timeEnd)}
        onChange={() => onChangeSwitch('work')}
        disabled={!(timeStart || timeEnd)}
      />
      <p className={styles.text}>{text}</p>
      <UITimePicker
        placeholder={t('COMMON.SELECT')}
        format="HH:mm"
        value={
          (timeStart as unknown as Date) &&
          new Date(`${STANDART_DATE} ${timeStart}`)
        }
        onChange={(e: Date | null) => onChange(e, 'work', 'from')}
        disabledTime={() =>
          WeekTimeHelper.onDisabledTime(
            WeekTimeHelper.disabledTimeStart(timeEnd),
            WeekTimeHelper.disabledTimeStart(timeEnd, timeStart, true),
          )
        }
        {...(timeStart >= timeEnd && { status: 'error' })}
      />
      <span className={styles.dash}></span>
      <UITimePicker
        placeholder={t('COMMON.SELECT')}
        format="HH:mm"
        clearIcon={false}
        value={
          (timeEnd as unknown as Date) &&
          new Date(`${STANDART_DATE} ${timeEnd}`)
        }
        showNow={false}
        inputReadOnly={true}
        onChange={(e: Date | null) => onChange(e, 'work', 'to')}
        disabledTime={() =>
          WeekTimeHelper.onDisabledTime(
            WeekTimeHelper.disabledTimeEnd(timeStart),
            WeekTimeHelper.disabledTimeEnd(timeStart, timeEnd, true),
          )
        }
        {...(timeStart >= timeEnd && { status: 'error' })}
      />
      <UISwitch
        checked={!!breakStart || !!breakEnd || isBreak}
        onChange={() => onChangeSwitch('break')}
        disabled={!(timeStart && timeEnd)}
      />
      <p className={cn(styles.text, styles.breakText)}>
        {t('MANAGER.PROFILE_SALON.ADD_BREAK')}
      </p>
      {isBreak && (
        <UITimePicker
          placeholder={t('COMMON.SELECT')}
          format="HH:mm"
          value={
            (breakStart as unknown as Date) &&
            new Date(`${STANDART_DATE} ${breakStart}`)
          }
          onChange={(e: Date | null) => onChange(e, 'break', 'from')}
          disabledTime={() =>
            WeekTimeHelper.onDisabledTime(
              WeekTimeHelper.disabledBreakStart(
                timeStart,
                timeEnd,
                breakEnd,
                breakStart,
              ),
              WeekTimeHelper.disabledBreakStart(
                timeStart,
                timeEnd,
                breakEnd,
                breakStart,
                true,
              ),
            )
          }
          disabled={!(timeStart && timeEnd)}
          {...(timeStart >= breakStart && { status: 'error' })}
        />
      )}
      {isBreak && <span className={styles.dash}></span>}
      {isBreak && (
        <UITimePicker
          placeholder={t('COMMON.SELECT')}
          format="HH:mm"
          value={
            (breakEnd as unknown as Date) &&
            new Date(`${STANDART_DATE} ${breakEnd}`)
          }
          onChange={(e: Date | null) => onChange(e, 'break', 'to')}
          disabledTime={() =>
            WeekTimeHelper.onDisabledTime(
              WeekTimeHelper.disabledBreakEnd(
                timeStart,
                timeEnd,
                breakStart,
                breakEnd,
              ),
              WeekTimeHelper.disabledBreakEnd(
                timeStart,
                timeEnd,
                breakStart,
                breakEnd,
                true,
              ),
            )
          }
          disabled={!(timeStart && timeEnd)}
          {...((breakStart >= breakEnd || breakEnd >= timeEnd) && {
            status: 'error',
          })}
        />
      )}
    </div>
  )
}

export default memo(WidgetModalStaffEditHourRow)

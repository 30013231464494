import { FC, useState, SyntheticEvent, useEffect, memo } from 'react'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Content } from 'antd/lib/layout/layout'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as RemoveIcon } from 'assets/images/common/redesign/delete.svg'
import { ReactComponent as PlusIcon } from 'assets/images/common/redesign/plusCircle.svg'
import {
  clearAdminFaqErrors,
  deleteAdminFaqRequest,
  getAdminFaqRequest,
} from 'store/Admin/Faq/Faq.action'
import { addAlert } from 'store/Alert'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICheckbox, UICustomTable } from 'ui'
import {
  ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE,
  ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE,
} from 'utils'
import { getFromQueryString } from 'utils/helpers/query'

import style from './style.module.scss'

type PropTypes = { isManager: boolean }

const ViewAdminFaq: FC<PropTypes> = ({ isManager }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {
    data,
    errorMessage,
    isLoading,
    isSaved,
    meta: { perPage, total },
  } = useSelector((store: IRootState) => store.adminFaq)
  const [selected, setSelected] = useState<number[]>([])

  const columns = [
    { title: '' },
    { title: t('COMMON.ID') },
    { title: t('COMMON.QUESTION') },
    { title: t('COMMON.ANSWER') },
  ]

  const handleRequest = () => {
    const { page } = getFromQueryString(location.search)

    dispatch(
      getAdminFaqRequest(
        isManager ? 'business' : 'customer',
        Number(page) || 1,
      ),
    )
  }

  const handleChecked = (id: number) => (event: CheckboxChangeEvent) => {
    const { checked } = event.target

    if (checked) setSelected((prevState) => [...prevState, id])
    else setSelected((prevState) => prevState.filter((item) => item !== id))
  }

  const handleMove = (id: number | null) => () => {
    navigate(
      (isManager
        ? ADMIN_HELP_FOR_BUSINESS_ANSWER_PAGE
        : ADMIN_HELP_FOR_CUSTOMER_ANSWER_PAGE
      ).replace(':id', String(id)),
    )
  }

  const handleDelete = () => {
    dispatch(deleteAdminFaqRequest(selected))
  }

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      )
    }
  }, [dispatch, errorMessage])

  useEffect(() => {
    return () => {
      dispatch(clearAdminFaqErrors())
    }
  }, [dispatch])

  useEffect(() => {
    handleRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isManager])

  useEffect(() => {
    if (isSaved) {
      dispatch(
        addAlert({
          message: t('COMMON.SUCCESSFULLY_DELETE'),
          type: 'success',
        }),
      )

      handleRequest()
      setSelected([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved])

  return (
    <Content className={style.helpForBusiness}>
      <div className={style.titleWrapper}>
        <h1>
          {isManager
            ? t('ADMIN.HELP_FOR_BUSINESS.TITLE')
            : t('ADMIN.HELP_FOR_CUSTOMERS.TITLE')}
        </h1>

        <div className={style.titleActions}>
          <UIButton
            handler={handleDelete}
            label={
              <>
                {t('COMMON.DELETE')}{' '}
                {!!selected.length && `(${selected.length})`}
              </>
            }
            icon={<RemoveIcon />}
            type="text"
            isRed
          />

          <UIButton
            label={t('COMMON.ADD_NEW')}
            handler={handleMove(null)}
            icon={<PlusIcon />}
            type="text"
          />
        </div>
      </div>

      <UICustomTable
        columns={columns}
        className={style.table}
        isLoading={isLoading}
        perPage={perPage}
        total={total}
      >
        {data.map(({ answer, id, question }) => (
          <tr key={id} onClick={handleMove(id)}>
            <td
              onClick={(event: SyntheticEvent) => {
                event.stopPropagation()
              }}
            >
              <UICheckbox
                checked={!!selected?.find((item) => item === id)}
                onChange={handleChecked(id)}
              >
                {null}
              </UICheckbox>
            </td>
            <td>{id}</td>
            <td>{answer}</td>
            <td>{question}</td>
          </tr>
        ))}
      </UICustomTable>
    </Content>
  )
}

export default memo(ViewAdminFaq)

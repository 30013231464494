import { FC, memo, SyntheticEvent } from 'react'

import { UISwitch } from 'ui'
import UITextInput from 'ui/TextInput'

import style from './style.module.scss'

type PropsType = {
  description: string
  isChecked: boolean
  onChange: (event: SyntheticEvent) => void
  onChecked: (checked: boolean) => void
  title: string
  value: string
}

const AdminSettingsInfoBlock: FC<PropsType> = ({
  onChange,
  value,
  description,
  title,
  isChecked,
  onChecked,
}) => (
  <div className={style.infoBlock}>
    <div className={style.switchWrapper}>
      <UISwitch checked={isChecked} onChange={onChecked} />
      <h2>{title}</h2>
    </div>

    <p>{description}</p>

    <div className="d-flex align-items-center">
      <UITextInput
        value={value}
        onChange={onChange}
        className={style.input}
        disabled={!isChecked}
        type={'number'}
      />

      <p className={style.infoText}>{'day'}</p>
    </div>
  </div>
)

export default memo(AdminSettingsInfoBlock)

import { Dispatch, FC, memo, SetStateAction } from 'react';
import CustomSearch from 'ui/CustomSearch';
import { UIDatePicker } from 'ui';
import { FORMAT_DATE_WEEK_DAY_MONTH } from 'utils';
import styles from '../style.module.scss';
import { ReactComponent as ArrowSVG } from 'assets/images/common/redesign/arrowBottom.svg';
import { useTranslation } from 'react-i18next';
import { ValuesType } from '../index.type';
import { EventClickArg } from '@fullcalendar/react';
import { useDispatch } from 'react-redux';
import { clearStore } from 'store/Manager/Appointments/Appointments.action';
import { SearchClientType } from 'store/Manager/Appointments/Appointments.type';

type PropTypes = {
  clientList: SearchClientType[];
  disabledDate: (date: Date) => boolean;
  eventData?: EventClickArg | null;
  eventDate?: Date;
  isClientLoading?: boolean;
  onFocus?: (value: string) => void;
  setValues: Dispatch<SetStateAction<ValuesType>>;
  values: ValuesType;
};

const AddClientTab: FC<PropTypes> = ({
  eventData,
  eventDate,
  setValues,
  values,
  disabledDate,
  clientList,
  isClientLoading,
  onFocus,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.wrappPicker}>
        <UIDatePicker
          format={FORMAT_DATE_WEEK_DAY_MONTH}
          value={
            (values.from && new Date(values.from)) ||
            eventData?.event?.start ||
            eventDate ||
            new Date()
          }
          onChange={(date) => {
            setValues((prevState) => ({ ...prevState, from: date }));
            dispatch(clearStore());
          }}
          className={styles.datePicker}
          disabledDate={disabledDate}
        />
        <ArrowSVG />
      </div>

      {/* TODO remove requests onFocus & clear onBlur */}
      <CustomSearch
        label={t('MANAGER.STAFF.CLIENT_NAME')}
        placeholder={t('MANAGER.STAFF.FULL_NAME_PLACEHOLDER')}
        options={clientList?.map((item) => ({
          value: item.id,
          label: item.name,
          subtitle: item.phone,
          type: item.type,
        }))}
        onSelect={(value, obj) => {
          setValues({
            ...values,
            client_id: Number(value) || null,
            clientName: obj.label,
            clientType: (obj as unknown as { type: string }).type,
          });
        }}
        onFocus={onFocus}
        select={
          values.client_id
            ? {
                label: values.clientName
                  ? values.clientName || ''
                  : values.contact_name || '',
                value: values.client_id,
                subtitle: values.contact_phone ? values.contact_phone : '',
                type: values.clientType ? values.clientType : '',
              }
            : undefined
        }
        isLoading={isClientLoading}
      />
    </>
  );
};

export default memo(AddClientTab);

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ModalType } from 'entities/modal';
import LayoutManagerSidebar from 'layouts/Manager/Sidebar';
import ManagerSupport from 'modules/manager/support';
import { addAlert } from 'store/Alert';
import { clearSupportManagerErrors } from 'store/Manager/Support/Support.action';
import { showModal } from 'store/Modal';
import { IRootState } from 'store/Root.reducer';

const ViewManagerSupport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorMessage, isLoading, isSended, errors } = useSelector(
    (store: IRootState) => store.managerSupport,
  );

  useEffect(() => {
    if (isSended) {
      dispatch(
        showModal({
          modalType: ModalType.NOTIFY,
          modalProps: { notifyTitle: t('MANAGER.SUPPORT.MODAL_NOTIFY') },
        }),
      );
    }

    return () => {
      dispatch(clearSupportManagerErrors());
    };
  }, [dispatch, isSended, t]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
        }),
      );
    }
  }, [dispatch, errorMessage]);

  useEffect(() => {
    return () => {
      dispatch(clearSupportManagerErrors());
    };
  }, [dispatch]);

  return (
    <LayoutManagerSidebar
      titleHeader={t('MANAGER.SUPPORT.SUPPORT')}
      className={'bg-white p-0'}
      isMobileContentVisible
    >
      <ManagerSupport
        isLoading={isLoading}
        isSended={isSended}
        errors={errors}
      />
    </LayoutManagerSidebar>
  );
};

export default ViewManagerSupport;

import { ChangeEvent, FC, useMemo, memo } from 'react'

import { useTranslation } from 'react-i18next'

import { UICustomCard, UITooltip } from 'ui'
import UITextInput from 'ui/TextInput'
import TooltipUIPassword from 'ui/Tooltip/ui/Password'

import { PropsType } from './index.type'
import styles from './style.module.scss'

const ProfileSalonPassword: FC<PropsType> = ({ values, setValues, errors }) => {
  const { t } = useTranslation()

  const handlerChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string,
  ) => {
    const { value } = e.target

    setValues((prevState) => ({
      ...prevState,
      [type]: value,
    }))
  }

  const inputs = useMemo(
    () => [
      { title: t('MANAGER.CURRENT_PASSWORD'), key: 'password_old' },
      { title: t('MANAGER.NEW_PASSWORD'), key: 'password' },
      { title: t('MANAGER.CONFIRM_PASSWORD'), key: 'password_confirmation' },
    ],
    [t],
  )

  return (
    <UICustomCard
      title={
        <>
          <span>6. </span> {t('MANAGER.PASSWORD_CHANGE')}
        </>
      }
    >
      <div className={styles.passwordWrapp}>
        {inputs.map(({ key, title }, index) => (
          <UITextInput
            key={index}
            text={title}
            isShowPassword
            type="password"
            placeholder={title}
            error={errors?.[key]}
            onChange={(e) => handlerChange(e, key)}
            value={values[key as keyof typeof values] as string}
            iconLabel={<UITooltip content={<TooltipUIPassword />} />}
          />
        ))}
      </div>
    </UICustomCard>
  )
}

export default memo(ProfileSalonPassword)

import { EventSourceInput } from '@fullcalendar/react';

import { TimeFromTo } from 'entities/common';

// eslint-disable-next-line import/no-cycle
import { ServiceType } from '../service';
import { StaffType } from '../staff';

export type ValidationErrorType = {
  client: { avatar: number | null; id: number; name: string };
  contact: { name: string; phone: string };
  finished_at: string;
  from: string;
  id: number;
  service: Pick<
    ServiceType,
    'description' | 'duration' | 'id' | 'name' | 'price'
  >;
  staff: StaffType;
  started_at: string;
};

export const createNotActiveEvent = (schedule: {
  [key: string]: TimeFromTo;
}) => {
  const event: EventSourceInput = [];

  Object.keys(schedule)?.forEach((item, index) => {
    if (!schedule[item]) {
      event.push({
        id: `${index}item`,
        title: 'none',
        start: `${item}T00:00:00`,
        end: `${item}T24:00:00`,
        className: 'notActive monthNotActive',
      });
    } else {
      event.push(
        {
          id: `${index}item`,
          title: 'none',
          start: `${item}T00:00:00`,
          end: `${item}T${schedule[item]?.from}`,
          className: 'notActive',
        },
        {
          id: `${index}item1`,
          title: 'none',
          start: `${item}T${schedule[item]?.to}`,
          end: `${item}T23:59:59`,
          className: 'notActive',
        },
      );
    }
  });

  return event;
};

export const getDisableDate = (schedule: { [key: string]: TimeFromTo }) => {
  const arrDate: Date[] = [];

  Object.keys(schedule)?.forEach((item) => {
    if (schedule[item]?.from === null && schedule[item]?.to === null) {
      arrDate.push(new Date(new Date(item).toDateString()));
    }
  });

  return arrDate;
};

export const createEvent = (appointmens: ValidationErrorType[]) => {
  return appointmens?.map((item) => {
    return {
      id: `${item?.id}`,
      title: item?.client?.name,
      start: `${item?.from}T${item?.started_at}`,
      end: `${item?.from}T${item?.finished_at}`,
      backgroundColor: item?.staff?.color,

      extendedProps: {
        client: item.client,
        contact: item.contact,
        contactName: item.contact?.name,
        contactPhone: item.contact?.phone,
        title: item?.staff?.name,
        description: item?.service?.name,
        service: { label: item?.service?.name, value: item?.service?.id },
        staff: { label: item?.staff?.name, value: item?.staff?.id },
        startTime: item?.started_at.slice(0, 5),
        endTime: item?.finished_at.slice(0, 5),
        price: item?.service?.price,
        clientName: item?.client?.name,
        client_id: item?.client?.id,
        serviceDuration: item?.service?.duration,
      },
    };
  });
};

export type AppointmentType = ValidationErrorType;

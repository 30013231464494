import { FC, memo, useEffect, useMemo, useState } from 'react'

import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as ArrowRigth } from 'assets/images/common/redesign/arrowLong.svg'
import { copyShiftRequest } from 'store/Manager/Shift/Shift.action'
import { getStaffRequest } from 'store/Manager/Staff'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICalendar, UICustomModal, UIDatePicker } from 'ui'
import { FORMAT_DATE } from 'utils'
import { DateHelper } from 'utils/helpers'

import WidgetModalCopyShiftCheckbox from './Checkbox'
import WidgetModalCopyShiftCheckboxTime from './CheckboxTime'
import { INITIAL_VALUES } from './constant'
import { DateType, PropsType, ValuesCopyType } from './index.type'
import styles from './style.module.scss'

const WidgetModalCopyShift: FC<PropsType> = () => {
  const { staffs } = useSelector((state: IRootState) => state.staff)
  const { isLoading } = useSelector((state: IRootState) => state.shift)
  const { isRepeatRequest } = useSelector(
    (state: IRootState) => state.appointments,
  )

  const [selectDate, setSelectDate] = useState('day')
  const [values, setValues] = useState<ValuesCopyType>(INITIAL_VALUES)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const startWeekDate = values.copy.length
    ? Math.min(...values.copy.map((i) => +i.valueOf()))
    : 0

  const endWeekDate = values.copy.length
    ? Math.max(...values.copy.map((i) => +i.valueOf()))
    : 0

  const isWeek = selectDate === 'week'

  const objToRequest = useMemo(
    () => ({
      staff_ids: values.staff_ids,
      ...(values.from && {
        from: DateHelper.toFormat(values.from as Date, FORMAT_DATE),
      }),
      ...(values.to && {
        to: DateHelper.toFormat(values.to as Date, FORMAT_DATE),
      }),
      ...(startWeekDate !== 0 && {
        copy_from: isWeek
          ? DateHelper.toFormat(
              DateHelper.startOfWeek(startWeekDate),
              FORMAT_DATE,
            )
          : DateHelper.toFormat(startWeekDate, FORMAT_DATE),
      }),
      ...(endWeekDate !== 0 && {
        copy_to: isWeek
          ? DateHelper.toFormat(DateHelper.endOfWeek(endWeekDate), FORMAT_DATE)
          : DateHelper.toFormat(endWeekDate - 86400000, FORMAT_DATE),
      }),
    }),
    [
      endWeekDate,
      isWeek,
      startWeekDate,
      values.from,
      values.staff_ids,
      values.to,
    ],
  )

  const handleCancel = () => {
    dispatch(hideModal())
  }

  const handleSave = () => {
    dispatch(copyShiftRequest(objToRequest))
  }

  const handleDateChange = (date: Date | null) => {
    setValues({
      ...values,
      from: isWeek ? DateHelper.startOfWeek(date as Date) : date,
      to: isWeek ? DateHelper.endOfWeek(date as Date) : date,
    })
  }

  useEffect(() => {
    if (isRepeatRequest) {
      dispatch(copyShiftRequest(objToRequest))
    }
  }, [dispatch, isRepeatRequest, objToRequest])

  useEffect(() => {
    dispatch(getStaffRequest())
  }, [dispatch])

  useEffect(() => {
    if (selectDate === 'week') {
      setValues({
        ...values,
        copy: [],
        from: DateHelper.toFormat(
          DateHelper.startOfWeek(new Date()),
          FORMAT_DATE,
        ),
        to: DateHelper.toFormat(DateHelper.endOfWeek(new Date()), FORMAT_DATE),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate])

  const isDisabled = !(
    values.from &&
    values.to &&
    values.staff_ids.length &&
    values.copy.length
  )

  return (
    <UICustomModal title={t('MANAGER.STAFF.COPY_SHIFTS')}>
      <Spin spinning={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.leftContent}>
            <div className={styles.wrappTab}>
              <p>{t('MANAGER.STAFF.USE_SHIFTS')}</p>
              <div>
                <UIButton
                  label={t('COMMON.DAY')}
                  handler={() => setSelectDate('day')}
                  type={selectDate === 'day' ? 'primary' : 'outline'}
                />
                <UIButton
                  label={t('COMMON.WEEK')}
                  type={selectDate === 'week' ? 'primary' : 'outline'}
                  handler={() => setSelectDate('week')}
                />
              </div>
            </div>
            <div className={styles.picker}>
              <UIDatePicker
                popupClassName={selectDate === 'week' ? 'calendarWeek' : ''}
                // eslint-disable-next-line no-void
                picker={selectDate === 'week' ? 'week' : void 0}
                value={new Date(values.from as Date)}
                onChange={handleDateChange}
                label={t('MANAGER.STAFF.DATE')}
              />
            </div>
          </div>

          <div className={styles.centerContent}>
            <span>
              <ArrowRigth />
            </span>
          </div>

          <div className={styles.rigthContent}>
            {selectDate === 'day' ? (
              <UICalendar
                setDate={(time) => {
                  setValues({
                    ...values,
                    copy: [(time as DateType)?.start, (time as DateType)?.end],
                  })
                }}
                selectAllow={(selectInfo) =>
                  selectInfo.valueOf() >= new Date().valueOf()
                }
                className={styles.calendar}
                isDragging
              />
            ) : (
              <WidgetModalCopyShiftCheckboxTime
                values={values}
                setValues={setValues}
              />
            )}
          </div>
        </div>
        <div className={styles.line}></div>
        <WidgetModalCopyShiftCheckbox
          staffs={staffs}
          setValues={setValues}
          values={values}
        />
        <div className={styles.line}></div>
        <div className={styles.btn}>
          <UIButton
            handler={handleCancel}
            type="outline"
            label={t('ADMIN.BUTTON.CANCEL')}
          />
          <UIButton
            handler={handleSave}
            disabled={isDisabled}
            label={t('ADMIN.BUTTON.SAVE')}
          />
        </div>
      </Spin>
    </UICustomModal>
  )
}

export default memo(WidgetModalCopyShift)

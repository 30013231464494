import { call, fork, ForkEffect, put, takeLatest } from 'redux-saga/effects'

import { getOrderHistoryApi } from 'services/http/manager/OrderHistory'
import { getRequestErrors } from 'utils/helpers/requests'

import { getOrderHistorySuccess, getOrderHistoryFailure } from './Orders.action'
import { GET_ORDER_HISTORY_REQUEST } from './Orders.constant'
import { IGetOrderHistoryRequest, IOrderHistoryResponse } from './Orders.type'

function* workerGetOrderHistory({ payload }: IGetOrderHistoryRequest) {
  try {
    const response: { data: IOrderHistoryResponse } = yield call(
      getOrderHistoryApi,
      payload,
    )

    yield put(getOrderHistorySuccess(response.data))
  } catch (error) {
    yield getRequestErrors(getOrderHistoryFailure, error as string)
  }
}

function* watchGetOrderHistory() {
  yield takeLatest(GET_ORDER_HISTORY_REQUEST, workerGetOrderHistory)
}

export const orderHistoryWatchers: ForkEffect[] = [fork(watchGetOrderHistory)]

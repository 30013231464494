import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import locale from 'antd/es/locale/es_ES';
import es from 'antd/es/date-picker/locale/es_ES';

import initStore from 'store';

import App from './App';
import './lib/i18n/i18n';

import 'antd/dist/antd.css';
import './assets/scss/index.scss';
import { ConfigProvider } from 'antd';

es.lang.locale = 'es';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store={initStore}>
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>,
);

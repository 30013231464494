import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/delete.svg';
import { UIButton, UITimePicker } from 'ui';
import { STANDART_DATE } from 'utils';
import { WeekTimeHelper } from 'utils/helpers';

import { PropsType } from './index.type';
import styles from './style.module.scss';

const WidgetModalEditShiftBreak: FC<PropsType> = ({
  setIsDisabled,
  valueEvent,
  setValueEvent,
  handleBreakChange,
}) => {
  const { t } = useTranslation();

  const timeStart = valueEvent?.work?.from;

  const timeEnd = valueEvent?.work?.to;

  useEffect(() => {
    setIsDisabled(false);
    valueEvent?.break?.forEach((item) => {
      const breakStart = item?.from;
      const breakEnd = item?.to;

      if (timeStart && breakStart && timeStart >= breakStart) {
        setIsDisabled(true);
      } else if (breakStart && breakEnd && breakStart >= breakEnd) {
        setIsDisabled(true);
      } else if (breakStart && timeStart && breakStart <= timeStart) {
        setIsDisabled(true);
      } else if (breakEnd && timeEnd && breakEnd >= timeEnd) {
        setIsDisabled(true);
      } else if (!(breakStart && breakEnd)) {
        setIsDisabled(true);
      }
    });
  }, [setIsDisabled, timeEnd, timeStart, valueEvent?.break]);

  return (
    <div className={styles.wrapBreak}>
      {valueEvent?.break?.map((item, index) => {
        const breakStart = item?.from;
        const breakEnd = item?.to;
        const isAllInput = timeStart && timeEnd && breakEnd && breakStart;

        return (
          <div className={styles.itemBreak} key={index}>
            <div className={styles.text}>{t('MANAGER.STAFF.BREAK')}</div>

            <UITimePicker
              format="HH:mm"
              placeholder={t('COMMON.SELECT')}
              value={
                (item?.from as unknown as Date) &&
                new Date(`${STANDART_DATE} ${item?.from}`)
              }
              onChange={(e: Date | null) => handleBreakChange(e, 'from', index)}
              disabledTime={() =>
                WeekTimeHelper.onDisabledTime(
                  isAllInput
                    ? WeekTimeHelper.disabledBreakStart(
                        timeStart,
                        timeEnd,
                        breakEnd,
                        breakStart,
                      )
                    : [],
                  isAllInput
                    ? WeekTimeHelper.disabledBreakStart(
                        timeStart,
                        timeEnd,
                        breakEnd,
                        breakStart,
                        true,
                      )
                    : [],
                )
              }
              {...(timeStart &&
                breakStart &&
                timeStart >= breakStart && { status: 'error' })}
            />
            <span className={styles.dash}></span>
            <UITimePicker
              placeholder={t('COMMON.SELECT')}
              format="HH:mm"
              value={
                (item?.to as unknown as Date) &&
                new Date(`${STANDART_DATE} ${item?.to}`)
              }
              onChange={(e: Date | null) => handleBreakChange(e, 'to', index)}
              disabledTime={() =>
                WeekTimeHelper.onDisabledTime(
                  isAllInput
                    ? WeekTimeHelper.disabledBreakEnd(
                        timeStart,
                        timeEnd,
                        breakStart,
                        breakEnd,
                      )
                    : [],
                  isAllInput
                    ? WeekTimeHelper.disabledBreakEnd(
                        timeStart,
                        timeEnd,
                        breakStart,
                        breakEnd,
                        true,
                      )
                    : [],
                )
              }
              {...(breakStart &&
                breakEnd &&
                timeEnd &&
                (breakStart >= breakEnd || breakEnd >= timeEnd) && {
                  status: 'error',
                })}
            />
            <UIButton
              type="text"
              icon={<DeleteSVG />}
              label={t('COMMON.DELETE')}
              handler={() =>
                setValueEvent({
                  ...valueEvent,
                  break: valueEvent.break?.filter((i, idx) => index !== idx),
                })
              }
              isRed
            />
          </div>
        );
      })}
    </div>
  );
};

export default WidgetModalEditShiftBreak;

import {
  ChangeEvent,
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useState,
} from 'react';

import { Input } from 'antd';
import classNames from 'classnames';

import { ReactComponent as HidePassSVG } from 'assets/images/common/redesign/hidePassword.svg';
import { ReactComponent as ShowPassSVG } from 'assets/images/common/redesign/showPassword.svg';
import { VALIDATION_CLASS } from 'utils';

import styles from './style.module.scss';
import { StringHelper } from 'utils/helpers';

const { Search } = Input;

type PropsType = {
  className?: string;
  disabled?: boolean;
  error?: string | string[] | null;
  icon?: ReactNode;
  iconLabel?: ReactNode;
  id?: string;
  isSearch?: boolean;
  isShowPassword?: boolean;
  maxLength?: number;
  name?: string;
  necessaryLabel?: string;
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onSearch?: () => void;
  pattern?: string;
  placeholder?: string;
  prefix?: ReactNode;
  status?: '' | 'error' | 'warning';
  text?: string | ReactNode;
  type?: 'text' | 'number' | 'email' | 'password';
  value?: string;
};

const UITextInput: FC<PropsType> = ({
  text,
  id,
  placeholder,
  className,
  onChange,
  value,
  error,
  isSearch,
  prefix,
  necessaryLabel,
  onSearch,
  disabled,
  icon,
  type,
  maxLength,
  name,
  status,
  isShowPassword,
  iconLabel,
  pattern,
  onBlur,
  onFocus,
}) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const Component = (isSearch && Search) || Input;

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [VALIDATION_CLASS]: error,
      })}
    >
      {text && (
        <div className={styles.label}>
          {text}{' '}
          {necessaryLabel && (
            <span className={styles.necessaryLabel}>{necessaryLabel}</span>
          )}
          {iconLabel && iconLabel}
        </div>
      )}
      <Component
        id={id}
        {...(isSearch && { onSearch })}
        prefix={
          prefix as ReactElement<any, string | JSXElementConstructor<any>> &
            string
        }
        pattern={pattern}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        className={styles.input}
        value={value}
        disabled={disabled}
        type={isVisiblePassword ? 'text' : type}
        name={name}
        maxLength={maxLength}
        status={error ? 'error' : status || undefined}
        suffix={
          (isShowPassword && (
            <div onClick={() => setIsVisiblePassword(!isVisiblePassword)}>
              {isVisiblePassword ? <ShowPassSVG /> : <HidePassSVG />}
            </div>
          )) ||
          (icon && <div className={styles.icon}>{icon}</div>)
        }
        onBlur={onBlur}
      />
      {error && (
        <div className={styles.error}>
          {StringHelper.getFisrtMessage(error)}
        </div>
      )}
    </div>
  );
};

export default UITextInput;

/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { call, fork, takeLatest, put } from 'redux-saga/effects';

import i18n from 'lib/i18n/i18n';
import { AppointmentsHttp, ServicesHttp } from 'services/http';
import { addAlert } from 'store/Alert';
import { AlertHelper, StringHelper } from 'utils/helpers';
import { getRequestErrors } from 'utils/helpers/requests';

import {
  updateAppointmentSearchFailure,
  updateAppointmentAvailableFailure,
  updateAppointmentAvailableSuccess,
  updateAppointmentSuccess,
  updateAppointmentFailure,
  getAppointmentSuccess,
  getAppointmentFailure,
  getAppointmentScheduleSuccess,
  updateSearchClientSuccess,
  updateSearchContactSuccess,
  updateSearchServiceSuccess,
  updateSearchStaffSuccess,
  createAppointmentFailure,
  createAppointmentSuccess,
  deleteAppointmentFailure,
  deleteAppointmentSuccess,
  getAppointmentMobileSuccess,
  getOneServiceSuccess,
  getOneServiceFailure,
  getHasClientSuccess,
  getHasClientFailure,
} from './Appointments.action';
import {
  CREATE_APPOINTMENT_REQUEST,
  DELETE_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST,
  GET_HAS_CLIENT_REQUEST,
  GET_ONE_SERVICE_REQUEST,
  UPDATE_APPOINTMENT_AVAILABLE_REQUEST,
  UPDATE_APPOINTMENT_REQUEST,
  UPDATE_APPOINTMENT_SEARCH_REQUEST,
} from './Appointments.constant';
import {
  ICreateAppointmentRequest,
  IDeleteAppointmentRequest,
  IGetAppointmentRequest,
  IGetAppointmentResponse,
  IGetHasClientRequest,
  IGetOneServicesRequest,
  IUpdateAppointmentAvailableRequest,
  IUpdateAppointmentRequest,
  IUpdateAppointmentSearchRequest,
  IUpdateAppointmentSearchResponse,
  MethodSearch,
} from './Appointments.type';
import { IGetOneServiceResponse } from '../Services';

function* workerUpdateSearch({
  payload,
}: IUpdateAppointmentSearchRequest): unknown {
  try {
    const { body, method } = payload;

    const { data }: { data: IUpdateAppointmentSearchResponse } = yield call(
      AppointmentsHttp.updateSearch,
      body,
      method,
    );

    switch (method) {
      case MethodSearch.CLIENT:
        return yield put(updateSearchClientSuccess(data.data));
      case MethodSearch.CONTACT:
        return yield put(updateSearchContactSuccess(data.data));
      case MethodSearch.SERVICE:
        return yield put(updateSearchServiceSuccess(data.data));
      case MethodSearch.STAFF:
        return yield put(updateSearchStaffSuccess(data.data));
      default:
        return null;
    }
  } catch (error) {
    yield getRequestErrors(updateAppointmentSearchFailure, error as string);
  }
}

function* watchUpdateSearch() {
  yield takeLatest(UPDATE_APPOINTMENT_SEARCH_REQUEST, workerUpdateSearch);
}

function* workerUpdateAvailable({
  payload,
}: IUpdateAppointmentAvailableRequest): unknown {
  try {
    const { data } = yield call(AppointmentsHttp.updateAvailable, payload);

    yield put(updateAppointmentAvailableSuccess(data as string[]));
  } catch (error) {
    yield getRequestErrors(updateAppointmentAvailableFailure, error as string);
  }
}

function* watchUpdateAvailable() {
  yield takeLatest(UPDATE_APPOINTMENT_AVAILABLE_REQUEST, workerUpdateAvailable);
}

function* workerUpdateAppointment({
  payload,
}: IUpdateAppointmentRequest): unknown {
  try {
    const { body, id } = payload;

    yield call(AppointmentsHttp.updateAppointment, body, id);

    yield put(updateAppointmentSuccess(id));
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_UPDATED'),
        type: 'success',
        placement: 'right',
      }),
    );
  } catch (error) {
    yield getRequestErrors(updateAppointmentFailure, error as string);
  }
}

function* watchUpdateAppointment() {
  yield takeLatest(UPDATE_APPOINTMENT_REQUEST, workerUpdateAppointment);
}

function* workerGetAppointment({ payload }: IGetAppointmentRequest) {
  try {
    const { query, type } = payload;

    const { data }: { data: IGetAppointmentResponse } = yield call(
      AppointmentsHttp.getAppointment,
      query,
    );

    yield put(getAppointmentScheduleSuccess(data.schedule));
    if (type === 'mobile') {
      yield put(getAppointmentMobileSuccess(data.data));
    } else if (type === 'all') {
      yield put(getAppointmentMobileSuccess(data.data));
      yield put(getAppointmentSuccess(data.data));
    } else {
      yield put(getAppointmentSuccess(data.data));
    }
  } catch (error) {
    yield getRequestErrors(getAppointmentFailure, error as string);
    yield put(AlertHelper.getError());
  }
}

function* watchGetAppointment() {
  yield takeLatest(GET_APPOINTMENT_REQUEST, workerGetAppointment);
}

function* workerCreateAppointment({ payload }: ICreateAppointmentRequest) {
  try {
    yield call(AppointmentsHttp.createAppointment, payload);

    yield put(createAppointmentSuccess());
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_SAVED'),
        type: 'success',
        placement: 'right',
      }),
    );
  } catch (error) {
    yield getRequestErrors(
      createAppointmentFailure,
      StringHelper.getError(error as { [key: string]: string[] }),
    );
  }
}

function* watchCreateAppointment() {
  yield takeLatest(CREATE_APPOINTMENT_REQUEST, workerCreateAppointment);
}

function* workerDeleteAppointment({ payload }: IDeleteAppointmentRequest) {
  try {
    yield call(AppointmentsHttp.deleteAppointment, payload);

    yield put(deleteAppointmentSuccess(payload));
    yield put(
      addAlert({
        message: i18n.t('COMMON.SUCCESSFULLY_DELETE'),
        type: 'success',
        placement: 'right',
      }),
    );
  } catch (error) {
    yield getRequestErrors(deleteAppointmentFailure, error as string);
  }
}

function* watchDeleteAppointment() {
  yield takeLatest(DELETE_APPOINTMENT_REQUEST, workerDeleteAppointment);
}

function* workerGetOneService({ payload }: IGetOneServicesRequest) {
  try {
    const { data }: { data: IGetOneServiceResponse } = yield call(
      ServicesHttp.getOneService,
      payload,
    );

    yield put(getOneServiceSuccess(data.data));
  } catch (error) {
    yield getRequestErrors(getOneServiceFailure, error as string);
    yield put(AlertHelper.getError());
  }
}

function* watchGetOneService() {
  yield takeLatest(GET_ONE_SERVICE_REQUEST, workerGetOneService);
}

function* workerGetHasClient({ payload }: IGetHasClientRequest) {
  try {
    const { data }: { data: { message: string } } = yield call(
      AppointmentsHttp.getHasClient,
      payload,
    );

    yield put(getHasClientSuccess(data));
  } catch (error) {
    yield getRequestErrors(getHasClientFailure, error as string);
    yield put(AlertHelper.getError());
  }
}

function* watchGetHasClient() {
  yield takeLatest(GET_HAS_CLIENT_REQUEST, workerGetHasClient);
}

export const appointmentsWatchers = [
  fork(watchUpdateSearch),
  fork(watchUpdateAvailable),
  fork(watchUpdateAppointment),
  fork(watchGetAppointment),
  fork(watchCreateAppointment),
  fork(watchDeleteAppointment),
  fork(watchGetOneService),
  fork(watchGetHasClient),
];

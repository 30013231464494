import { FC, useMemo, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TimeWeekType } from 'entities/manager/staff';
import { ModalType } from 'entities/modal';
import { showModal } from 'store/Modal';
import { IRootState } from 'store/Root.reducer';
import { UIButton } from 'ui';

import { PropsType, DayTextType } from './index.type';
import StaffHoursRow from './Row';
import styles from './style.module.scss';

const ServicesUIHours: FC<PropsType> = ({ staffId }) => {
  const { staffSchedule } = useSelector((state: IRootState) => state.staff);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(
      showModal({
        modalType: ModalType.MANAGER_STAFF_EDIT_HOUR,
        modalProps: {
          staffId,
        },
      }),
    );
  };

  const renderMockData = useCallback(
    () => [
      {
        week: t('MANAGER.PROFILE_SALON.MONDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'monday',
      },
      {
        week: t('MANAGER.PROFILE_SALON.TUESDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'tuesday',
      },
      {
        week: t('MANAGER.PROFILE_SALON.WEDNESDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'wednesday',
      },
      {
        week: t('MANAGER.PROFILE_SALON.THURSDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'thursday',
      },
      {
        week: t('MANAGER.PROFILE_SALON.FRIDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'friday',
      },
      {
        week: t('MANAGER.PROFILE_SALON.SATURDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'saturday',
      },
      {
        week: t('MANAGER.PROFILE_SALON.SUNDAY'),
        break: t('MANAGER.PROFILE_SALON.ADD_BREAK'),
        value: 'sunday',
      },
    ],
    [t],
  );

  const renderTime = useMemo(
    () =>
      renderMockData().map((item, index) => (
        <StaffHoursRow
          key={index}
          name={item.week}
          workStart={
            (staffSchedule as unknown as TimeWeekType)?.[
              item.value as DayTextType
            ]?.work?.from
          }
          workEnd={
            (staffSchedule as unknown as TimeWeekType)?.[
              item.value as DayTextType
            ]?.work?.to
          }
          breakStart={
            (staffSchedule as unknown as TimeWeekType)?.[
              item.value as DayTextType
            ]?.break?.from
          }
          breakEnd={
            (staffSchedule as unknown as TimeWeekType)?.[
              item.value as DayTextType
            ]?.break?.to
          }
        />
      )),
    [staffSchedule, renderMockData],
  );

  return (
    <div className={styles.wrapper}>
      {renderTime}
      <div className={styles.shifts}>
        <UIButton
          label={t('MANAGER.STAFF.STAFFER_SHIFTS')}
          handler={handleOpenModal}
        />
      </div>
    </div>
  );
};

export default ServicesUIHours;

import { combineReducers } from 'redux';

import adminCategoriesReducer from './Admin/Categories/Categories.reducer';
import adminCustomerReducer from './Admin/Customer/Customer.reducer';
import adminFaqReducer from './Admin/Faq/Faq.reducer';
import adminNotificationsReducer from './Admin/Notifications/Notifications.reducer';
import adminPagesReducer from './Admin/Pages/Pages.reducer';
import adminSalonsReducer from './Admin/Salons/Salons.reducer';
import adminSeoReducer from './Admin/Seo/Seo.reducer';
import adminSettingsReducer from './Admin/Settings/Settings.reducer';
import alertReducer from './Alert/Alert.reducer';
import authReducer from './Auth/Auth.reducer';
import appointmentsReducer from './Manager/Appointments/Appointments.reducer';
import managerClientsReducer from './Manager/Clients/Clients.reducer';
import documentReducer from './Manager/Documents/Documents.reducer';
import feedbacksReducer from './Manager/Feedbacks/Feedbacks.reducer';
import notificationReducer from './Manager/Notifications/Notifications.reducer';
import ordersReducer from './Manager/Orders/Orders.reducer';
import salonReducer from './Manager/Salon/Salon.reducer';
import ServicesReducer from './Manager/Services/Services.reducer';
import settingsReducer from './Manager/Settings/Settings.reducer';
import shiftReducer from './Manager/Shift/Shift.reducer';
import staffReducer from './Manager/Staff/Staff.reducer';
import managerSubscriptionReducer from './Manager/Subscription/Subscription.reducer';
import supportManagerReducer from './Manager/Support/Support.reducer';
import mediaReducer from './Media/Media.reducer';
import modalReducer from './Modal/Modal.reducer';
import validationReducer from './Validation/Validation.reducer';
import basketReducer from './Manager/Basket/Basket.reducer';

export type IRootState = ReturnType<typeof rootReducer>;
export const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  adminCategories: adminCategoriesReducer,
  adminCustomer: adminCustomerReducer,
  adminSalons: adminSalonsReducer,
  adminPages: adminPagesReducer,
  adminSettings: adminSettingsReducer,
  adminFaq: adminFaqReducer,
  media: mediaReducer,
  service: ServicesReducer,
  salon: salonReducer,
  staff: staffReducer,
  shift: shiftReducer,
  modal: modalReducer,
  appointments: appointmentsReducer,
  feedbacks: feedbacksReducer,
  orders: ordersReducer,
  adminNotifications: adminNotificationsReducer,
  document: documentReducer,
  settings: settingsReducer,
  managerClients: managerClientsReducer,
  managerSupport: supportManagerReducer,
  managerSubscription: managerSubscriptionReducer,
  validation: validationReducer,
  adminSeo: adminSeoReducer,
  managerNotification: notificationReducer,
  basket: basketReducer,
});

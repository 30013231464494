import { useSelector } from 'react-redux'

import { ModalType } from 'entities/modal'
import { IRootState } from 'store/Root.reducer'

import WidgetModalAddService from './AddService'
import WidgetModalAddTimeOff from './AddTimeOff'
import WidgetModalCopyShift from './CopyShift'
import WidgetModalDeactivateCategory from './DeactivateCaterory'
import WidgetModalDeleteService from './DeleteService'
import WidgetModalDeleteStaff from './DeleteStaff'
import WidgetModalEditShift from './EditShift'
import WidgetModalImageEditor from './ImageEditor'
import WidgetsModalNotify from './Notify'
import WidgetModalStaffEditHour from './StaffEditHour'

const WidgetModal = () => {
  const { modalType, modalProps } = useSelector(
    (state: IRootState) => state.modal,
  )

  const renderModal = () => {
    switch (modalType) {
      case ModalType.MANAGER_DELETE_SERVICE:
        return <WidgetModalDeleteService {...modalProps} />
      case ModalType.MANAGER_STAFF_EDIT_HOUR:
        return <WidgetModalStaffEditHour {...modalProps} />
      case ModalType.MANAGER_DELETE_STAFF:
        return <WidgetModalDeleteStaff {...modalProps} />
      case ModalType.MANAGER_ADD_SERVICE:
        return <WidgetModalAddService {...modalProps} />
      case ModalType.MANAGER_ADD_TIME_OFF:
        return <WidgetModalAddTimeOff {...modalProps} />
      case ModalType.MANAGER_EDIT_SHIFT:
        return <WidgetModalEditShift {...modalProps} />
      case ModalType.MANAGER_COPY_SHIFT:
        return <WidgetModalCopyShift {...modalProps} />
      case ModalType.ADMIN_DEACTIVATE_CATEGORY:
        return <WidgetModalDeactivateCategory {...modalProps} />
      case ModalType.MANAGER_IMAGE_EDITOR:
        return <WidgetModalImageEditor {...modalProps} />
      case ModalType.NOTIFY:
        return <WidgetsModalNotify {...modalProps} />

      default:
        return null
    }
  }

  return renderModal()
}

export default WidgetModal

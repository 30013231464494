import { FC } from 'react';

import cn from 'classnames';

import { PropsType } from './index.type';
import styles from './style.module.scss';

const UIButton: FC<PropsType> = ({
  type = 'primary',
  icon,
  label,
  handler,
  disabled,
  className,
  isGreen,
  isRed,
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(className, {
        [styles.btn]: true,
        [styles[type]]: true,
        [styles.green]: isGreen,
        [styles.red]: isRed,
      })}
      onClick={handler}
    >
      {icon && icon}
      <p className={cn('mb-0', styles.text)}>{label}</p>
    </button>
  );
};

export default UIButton;

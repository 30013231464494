import { ManagerSettingsUpdateType } from 'store/Manager/Settings/Settings.type';
import { GET, POST } from 'utils/http';

const basePath = '/salon/settings';

export const getSettings = () => GET(`${basePath}`);

export const updateSettings = (body: ManagerSettingsUpdateType) =>
  POST(`${basePath}`, body);

export const syncGoogleCalendar = (body: { code: string }) =>
  POST('/auth/google-calendar/code', body);

export const desyncGoogleCalendar = () =>
  POST('/auth/google-calendar/disconnect');

/* eslint-disable @typescript-eslint/naming-convention */
import {
  FC,
  useState,
  SyntheticEvent,
  useMemo,
  useCallback,
  memo,
  useEffect,
} from 'react'

import { Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FullMedia } from 'entities/common'
import TextBlock from 'modules/admin/Faq/TextBlock'
import { IRootState } from 'store/Root.reducer'
import { UIButton } from 'ui'
import { OnUploadFileChange } from 'ui/UploadFile/index.type'
import { SEO_INPUTS } from 'utils'
import { getAdminCustomerTemplate } from 'utils/helpers/adminTemplate'
import { CustomerBody, TemplateBodyType } from 'views/admin/Pages/index.type'

import {
  CustomerTemplateStateType,
  INITIAL_CUSTOMER_STATE_SECTION_FIVE,
  INITIAL_CUSTOMER_STATE_SECTION_FOUR,
  INITIAL_CUSTOMER_STATE_SECTION_SIX,
  INITIAL_CUSTOMER_STATE_SECTION_TWO,
  INITAL_SEO_STATE,
} from './index.type'
import style from './style.module.scss'
import {
  BusinessTemplateSetterType,
  BusinessTemplateStateType,
  INITIAL_STATE_SECTION_ONE,
  INITIAL_STATE_SECTION_THREE,
} from '../index.type'
import {
  BusinessTemplateBlock,
  BusinessTemplateSectionThree,
  CustomerTemplateSectionSix,
} from '../ui'
import {
  TYPE_FILE,
  TYPE_INPUT,
  TYPE_TEXTAREA,
} from '../ui/TemplateField/index.type'

type PropsType = { onSave: (body: TemplateBodyType) => void }

const CustomerTemplate: FC<PropsType> = ({ onSave }) => {
  const { t } = useTranslation()
  const { currentPage, isLoading } = useSelector(
    (store: IRootState) => store.adminPages,
  )
  const [sectionOne, setSectionOne] = useState<BusinessTemplateStateType>(
    INITIAL_STATE_SECTION_ONE,
  )
  const [sectionTwo, setSectionTwo] = useState<BusinessTemplateStateType>(
    INITIAL_CUSTOMER_STATE_SECTION_TWO,
  )
  const [sectionThree, setSectionThree] = useState(INITIAL_STATE_SECTION_THREE)
  const [sectionFour, setSectionFour] = useState<BusinessTemplateStateType>(
    INITIAL_CUSTOMER_STATE_SECTION_FOUR,
  )
  const [sectionFive, setSectionFive] = useState<BusinessTemplateStateType>(
    INITIAL_CUSTOMER_STATE_SECTION_FIVE,
  )
  const [sectionSix, setSectionSix] = useState<CustomerTemplateStateType>(
    INITIAL_CUSTOMER_STATE_SECTION_SIX,
  )
  const [seo, setSeo] = useState<BusinessTemplateStateType>(INITAL_SEO_STATE)
  const { section_1_description, section_1_title, section_1_image } = sectionOne
  const { section_2_description, section_2_title } = sectionTwo
  const {
    section_4_description,
    section_4_title,
    section_4_image_1,
    section_4_image_2,
  } = sectionFour
  const {
    section_5_description,
    section_5_title,
    section_5_image_1,
    section_5_image_2,
    section_5_image_3,
  } = sectionFive

  const handleChange = useCallback(
    (setter: BusinessTemplateSetterType) => (event: SyntheticEvent) => {
      const { name, value } = event.target as HTMLInputElement

      setter((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    },
    [],
  )

  const handleFileChange: (
    setter: BusinessTemplateSetterType,
  ) => OnUploadFileChange =
    (setter: BusinessTemplateSetterType) => (name, value) => {
      setter((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

  const handleSave = () => {
    onSave({
      ...sectionOne,
      ...sectionTwo,
      ...sectionThree,
      ...sectionFour,
      ...sectionFive,
      ...sectionSix,
      ...seo,
    } as CustomerBody)
  }

  const sectionOneInputs = useMemo(
    () => [
      {
        label: t('COMMON.TITLE_H1'),
        value: section_1_title,
        name: 'section_1_title',
        type: TYPE_INPUT,
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_1_description,
        name: 'section_1_description',
        type: TYPE_TEXTAREA,
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
        name: 'section_1_image',
        widthRatio: 3.09,
        heightRatio: 1,
        type: TYPE_FILE,
        selectedName: String(
          (section_1_image as FullMedia)?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
    ],
    [section_1_description, section_1_title, section_1_image, t],
  )

  const sectionTwoInputs = useMemo(
    () => [
      {
        label: t('COMMON.TITLE'),
        value: section_2_title,
        name: 'section_2_title',
        type: TYPE_INPUT,
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_2_description,
        name: 'section_2_description',
        type: TYPE_TEXTAREA,
      },
    ],
    [section_2_description, section_2_title, t],
  )

  const sectionFourInputs = useMemo(
    () => [
      {
        label: t('COMMON.TITLE'),
        value: section_4_title,
        name: 'section_4_title',
        type: TYPE_INPUT,
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_4_description,
        name: 'section_4_description',
        type: TYPE_TEXTAREA,
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
        name: 'section_4_image_1',
        type: TYPE_FILE,
        selectedName: String(
          (section_4_image_1 as FullMedia)?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 2 }),
        name: 'section_4_image_2',
        type: TYPE_FILE,
        selectedName: String(
          (section_4_image_2 as FullMedia)?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
    ],
    [
      section_4_description,
      section_4_image_1,
      section_4_image_2,
      section_4_title,
      t,
    ],
  )

  const sectionFiveInputs = useMemo(
    () => [
      {
        label: t('COMMON.TITLE'),
        value: section_5_title,
        name: 'section_5_title',
        type: TYPE_INPUT,
      },
      {
        label: t('COMMON.DESCRIPTION'),
        value: section_5_description,
        name: 'section_5_description',
        type: TYPE_TEXTAREA,
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 1 }),
        name: 'section_5_image_1',
        type: TYPE_FILE,
        selectedName: String(
          (section_5_image_1 as FullMedia)?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 2 }),
        name: 'section_5_image_2',
        type: TYPE_FILE,
        selectedName: String(
          (section_5_image_2 as FullMedia)?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
      {
        label: t('ADMIN.PAGES.TEMPLATE.ABOUT_US.IMAGE', { number: 3 }),
        name: 'section_5_image_3',
        type: TYPE_FILE,
        selectedName: String(
          (section_5_image_3 as FullMedia)?.path?.split('/')?.slice(-1)?.[0],
        ),
      },
    ],
    [
      section_5_description,
      section_5_image_1,
      section_5_image_2,
      section_5_image_3,
      section_5_title,
      t,
    ],
  )

  const sections = useMemo(
    () =>
      getAdminCustomerTemplate({
        ...currentPage?.content,
        ...currentPage?.seo,
      } as CustomerBody),
    [currentPage?.content, currentPage?.seo],
  )

  useEffect(() => {
    if (currentPage?.content) {
      setSectionOne(sections.sectionOne)
      setSectionTwo(sections.sectionTwo)
      setSectionThree(
        sections.sectionThree as typeof INITIAL_STATE_SECTION_THREE,
      )
      setSectionFour(sections.sectionFour)
      setSectionFive(sections.sectionFive)
      setSectionSix(sections.sectionSix)
      setSeo(sections?.seo)
    }
  }, [sections, currentPage?.content, currentPage?.seo])

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical" className={style.customerTemplate}>
        <BusinessTemplateBlock
          array={sectionOneInputs}
          className={style.block}
          onChange={handleChange(setSectionOne)}
          onFileChange={handleFileChange(setSectionOne)}
          title={t('COMMON.SECTION_NUMBER', { number: 1 })}
        />

        <BusinessTemplateBlock
          array={sectionTwoInputs}
          className={style.block}
          onChange={handleChange(setSectionTwo)}
          onFileChange={handleFileChange(setSectionTwo)}
          title={t('COMMON.SECTION_NUMBER', { number: 2 })}
        />

        <BusinessTemplateSectionThree
          setter={setSectionThree}
          state={sectionThree}
        />

        <BusinessTemplateBlock
          array={sectionFourInputs}
          className={style.block}
          onChange={handleChange(setSectionFour)}
          onFileChange={handleFileChange(setSectionFour)}
          title={t('COMMON.SECTION_NUMBER', { number: 4 })}
        />

        <BusinessTemplateBlock
          array={sectionFiveInputs}
          className={style.block}
          onChange={handleChange(setSectionFive)}
          onFileChange={handleFileChange(setSectionFive)}
          title={t('COMMON.SECTION_NUMBER', { number: 5 })}
        />

        <CustomerTemplateSectionSix setter={setSectionSix} state={sectionSix} />

        <div className={'seo-block'}>
          {SEO_INPUTS.map(({ name, title: seoTitle }, index) => (
            <TextBlock
              key={index}
              rows={7}
              name={name}
              title={t(seoTitle)}
              value={seo[name] as string}
              onChange={handleChange(setSeo)}
            />
          ))}
        </div>

        <UIButton
          className={style.save}
          label={t('COMMON.SAVE')}
          handler={handleSave}
        />
      </Form>
    </Spin>
  )
}

export default memo(CustomerTemplate)

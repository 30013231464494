import React, { ChangeEvent, FC, useEffect, useState } from 'react'

import 'antd/dist/antd.css'

import { ColorResult } from 'react-color'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import LayoutManagerCenter from 'layouts/Manager/Center'
import { addStaffRequest } from 'store/Manager/Staff'
import { clearMediaStore } from 'store/Media/Media.action'
import { UPLOAD_LINK_STAFF_PORTFILIO } from 'store/Media/Media.constant'
import { IRootState } from 'store/Root.reducer'
import {
  UICustomCard,
  UIPhoneInput,
  UITextArea,
  UIUpload,
  UIUploadMove,
} from 'ui'
import UISketchPicker from 'ui/SketchPicker'
import UITextInput from 'ui/TextInput'
import { GeneralValueType } from 'ui/UploadMove/index.type'
import { MANAGER_STAFF } from 'utils'

import { INITIAL_VALUES } from './constant'
import { ValuesType } from '../index.type'
import styles from '../style.module.scss'

const ViewAddStaffStep1: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { fileData, isloading, images, linkType, errors } = useSelector(
    (state: IRootState) => ({ ...state.media, errors: state.staff.errors }),
  )
  const [color, setColor] = useState('')
  const navigate = useNavigate()
  const [number, setNumber] = useState('')
  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES)

  const handleBack = () => {
    navigate(MANAGER_STAFF)
  }

  const handleClick = () => {
    dispatch(addStaffRequest({ ...values, phone: `52-${number}` }))
    dispatch(clearMediaStore())
  }

  const handleColorChange = (clr: ColorResult) => {
    setColor(clr.hex)
    setValues({ ...values, color: clr.hex })
  }

  const onChangeInput = (
    value: string,
    name: 'name' | 'position' | 'description',
  ) => {
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {
    if (fileData) {
      setValues({ ...values, avatar: fileData.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData])

  useEffect(() => {
    if (images) {
      setValues({
        ...values,
        images: [...values.images, ...images.map((i) => i.id)],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])

  useEffect(() => {
    dispatch(clearMediaStore())
  }, [dispatch])

  return (
    <LayoutManagerCenter
      title={t('MANAGER.STAFF.NEW_STAFF_MEMBER')}
      handleBack={handleBack}
      handleSave={handleClick}
      isHeader
    >
      <UICustomCard
        title={
          <>
            <span>1. </span> {t('MANAGER.STAFF.ITEM_1')}
          </>
        }
      >
        <div className={styles.infoWrapp}>
          <div className={styles.avatar}>
            <p>{t('MANAGER.STAFF.PHOTO')}</p>

            <UIUpload
              className={styles.uploadAvatar}
              pathImg={fileData?.path}
              setValues={setValues}
              values={values}
              fileData={fileData}
              isCircle={true}
              isLoading={isloading}
              widthRatio={1}
              heightRatio={1}
            />
          </div>

          <div className={styles.inputWrapp}>
            <UITextInput
              onChange={(e) => onChangeInput(e.target.value, 'name')}
              text={t('MANAGER.STAFF.NAME')}
              placeholder={t('MANAGER.STAFF.NAME')}
              necessaryLabel={`(${t('COMMON.NECESSARY')})`}
              value={values.name}
              maxLength={150}
              error={errors?.name}
            />

            <UIPhoneInput
              label={t('COMMON.PHONE_NUMBER')}
              necessaryLabel={`(${t('COMMON.NECESSARY')})`}
              value={number}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNumber(e.target.value)
              }
            />

            <UITextInput
              onChange={(e) => onChangeInput(e.target.value, 'position')}
              text={t('MANAGER.STAFF.POSITION')}
              placeholder={t('MANAGER.STAFF.POSITION')}
              necessaryLabel={`(${t('COMMON.NECESSARY')})`}
              value={values.position}
              maxLength={150}
              error={errors?.position}
            />
          </div>

          <div className={styles.texareaWrapp}>
            <UITextArea
              onChange={(e) => onChangeInput(e.target.value, 'description')}
              label={t('MANAGER.STAFF.STAFFET')}
              placeholder={t('MANAGER.STAFF.STAFFET_PLACEHOLDER')}
              value={values.description}
            />
          </div>
        </div>
      </UICustomCard>

      <UICustomCard
        title={
          <>
            <span>2. </span> {t('MANAGER.STAFF.ITEM_3')}
          </>
        }
      >
        <UIUploadMove
          images={images}
          isLoading={linkType === UPLOAD_LINK_STAFF_PORTFILIO && isloading}
          setValues={
            setValues as (value: React.SetStateAction<GeneralValueType>) => void
          }
          values={values as GeneralValueType}
          className={styles.uploadMoveWrapp}
          linkType={UPLOAD_LINK_STAFF_PORTFILIO}
          widthRatio={1.14}
          heightRatio={1}
        />
      </UICustomCard>

      <UICustomCard
        title={
          <>
            <span>3. </span> {t('MANAGER.STAFF.ITEM_4')}
          </>
        }
      >
        <div className={styles.wrappColor}>
          <p>
            {t('MANAGER.STAFF.COLOR_DESCRIPTION')}{' '}
            <span className={styles.necessaryLabel}>{`(${t(
              'COMMON.NECESSARY',
            )})`}</span>
          </p>

          <UISketchPicker
            color={color}
            error={errors?.color}
            onChange={(clr) => handleColorChange(clr)}
          />
        </div>
      </UICustomCard>
    </LayoutManagerCenter>
  )
}

export default ViewAddStaffStep1

import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { UICheckbox } from 'ui'
import {
  FORMAT_DATE_MONTH_WITH_DAY,
  FORMAT_DATE_YEAR,
  FORMAT_NUMBER_WEEK,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import { PropsType } from './index.type'
import styles from '../style.module.scss'

const WidgetModalCopyShiftCheckboxTime: FC<PropsType> = ({
  values,
  setValues,
}) => {
  const { t } = useTranslation()

  const startWeekDate = Math.min(...values.copy.map((i) => +i.valueOf()))

  const endWeekDate = Math.max(...values.copy.map((i) => +i.valueOf()))

  const renderWeeks = () => {
    const weekArray = [...Array(52).fill(1)].map((_, index) => index)

    const value: { date: Date; label: string }[] = []

    weekArray.forEach((item) => {
      const startDate = DateHelper.addWeek(
        DateHelper.startOfWeek(new Date()),
        item,
      )

      const endDate = DateHelper.addWeek(DateHelper.endOfWeek(new Date()), item)

      value.push({
        date: startDate,
        label: `${t('MANAGER.STAFF.COPY_WEEK')}#${DateHelper.toFormat(
          startDate,
          FORMAT_NUMBER_WEEK,
        )} ${DateHelper.toFormat(startDate, FORMAT_DATE_MONTH_WITH_DAY)}
        -
        ${DateHelper.toFormat(
          endDate,
          FORMAT_DATE_MONTH_WITH_DAY,
        )}, ${DateHelper.toFormat(startDate, FORMAT_DATE_YEAR)} `,
      })
    })

    return value
  }

  const handleCheckboxChange = (date: Date) => {
    if (values?.copy?.find((i) => i.valueOf() === date.valueOf())) {
      setValues({
        ...values,
        copy: values?.copy.filter((i) => i.valueOf() !== date.valueOf()),
      })
    } else {
      setValues({
        ...values,
        copy: [
          ...values.copy,
          ...renderWeeks()
            .map((item) => {
              if (
                date.valueOf() === item.date.valueOf() ||
                (values.copy.length > 0 &&
                  ((date.valueOf() < item.date.valueOf() &&
                    item.date.valueOf() < startWeekDate) ||
                    (date.valueOf() > item.date.valueOf() &&
                      item.date.valueOf() > endWeekDate)))
              ) {
                return item.date
              }

              return ''
            })
            .filter((i) => i !== ''),
        ],
      })
    }
  }

  const handleCheckboxAllChange = () => {
    setValues({ ...values, copy: renderWeeks().map((item) => item.date) })
  }

  return (
    <div className={styles.dateCheckbox}>
      <p>{t('MANAGER.STAFF.APPLY_WEEKS')}</p>
      <UICheckbox
        onChange={handleCheckboxAllChange}
        checked={values.copy.length === renderWeeks().length}
      >
        {t('MANAGER.STAFF.ALL')}
      </UICheckbox>
      <div className={styles.weeks}>
        {renderWeeks().map(({ date, label }, index) => {
          return (
            <div key={index}>
              <UICheckbox
                onChange={() => {
                  handleCheckboxChange(date)
                }}
                disabled={
                  values.copy.map((i) => i.valueOf()).indexOf(date.valueOf()) >
                    -1 &&
                  !(
                    date.valueOf() === startWeekDate ||
                    date.valueOf() === endWeekDate
                  )
                }
                checked={
                  !!values.copy?.find((i) => i.valueOf() === date.valueOf())
                }
              >
                {label}
              </UICheckbox>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default WidgetModalCopyShiftCheckboxTime

import {
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';

import FullCalendar from '@fullcalendar/react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as ArrowLeft } from 'assets/images/common/redesign/arrowLeft.svg';
import { ReactComponent as ArrowRigth } from 'assets/images/common/redesign/arrowRight.svg';
import { SelectOption } from 'entities/common';
import useClickOutside from 'hooks/useClickOutside';
import useResponsive from 'hooks/useResponsive';
import {
  getAppointmentRequest,
  setQuery,
} from 'store/Manager/Appointments/Appointments.action';
import { getStaffRequest } from 'store/Manager/Staff';
import { IRootState } from 'store/Root.reducer';
import { UIButton, UICalendar, UIDatePicker, UISelect } from 'ui';
import { FORMAT_DATE } from 'utils';
import { DateHelper } from 'utils/helpers';

import UICalendarHeaderDateTitle from './DateTitle';
import {
  DateType,
  ModeShiftType,
  ModeType,
  NavigateType,
  PropsType,
} from './index.type';
import UICalendarHeaderMode from './Mode';
import styles from './style.module.scss';

const UICalendarHeader: FC<PropsType> = ({
  calendarRef,
  isShift,
  btnLabel,
  btnIcon,
  btnHandler,
  setMode,
  mode: stateMode,
  setHeaderDate,
}) => {
  const { staffs } = useSelector((state: IRootState) => state.staff);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [refCurrent, setRefCurrent] =
    useState<InstanceType<typeof FullCalendar>>();

  const [currentMode, setCurrentMode] = useState<ModeType | ModeShiftType>(
    isShift ? ModeShiftType.DAY : ModeType.DAY,
  );
  const [isCalendar, setIsCalendar] = useState(false);
  const [isCalendarMonth, setIsCalendarMonth] = useState(false);
  const [updateRef, setUpdateRef] = useState(false);
  const [rangeDate, setRangeDate] = useState({
    start: new Date(DateHelper.getUnixDate(new Date())),
    end: new Date(DateHelper.getUnixDate(new Date())),
  });
  const [selectValue, setSelectValue] = useState<number>(0);
  const [optionMaster, setOptionMaster] = useState<SelectOption[]>([]);

  const refPopupCalendar = useRef(null);
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useResponsive();

  const getApi = refCurrent?.getApi();

  useClickOutside(refPopupCalendar, () => {
    setIsCalendar(false);
    setIsCalendarMonth(false);
  });

  const handleNavigate = useCallback(
    (type: NavigateType) => {
      if (!isShift) {
        setUpdateRef(!updateRef);
      }

      switch (type) {
        case NavigateType.PREV:
          return getApi?.prev();
        case NavigateType.NEXT:
          return getApi?.next();
        case NavigateType.TODAY:
          return getApi?.today();
        default:
          return null;
      }
    },
    [getApi, isShift, updateRef],
  );

  const handleDateChange = (time: DateType) => {
    getApi?.gotoDate(new Date(DateHelper.getUnixDate(time.start)));

    setRangeDate({
      start: new Date(DateHelper.getUnixDate(time.start)),
      end: new Date(DateHelper.getUnixDate(time.end)),
    });
  };

  const renderTitleCalendar = useCallback(
    (mode: ModeType | ModeShiftType) => {
      switch (mode) {
        case ModeShiftType.WEEK:
        case ModeType.WEEK:
          return (
            <UICalendarHeaderDateTitle date={rangeDate.start} type="week" />
          );
        case ModeType.MONTH:
          return (
            <UICalendarHeaderDateTitle date={rangeDate.start} type="month" />
          );
        default:
          return (
            <UICalendarHeaderDateTitle date={rangeDate.start} type="day" />
          );
      }
    },
    [rangeDate.start],
  );

  const getDate = useCallback(() => {
    switch (currentMode) {
      case ModeShiftType.WEEK:
      case ModeType.WEEK:
        return {
          start: DateHelper.startOfWeek(rangeDate.start),
          end: DateHelper.endOfWeek(rangeDate.start),
        };
      case ModeType.MONTH:
        return {
          start: DateHelper.startMonth(rangeDate.start),
          end: DateHelper.endMonth(rangeDate.start),
        };

      default:
        return {
          start: DateHelper.toFormat(
            new Date(DateHelper.getUnixDate(rangeDate.start)),
            FORMAT_DATE,
          ),
          end: DateHelper.toFormat(
            new Date(DateHelper.getUnixDate(rangeDate.start)),
            FORMAT_DATE,
          ),
        };
    }
  }, [currentMode, rangeDate.start]);

  const query = `from=${DateHelper.toFormat(
    new Date(DateHelper.getUnixDate(getDate().start)),
    FORMAT_DATE,
  )}&to=${DateHelper.toFormat(
    new Date(DateHelper.getUnixDate(getDate().end)),
    FORMAT_DATE,
  )}`;

  const handleSelectChange = (id: number) => {
    const requestQuery = `${query}&staff=${id}`;

    searchParams.delete('staffId');
    setSearchParams(searchParams);

    setSelectValue(id);
    if (id === 0) {
      dispatch(getAppointmentRequest({ query }));
      dispatch(setQuery(query));
    } else {
      dispatch(getAppointmentRequest({ query: requestQuery }));
      dispatch(setQuery(requestQuery));
    }
  };

  useEffect(() => {
    if (calendarRef && calendarRef.current) {
      setRefCurrent(calendarRef.current);
    }
  }, [calendarRef]);

  useEffect(() => {
    if (setHeaderDate) {
      setHeaderDate(new Date(DateHelper.getUnixDate(rangeDate.start)));
    }
  }, [rangeDate.start, setHeaderDate]);

  useEffect(() => {
    if (!isShift && stateMode) {
      setCurrentMode(stateMode);
    }
  }, [isShift, stateMode]);

  useEffect(() => {
    if (getApi?.getCurrentData().currentDate) {
      setRangeDate({
        start:
          getApi?.getCurrentData().currentDate ||
          new Date(DateHelper.getUnixDate(new Date())),
        end:
          getApi?.getCurrentData().currentDate ||
          new Date(DateHelper.getUnixDate(new Date())),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApi?.getCurrentData().currentDate]);

  useEffect(() => {
    if (!isShift && refCurrent) {
      if (currentMode !== ModeType.WEEK) {
        if (searchParams.get('staffId')) {
          dispatch(
            getAppointmentRequest({
              query: `${query}&staff=${searchParams.get('staffId')}`,
            }),
          );
        } else {
          dispatch(getAppointmentRequest({ query }));
          dispatch(setQuery(query));
        }
      }
    }
  }, [
    currentMode,
    dispatch,
    isMobile,
    isShift,
    isTablet,
    query,
    refCurrent,
    searchParams,
  ]);

  useEffect(() => {
    if (!isShift && refCurrent) {
      dispatch(getStaffRequest());
    }
  }, [dispatch, isShift, refCurrent]);

  useEffect(() => {
    if (currentMode === ModeType.WEEK && staffs) {
      const requestQuery = `${query}&staff=${selectValue}`;
      const queryWithStaff = `${query}&staff=${searchParams.get('staffId')}`;

      dispatch(
        getAppointmentRequest({
          query: searchParams.get('staffId') ? queryWithStaff : requestQuery,
        }),
      );
      dispatch(setQuery(requestQuery));
    }
  }, [currentMode, dispatch, query, searchParams, selectValue, staffs]);

  useEffect(() => {
    const option = staffs?.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    if (currentMode === ModeType.DAY || currentMode === ModeType.MONTH) {
      setOptionMaster([
        { label: t('MANAGER.STAFF.ALL_MASTERS'), value: 0 },
        ...(option || []),
      ] as unknown as SelectOption[]);
    } else {
      setOptionMaster(option as unknown as SelectOption[]);
    }
  }, [currentMode, staffs, t]);

  useEffect(() => {
    if (searchParams.get('staffId') && optionMaster?.length) {
      setSelectValue(+(searchParams.get('staffId') as unknown as number));
    }
  }, [optionMaster, optionMaster?.length, searchParams, selectValue]);

  return (
    <div
      className={cn(styles.header, {
        [styles.shiftHeader]: isShift,
        [styles.appointmentHeader]: !isShift,
      })}
    >
      {!isShift && (
        <div className={styles.filter}>
          <UISelect
            value={{
              value: selectValue,
              label: optionMaster?.find((item) => +item.value === selectValue)
                ?.label,
            }}
            options={optionMaster}
            onChange={handleSelectChange}
          />
        </div>
      )}

      <div className={styles.navigate}>
        <div
          className={styles.arrow}
          onClick={() => handleNavigate(NavigateType.PREV)}
        >
          <ArrowLeft />
        </div>

        <div className={styles.date}>
          <div
            className={styles.dateTitle}
            onClick={() => handleNavigate(NavigateType.TODAY)}
          >
            {t('COMMON.TODAY')}
          </div>

          <div
            className={styles.calendarWrap}
            onClick={() =>
              currentMode === ModeType.MONTH
                ? setIsCalendarMonth(true)
                : setIsCalendar(true)
            }
          >
            {renderTitleCalendar(currentMode)}

            <span
              className={cn(styles.calendar, {
                [styles.month]: currentMode === ModeType.MONTH,
              })}
              ref={refPopupCalendar}
            >
              {isCalendarMonth && (
                <div className={styles.monthCalendarWrapp}>
                  <UIDatePicker
                    open={true}
                    picker="month"
                    value={rangeDate.start}
                    popupClassName="monthCalendar"
                    onChange={(date: Date) => {
                      getApi?.gotoDate(new Date(DateHelper.getUnixDate(date)));
                      setRangeDate({
                        start: new Date(DateHelper.getUnixDate(date)),
                        end: new Date(DateHelper.getUnixDate(date)),
                      });
                    }}
                    getPopupContainer={(triggerNode: HTMLElement) => {
                      return triggerNode.parentNode as HTMLElement;
                    }}
                  />
                </div>
              )}

              {isCalendar && (
                <UICalendar
                  className={cn(styles.calendarStyle, {
                    [styles.viewWeek]:
                      currentMode === ModeShiftType.WEEK ||
                      currentMode === ModeType.WEEK,
                  })}
                  setDate={
                    handleDateChange as Dispatch<SetStateAction<DateType>>
                  }
                  initialDate={
                    new Date(DateHelper.getUnixDate(rangeDate.start))
                  }
                  {...((currentMode === ModeShiftType.WEEK ||
                    currentMode === ModeType.WEEK) && {
                    eventStart: DateHelper.startOfWeek(
                      new Date(DateHelper.getUnixDate(rangeDate.start)),
                    ) as unknown as Date,
                    eventEnd: DateHelper.endOfWeek(
                      new Date(DateHelper.getUnixDate(rangeDate.start)),
                    ) as unknown as Date,
                  })}
                  {...((currentMode === ModeShiftType.DAY ||
                    currentMode === ModeType.DAY) && {
                    eventStart: new Date(
                      DateHelper.getUnixDate(rangeDate.start),
                    ),
                  })}
                />
              )}
            </span>
          </div>
        </div>

        <div
          className={styles.arrow}
          onClick={() => handleNavigate(NavigateType.NEXT)}
        >
          <ArrowRigth />
        </div>
      </div>

      <UICalendarHeaderMode
        isShift={isShift}
        currentMode={currentMode}
        setMode={setMode}
        refCurrent={refCurrent}
        setCurrentMode={setCurrentMode}
        setRangeDate={setRangeDate}
        setSelectValue={setSelectValue}
        staffs={staffs}
      />

      <div>
        <UIButton
          type="text"
          label={btnLabel}
          icon={btnIcon}
          handler={btnHandler}
        />
      </div>
    </div>
  );
};

export default memo(UICalendarHeader);

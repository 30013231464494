/* eslint-disable react-hooks/exhaustive-deps */
import { FC, memo, MouseEvent, useCallback, useEffect, useState } from 'react';

import { Spin } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ReactComponent as BackSVG } from 'assets/images/common/back.svg';
import { ReactComponent as DeleteSVG } from 'assets/images/common/redesign/close.svg';
import useResponsive from 'hooks/useResponsive';
import {
  clearStore,
  createAppointmentRequest,
  deleteAppointmentRequest,
  getAppointmentRequest,
  resetError,
  resetValidationError,
  setRepeatRequest,
  updateAppointmentRequest,
} from 'store/Manager/Appointments/Appointments.action';
import { IRootState } from 'store/Root.reducer';
import { FORMAT_DATE, MANAGER_APPOINTMENTS } from 'utils';
import { DateHelper } from 'utils/helpers';

import AppointmentSidearConflicts from './Conflicts';
import { INITIAL_VALUES } from './constant';
import UIAppointmendarSidebarContent from './Content';
import { PropsType, ValuesType } from './index.type';
import styles from './style.module.scss';
import UIAppointmentsSidebarValidate from './Validate';
import UIAppointmendarSidebarAdd from './Add';
import { deleteBasketServiceRequest } from 'store/Manager/Basket/Basket.action';
import { addAlert } from 'store/Alert';

const UIAppointmentsSidebar: FC<PropsType> = ({
  setIsDrawer,
  isValidateError,
  eventData,
  eventDate,
  setEventData,
  setEventDate,
  isAddAppointment,
  selectDate,
  setSelectDate,
  mode,
  headerDate,
}) => {
  const { pathname } = useLocation();
  const {
    appointments: {
      isUpdateLoading,
      serviceList,
      appointmentsError,
      isAppointmentAction,
      query: storeQuery,
      errors,
      errorMessage,
    },
    basket: { basket },
  } = useSelector((store: IRootState) => ({
    appointments: store.appointments,
    basket: store.basket,
  }));

  const [isBack, setIsBack] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [values, setValues] = useState<ValuesType>(INITIAL_VALUES);
  const [tabMode, setTabMode] = useState<string>('1');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useResponsive();

  const handleClose = (e: MouseEvent<HTMLElement>) => {
    if ((e.target as Element)?.classList.contains(styles.outsideWrapp)) {
      setIsDrawer(false);
      dispatch(resetError());
      dispatch(resetValidationError());

      if (setEventData && setEventDate) {
        setEventData(null);
        setEventDate(new Date());
      }
    }
  };

  const handleDelete = () => {
    if (values?.id) {
      dispatch(deleteAppointmentRequest(values?.id));
      dispatch(setRepeatRequest(true));
      setIsBack(false);
    }
  };

  const handleSave = () => {
    setIsSaved(true);
    dispatch(resetError());

    if (isAddAppointment) {
      dispatch(
        createAppointmentRequest({
          staff_id: values.staff_id || undefined,
          service_id: values.service_id || undefined,
          from: DateHelper.toFormat(values.from, FORMAT_DATE),
          started_at: values.started_at,
          ...(tabMode === '1' && {
            client: {
              id: values.client_id || 0,
              type: values?.clientType || '',
            },
          }),
          ...(tabMode === '2' && {
            contact_name: (values.contact_name as string).trim(),
            ...(!!values.contact_phone && {
              contact_phone: `52-${values.contact_phone}`,
            }),
          }),
        }),
      );
    } else {
      const errorData = {
        ...(values.client_id && { client_id: values.client_id || 0 }),
        ...(values.contact_phone && {
          contact_phone: `52-${values.contact_phone}`,
        }),
        ...(values.contact_name && {
          contact_name: values.contact_name.trim(),
        }),
      };

      dispatch(
        updateAppointmentRequest({
          body: {
            ...(isValidateError && errorData),
            ...(!isValidateError && {
              client: {
                id: values.client_id || 0,
                type: values?.clientType || '',
              },
            }),
            staff_id: values.staff_id || 0,
            service_id: values.service_id || 0,
            from: DateHelper.toFormat(values.from, FORMAT_DATE),
            started_at: values.started_at || '',
          },
          id: values.id || 0,
        }),
      );
    }
  };

  const handlerClose = () => {
    setIsDrawer(false);
    dispatch(resetValidationError());
    if (setEventData && setEventDate) {
      setEventData(null);
      setEventDate(new Date());
    }

    if (isAddAppointment) {
      basket?.items.forEach((item) => {
        if (!String(item?.id).includes('new')) {
          dispatch(deleteBasketServiceRequest(Number(item?.id)));
        }
      });
    }
  };

  const renderTitle = useCallback(() => {
    if (isValidateError) {
      return t('MANAGER.STAFF.EDIT_APPOINTMENT');
    }
    if (isAddAppointment) {
      return t('MANAGER.STAFF.NEW_APPOINTMENT');
    }

    return t('MANAGER.STAFF.EDIT_APPOINTMENT');
  }, [isAddAppointment, isValidateError, t]);

  const setDisableBtn = useCallback(() => {
    if (isValidateError) {
      return !values.id;
    }

    if (isAddAppointment && values.client_id) {
      return !(
        values.clientName &&
        values.client_id &&
        values.service_id &&
        values.serviceName &&
        values.started_at &&
        values.staff_id &&
        values.staffName
      );
    }

    if (isAddAppointment) {
      return !(
        values.contact_name &&
        values.contact_phone?.replace(/\D+/g, '').length === 10 &&
        values.service_id &&
        values.serviceName &&
        values.started_at &&
        values.staff_id &&
        values.staffName
      );
    }

    return false;
  }, [
    isAddAppointment,
    isValidateError,
    values.clientName,
    values.client_id,
    values.contact_name,
    values.contact_phone,
    values.id,
    values.serviceName,
    values.service_id,
    values.staffName,
    values.staff_id,
    values.started_at,
  ]);

  useEffect(() => {
    return () => {
      setValues(INITIAL_VALUES);
      dispatch(clearStore());
      dispatch(setRepeatRequest(false));
      setIsSaved(false);
      if (setEventData) {
        setEventData(null);
      }
      if (setSelectDate) {
        setSelectDate({ start: '', end: '' });
      }
    };
  }, [dispatch, setEventData, setSelectDate]);

  useEffect(() => {
    if (isValidateError && !appointmentsError.length) {
      setIsDrawer(false);
    }
  }, [setIsDrawer, isValidateError, appointmentsError.length]);

  useEffect(() => {
    if (isAppointmentAction && !appointmentsError.length) {
      setIsDrawer(false);

      if (pathname === MANAGER_APPOINTMENTS) {
        dispatch(getAppointmentRequest({ query: storeQuery }));
      }
      dispatch(clearStore());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isAppointmentAction,
    isSaved,
    serviceList,
    values.service_id,
    isValidateError,
  ]);

  const getDisable = () => {
    const dateFrom = DateHelper.toFormat(values.from, FORMAT_DATE);
    const dateStartEvent = new Date(`${dateFrom} ${values?.started_at}`);

    return new Date(dateStartEvent).valueOf() < new Date().valueOf();
  };

  useEffect(() => {
    if ((errors as { [key: string]: string })?.from || errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage || (errors as { [key: string]: string })?.from,
          type: 'error',
          placement: 'right',
        }),
      );
    }
  }, [(errors as { [key: string]: string })?.from, errorMessage]);

  return (
    <div className={styles.outsideWrapp} onClick={(e) => handleClose(e)}>
      <div className={styles.drawerWrapp}>
        <div>
          {isValidateError && (
            <AppointmentSidearConflicts
              setIsBack={setIsBack}
              setIsDrawer={setIsDrawer}
              setValues={setValues}
              setEventData={setEventData}
              setEventDate={setEventDate}
            />
          )}

          {(((isTablet || isMobile) && (isBack || !isValidateError)) ||
            !(isTablet || isMobile)) && (
            <div
              className={cn(styles.appointment, {
                [styles.appointmentMobile]: isBack,
              })}
            >
              <div className={styles.header}>
                <div>
                  {isBack && (
                    <span onClick={() => setIsBack(false)}>
                      <BackSVG />
                    </span>
                  )}

                  <h2>{renderTitle()}</h2>
                </div>

                <div className={styles.close} onClick={handlerClose}>
                  <DeleteSVG />
                </div>
              </div>

              <Spin spinning={isUpdateLoading}>
                <div className={styles.contentWrapp}>
                  {isValidateError ? (
                    <UIAppointmentsSidebarValidate
                      isValidateError={isValidateError}
                      values={values}
                      setValues={setValues}
                    />
                  ) : null}

                  {isAddAppointment ? (
                    <UIAppointmendarSidebarAdd
                      selectDate={selectDate}
                      setSelectDate={setSelectDate}
                      setTabMode={setTabMode}
                      headerDate={headerDate}
                      mode={mode}
                      setIsDrawer={setIsDrawer}
                      eventDate={eventDate}
                    />
                  ) : (
                    <UIAppointmendarSidebarContent
                      eventData={eventData}
                      eventDate={eventDate}
                      setValues={setValues}
                      values={values}
                      selectDate={selectDate}
                      setSelectDate={setSelectDate}
                      mode={mode}
                      headerDate={headerDate}
                      setTabMode={setTabMode}
                      isAddAppointment={isAddAppointment}
                      isDisabled={getDisable()}
                      handleSave={handleSave}
                      handleDelete={handleDelete}
                      isBtnDisabled={setDisableBtn()}
                    />
                  )}
                </div>
              </Spin>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(UIAppointmentsSidebar);

// eslint-disable @typescript-eslint/no-unsafe-return

import { FC, useCallback, useEffect, useState } from 'react'

import { Spin, Upload, UploadFile } from 'antd'
import { RcFile } from 'antd/lib/upload'
import classNames from 'classnames'
import update from 'immutability-helper'
import { isMobile } from 'react-device-detect'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useDispatch } from 'react-redux'

import { ReactComponent as UploadSVG } from 'assets/images/common/redesign/uploadImage.svg'
import { FullMedia } from 'entities/common'
import { sortRequest } from 'store/Sort/Sort.action'
import WidgetModalImageEditor from 'widgets/Modal/ImageEditor'

import { FileListType, PropsType } from './index.type'
import styles from './style.module.scss'
import UploadMoveItem from './UploadMoveItem'

const getSrcFromFile = (file: UploadFile<any>) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.readAsDataURL(file.originFileObj as RcFile)
    reader.onload = () => resolve(reader.result)
  })
}

const UIUploadMove: FC<PropsType> = ({
  images,
  setValues,
  values,
  startImage,
  isLoading,
  setImageList,
  className,
  linkType,
  widthRatio = 1,
  heightRatio = 1,
}) => {
  const [fileList, setFileList] = useState<FileListType[]>([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (setImageList) {
      dispatch(setImageList(startImage as FullMedia[]))
    }
  }, [startImage, dispatch, setImageList])

  useEffect(() => {
    if (images) {
      setFileList([
        ...fileList,
        ...images.map((image) => ({ id: image?.id, url: image?.path })),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])

  useEffect(() => {
    if (setValues && values && images) {
      setValues({
        ...values,
        images: [
          ...(fileList.map((image) => image?.id) as number[]),
          ...images.map((image) => image?.id),
        ],
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, setValues])

  useEffect(() => {
    if (startImage) {
      setFileList(startImage?.map((i) => ({ id: i.id, url: i.path })))
    }
  }, [startImage])

  const onRemove = (file: FileListType) => {
    if (file) {
      setFileList(fileList?.filter((image) => image?.id !== file.id))
    }

    if (setValues && values && file) {
      setValues({
        ...values,
        images: values.images?.filter((id: number) => id !== file.id),
      })
    }
  }

  const handleUpload = async (file: UploadFile<any>) => {
    const src = file.url || ((await getSrcFromFile(file)) as string)
    const imgWindow = window.open(src)

    if (imgWindow) {
      const image = new Image()

      image.src = src
      imgWindow.document.write(image.outerHTML)
    } else {
      window.location.href = src
    }
  }

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = fileList[dragIndex]
      const valueDragRow = values?.images?.[dragIndex]

      if (setValues && values?.images?.length) {
        setValues({
          ...values,
          images: update(values.images as any, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, valueDragRow],
            ],
          }),
        })
      }

      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      )

      dispatch(
        sortRequest({
          name: 'image',
          idx: update(fileList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          }).map((i) => i?.id || 0),
        }),
      )
    },
    [dispatch, fileList, setValues, values],
  )

  const uploadButton = (
    <div className={styles.uploadButton}>
      {isLoading && <Spin />}
      {!isLoading && <UploadSVG />}
    </div>
  )

  return (
    <div>
      <div className={classNames(styles.wrapper, className)}>
        <div className={styles.item}>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <WidgetModalImageEditor
              widthRatio={widthRatio}
              heightRatio={heightRatio}
              linkType={linkType}
              isArray={true}
            >
              <Upload
                accept=".jpg, .jpeg"
                listType="picture-card"
                fileList={fileList as unknown as UploadFile[]}
                customRequest={(file) => handleUpload(file.file as RcFile)}
                onRemove={(file) => onRemove(file as unknown as FileListType)}
                itemRender={(originNode, file, currFileList) => (
                  <UploadMoveItem
                    originNode={originNode}
                    file={file}
                    fileList={currFileList}
                    moveRow={moveRow}
                  />
                )}
              >
                {fileList?.length >= 15 ? null : uploadButton}
              </Upload>
            </WidgetModalImageEditor>
          </DndProvider>
        </div>
      </div>
    </div>
  )
}

export default UIUploadMove

import { FC, Fragment } from 'react';

/* eslint-disable */
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { STANDART_DATE } from 'utils';

import { EventTime } from '../index.type';
import { PropsType } from './index.type';

const StaffCalendarEvent: FC<PropsType> = ({ eventInfo }) => {
  const { t } = useTranslation();

  const extendedProps = eventInfo.event?.extendedProps;
  let startEventTime = 0;
  let endEventTime = 0;

  const FULL_DAY_MINS = 1440;
  const HOUR_MINS = 60;
  const HOUR_DAY = 24;
  const PX_IN_MINS = 1.6181;
  const EVENT_MIN =
    (new Date(`${STANDART_DATE} ${extendedProps?.work?.to}`).valueOf() -
      new Date(`${STANDART_DATE} ${extendedProps?.work?.from}`).valueOf()) /
    60000;

  const mainBreakArray: {
    duration: string;
    durationMin: number;
    margin: number;
  }[][] = [];

  const parserData = (breakArr: EventTime[]) => {
    const indentArray: {
      duration: string;
      durationMin: number;
      margin: number;
    }[] = breakArr
      ?.map((item) => {
        const timeSplit = item?.from?.split(':');

        startEventTime = +(item?.work?.from?.split(':')[0] || 0) * HOUR_MINS;
        endEventTime =
          (HOUR_DAY - +(item?.work?.to?.split(':')[0] || 0)) * HOUR_MINS;

        const durationMin =
          (new Date(`${STANDART_DATE} ${item.to}`).valueOf() -
            new Date(`${STANDART_DATE} ${item.from}`).valueOf()) /
          60000;

        return {
          durationMin: durationMin < 60 ? 60 : durationMin,
          duration: item.duration || '',
          margin: timeSplit
            ? +timeSplit?.[0] * HOUR_MINS + +timeSplit?.[1] - startEventTime
            : 0,
        };
      })
      .filter((i) => i.duration);

    indentArray?.sort(
      (a, b) => parseFloat(String(a?.margin)) - parseFloat(String(b?.margin)),
    );

    const sortArrNum = (
      obj: { duration: string; durationMin: number; margin: number }[],
      index: number,
    ) => {
      const oldArr = [];

      for (let i = 0; i < obj?.length; i++) {
        if (!mainBreakArray?.[index]?.length && obj?.[i]) {
          mainBreakArray[index] = [obj?.[i]];
        } else if (
          obj?.[i]?.margin -
            mainBreakArray?.[index][mainBreakArray?.[index]?.length - 1]
              ?.margin >
          mainBreakArray?.[index][mainBreakArray?.[index]?.length - 1]
            ?.durationMin
        ) {
          mainBreakArray?.[index]?.push(obj?.[i]);
        } else {
          oldArr?.push(obj?.[i]);
        }
      }
      if (oldArr?.length) {
        sortArrNum(oldArr, index + 1);
      }
    };

    sortArrNum(indentArray, 0);
  };

  parserData(extendedProps?.break);

  return (
    <div className="eventContent__custom">
      <div className={cn('eventContentDay', { dayNotWrapp: EVENT_MIN < 130 })}>
        {eventInfo.event?.title}
        <div className="eventContent__day">
          {extendedProps?.duration?.work &&
            `${extendedProps?.duration?.work} ${
              extendedProps?.duration?.break
                ? `+ ${extendedProps?.duration?.break} ${t(
                    'COMMON.BREAK',
                  ).toLowerCase()}`
                : ''
            }`}
        </div>
      </div>

      <div className="eventWrapp">
        {mainBreakArray.map((item, index) => {
          if (index < 2 && eventInfo.view.type === 'resourceTimelineDay') {
            return (
              <div
                key={index}
                className="eventContent__week"
                style={{
                  gridTemplateColumns: `repeat(${
                    FULL_DAY_MINS - startEventTime - endEventTime
                  }, 1fr)`,
                }}
              >
                <div
                  className="eventContentWeek__breakWrapp"
                  style={{
                    gridColumnEnd: item[0]?.margin < 0 ? 1 : item[0]?.margin,
                  }}
                />

                {item.map(({ margin, duration, durationMin }, index) => {
                  const marginLeft =
                    margin < 0 ? `${margin * PX_IN_MINS}px` : 0;
                  const marginRight =
                    FULL_DAY_MINS - startEventTime - endEventTime <
                    margin + durationMin
                      ? `-${
                          (margin +
                            durationMin -
                            (FULL_DAY_MINS - startEventTime - endEventTime)) *
                          PX_IN_MINS
                        }px`
                      : 0;
                  const gridColumnStart =
                    (margin < 0 ? 0 : margin) + durationMin;
                  const gridColumnEnd =
                    +item[index + 1]?.margin ||
                    FULL_DAY_MINS + 1 - startEventTime - endEventTime;

                  return (
                    <Fragment key={index}>
                      <div
                        style={{
                          marginLeft: marginLeft,
                          marginRight: marginRight,
                          gridColumnStart: margin < 0 ? 1 : margin,
                          gridColumnEnd: margin + durationMin,
                        }}
                        className={cn('eventContentWeek__break', {
                          end__break:
                            FULL_DAY_MINS - startEventTime - endEventTime <
                            margin + durationMin,
                        })}
                      >
                        {t('COMMON.BREAK')} ({duration})
                      </div>
                      <div
                        style={{
                          gridColumnStart: gridColumnStart,
                          gridColumnEnd: gridColumnEnd,
                        }}
                      />
                    </Fragment>
                  );
                })}
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
};

export default StaffCalendarEvent;

import { memo, FC } from 'react'

import { Form } from 'antd'

import { UITextArea, UIUploadFile } from 'ui'
import UITextInput from 'ui/TextInput'
import { OnUploadFileChange } from 'ui/UploadFile/index.type'

import {
  TemplateFieldsPropsType,
  TYPE_FILE,
  TYPE_INPUT,
  TYPE_TEXTAREA,
} from './index.type'

const TemplateField: FC<TemplateFieldsPropsType> = ({
  label,
  name,
  value,
  onChange,
  selectedName,
  type = TYPE_INPUT,
  widthRatio,
  heightRatio,
}) => (
  <Form.Item label={label}>
    {type === TYPE_INPUT && (
      <UITextInput value={value} name={name} onChange={onChange} />
    )}

    {type === TYPE_FILE && (
      <UIUploadFile
        name={name}
        selectedName={selectedName}
        onChange={onChange as unknown as OnUploadFileChange}
        widthRatio={widthRatio}
        heightRatio={heightRatio}
      />
    )}

    {type === TYPE_TEXTAREA && (
      <UITextArea value={value} name={name} onChange={onChange} rows={6} />
    )}
  </Form.Item>
)

export default memo(TemplateField)

import { FC, useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { addTimeShiftRequest } from 'store/Manager/Shift/Shift.action'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICustomModal, UICheckbox } from 'ui'
import { FORMAT_DATE, FORMAT_TIME_HOUR_WITH_MIN, STANDART_DATE } from 'utils'
import { DateHelper } from 'utils/helpers'

import { INITIAL_VALUES } from './constant'
import { PropsType, ValuesTimeOfType } from './index.type'
import WidgetModalAddTimeOffPicker from './Picker'
import styles from './style.module.scss'

const WidgetModalAddTimeOff: FC<PropsType> = ({ staffId, isClickStaff }) => {
  const { staffs, staff } = useSelector((state: IRootState) => state.staff)
  const { isShiftAction } = useSelector((state: IRootState) => state.shift)
  const { isRepeatRequest } = useSelector(
    (state: IRootState) => state.appointments,
  )

  const [isAll, setIsAll] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [isRepeat, setIsRepeat] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [values, setValues] = useState<ValuesTimeOfType>(INITIAL_VALUES)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const objToRequest = useMemo(
    () => ({
      staff_ids: values.staff_ids,
      from: DateHelper.toFormat(values.from as Date, FORMAT_DATE),
      to: isRepeat
        ? DateHelper.toFormat(values.to as Date, FORMAT_DATE)
        : DateHelper.toFormat(values.from as Date, FORMAT_DATE),
      ...(values.break?.from && {
        break: {
          to: (values?.break?.to as string)?.slice(0, 5),
          from: DateHelper.toFormat(
            new Date(`${STANDART_DATE} ${values?.break?.from}`),
            FORMAT_TIME_HOUR_WITH_MIN,
          ),
        },
      }),
      ...(values.break?.to && {
        break: {
          from: (values?.break?.from as string)?.slice(0, 5),
          to: DateHelper.toFormat(
            new Date(`${STANDART_DATE} ${values?.break?.to}`),
            FORMAT_TIME_HOUR_WITH_MIN,
          ),
        },
      }),
    }),
    [
      isRepeat,
      values?.break?.from,
      values?.break?.to,
      values.from,
      values.staff_ids,
      values.to,
    ],
  )

  const handleSave = () => {
    dispatch(addTimeShiftRequest(objToRequest))
  }

  const handleBack = () => {
    dispatch(hideModal())
    setValues(INITIAL_VALUES)
  }

  const handlerChangeAll = () => {
    setIsCheckAll(true)
    if (staffs) {
      setValues({ ...values, staff_ids: staffs?.map((item) => item.id) })
    }
  }

  const handleChange = (id: number) => {
    setIsCheckAll(false)

    if (values?.staff_ids?.find((idx) => idx === id)) {
      setValues({
        ...values,
        staff_ids: values?.staff_ids.filter((idx) => idx !== id),
      })
    } else {
      setValues({ ...values, staff_ids: [...values.staff_ids, id] })
    }
  }

  const renderTitle = () => {
    return (
      <>
        {t('MANAGER.STAFF.ADD_TIME_OFF')} - <span> {staff?.name}</span>
      </>
    )
  }

  useEffect(() => {
    if (isRepeatRequest) {
      dispatch(addTimeShiftRequest(objToRequest))
    }
  }, [dispatch, isRepeatRequest, objToRequest])

  useEffect(() => {
    if (isAll) {
      setValues({ ...values, break: { from: null, to: null } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAll])

  useEffect(() => {
    if (!isRepeat) {
      setValues({ ...values, to: values.from })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRepeat])

  useEffect(() => {
    if (
      isAll &&
      !values?.break?.from &&
      !values?.break?.to &&
      !values.staff_ids.length
    ) {
      setIsDisable(true)
    } else if (!isAll && (!values.staff_ids.length || !staff)) {
      setIsDisable(true)
    } else if (
      values.from &&
      values.to &&
      values.from.valueOf() > values.to.valueOf()
    ) {
      setIsDisable(true)
    } else if (
      (values?.break?.from && !values?.break?.to) ||
      (values?.break?.to && !values?.break?.from)
    ) {
      setIsDisable(true)
    } else if (!isAll && !values?.break?.to && !values?.break?.from) {
      setIsDisable(true)
    } else if (
      values?.break?.to &&
      values?.break?.from &&
      values?.break?.from >= values?.break?.to
    ) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
  }, [
    isAll,
    staff,
    values?.break?.from,
    values?.break?.to,
    values.from,
    values.staff_ids.length,
    values.to,
  ])

  useEffect(() => {
    if (staffId) {
      setValues({ ...values, staff_ids: [staffId] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffId])

  useEffect(() => {
    if (isShiftAction) {
      setValues(INITIAL_VALUES)
    }
  }, [isShiftAction])

  return (
    <UICustomModal
      title={isClickStaff ? renderTitle() : t('MANAGER.STAFF.ADD_TIME_OFF')}
    >
      <div className={styles.wrapper}>
        <WidgetModalAddTimeOffPicker
          isAll={isAll}
          isRepeat={isRepeat}
          setIsAll={setIsAll}
          setIsRepeat={setIsRepeat}
          values={values}
          setValues={setValues}
        />
        {!isClickStaff && (
          <div className={styles.checkboxWrapp}>
            <div className={styles.line}></div>
            <p>{t('MANAGER.STAFF.FOR_SELECTED')}</p>
            <UICheckbox
              onChange={handlerChangeAll}
              checked={
                isCheckAll || staffs?.length === values?.staff_ids.length
              }
            >
              {t('MANAGER.STAFF.ALL')}
            </UICheckbox>
            <div className={styles.listCheckbox}>
              {staffs?.map((item) => {
                return (
                  <UICheckbox
                    checked={!!values.staff_ids?.find((idx) => idx === item.id)}
                    onChange={() => handleChange(item.id)}
                    key={item.id}
                  >
                    {item.name}
                  </UICheckbox>
                )
              })}
            </div>
          </div>
        )}
        <div className={styles.line}></div>
        <div className={styles.btn}>
          <UIButton
            handler={handleBack}
            type="outline"
            label={t('ADMIN.BUTTON.CANCEL')}
          />
          <UIButton
            handler={handleSave}
            disabled={isDisable}
            label={t('ADMIN.BUTTON.SAVE')}
          />
        </div>
      </div>
    </UICustomModal>
  )
}

export default WidgetModalAddTimeOff

import {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  Fragment,
} from 'react'

import { RightOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg'
import useDebounce from 'hooks/useDebounce'
import useResponsive from 'hooks/useResponsive'
import {
  getAllServiceRequest,
  getCategoriesRequest,
  getServicesByCategoryRequest,
} from 'store/Manager/Services'
import {
  resetStore,
  setIsStartStaff,
  updateStaffRequest,
} from 'store/Manager/Staff'
import { hideModal } from 'store/Modal'
import { IRootState } from 'store/Root.reducer'
import { UIButton, UICheckbox, UICustomModal } from 'ui'
import UITextInput from 'ui/TextInput'

import styles from './style.module.scss'
import StaffCheckbox from '../../../modules/manager/staff/Checkbox'

type PropsType = {
  setValues: Dispatch<SetStateAction<number[]>>
  values?: number[]
}

const WidgetModalAddService: FC<PropsType> = ({ setValues, values }) => {
  const { staff, isLoading, isStaffModalUpdate } = useSelector(
    (state: IRootState) => state.staff,
  )

  const {
    categories,
    fullService,
    allService,
    isCategoryLoading,
    isOneServiceLoading,
  } = useSelector((state: IRootState) => state.service)

  const [isCheckAll, setIsCheckAll] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [active, setActive] = useState<number | null>(null)
  const [localValues, setLocalValues] = useState<number[]>([])
  const debouncedValue = useDebounce(searchText, 500)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isMobile, isTablet } = useResponsive()

  const handleSave = (type: string) => {
    if (staff) {
      dispatch(
        updateStaffRequest({
          body: {
            name: staff?.name,
            description: staff?.description,
            position: staff?.position,
            phone: staff?.phone,
            avatar: staff?.avatar?.id,
            color: staff?.color,
            services: localValues,
          },
          id: staff?.id,
          type,
        }),
      )
      dispatch(setIsStartStaff(false))
    }
  }

  const handlerChangeAll = () => {
    setIsCheckAll(true)

    if (setValues) {
      const arrService = allService?.map((item) => item.id)

      setValues(arrService)
      setLocalValues(arrService)
    }
  }

  const handlerClearAll = () => {
    setActive(null)
    setIsCheckAll(false)
    dispatch(getAllServiceRequest())

    if (setValues) {
      setValues([])
      setLocalValues([])
    }
  }

  const handlerChange = (id: number) => {
    setIsCheckAll(false)

    if (setValues && localValues) {
      if (localValues?.find((idx) => idx === id)) {
        const arrIdx = localValues?.filter((idx) => idx !== id)

        setValues(arrIdx)
        setLocalValues(arrIdx)
      } else {
        setValues([...localValues, id])
        setLocalValues([...localValues, id])
      }
    }
  }

  const handlerCategoryClick = (id: number) => {
    setActive(id)
    dispatch(getServicesByCategoryRequest(id, debouncedValue))
  }

  useEffect(() => {
    dispatch(getCategoriesRequest('profile'))
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllServiceRequest(debouncedValue))
  }, [debouncedValue, dispatch])

  useEffect(() => {
    if (isStaffModalUpdate) {
      dispatch(hideModal())
      dispatch(resetStore())
    }
  }, [dispatch, isStaffModalUpdate])

  useEffect(() => {
    if (values) {
      setLocalValues(values)
    }
  }, [values])

  const renderModalTitle = () => {
    return (
      <div className={styles.header}>
        <h2>
          {staff?.name} - {t('MANAGER.STAFF.MODAL.SERVICE.TITLE')}
        </h2>

        <UITextInput
          isSearch
          icon={<SearchSVG />}
          placeholder={t('MANAGER.STAFF.SEARCH_SERVICES')}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
    )
  }

  return (
    <UICustomModal title={renderModalTitle()}>
      <Spin spinning={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.contentWrapp}>
            <div className={styles.categories}>
              <Spin spinning={isCategoryLoading}>
                <h2>{t('MANAGER.STAFF.CATEGORIES')}</h2>

                {categories?.map((item, index) => (
                  <div
                    key={index}
                    className={cn(styles.wrapperCategory, {
                      [styles.activeBorder]: active === item.id,
                    })}
                    onClick={() => handlerCategoryClick(item.id)}
                  >
                    <div className={styles.categoryNameWrapp}>
                      <div
                        className={cn(styles.categoryName, {
                          [styles.activeName]: active === item.id,
                        })}
                      >
                        {item.name}
                      </div>
                    </div>
                    <RightOutlined />
                  </div>
                ))}
              </Spin>
            </div>

            <div className={styles.services}>
              <Spin spinning={isOneServiceLoading}>
                <div className={styles.checkboxTitle}>
                  <h2>{t('MANAGER.STAFF.ALL_SERVICES')}</h2>
                  <UICheckbox
                    onChange={handlerChangeAll}
                    checked={
                      isCheckAll || fullService?.length === localValues?.length
                    }
                  >
                    {t('MANAGER.STAFF.CHECK_ALL')}
                  </UICheckbox>
                </div>

                <div className={styles.checkboxWrapp}>
                  {allService?.map(({ name, duration, price, color, id }) => {
                    return (
                      <Fragment key={id}>
                        {isMobile || isTablet ? (
                          <div className={styles.mobileCheckboxWrapp}>
                            <StaffCheckbox
                              key={id}
                              name={name}
                              time={duration}
                              price={+price.price}
                              oldPrice={price.old_price}
                              color={color}
                              isCheckAll={isCheckAll}
                              checked={!!localValues?.find((idx) => idx === id)}
                              onChange={() => handlerChange(id)}
                            />
                          </div>
                        ) : (
                          <StaffCheckbox
                            key={id}
                            name={name}
                            time={duration}
                            price={+price.price}
                            oldPrice={price.old_price}
                            color={color}
                            isCheckAll={isCheckAll}
                            checked={!!localValues?.find((idx) => idx === id)}
                            onChange={() => handlerChange(id)}
                          />
                        )}
                      </Fragment>
                    )
                  })}
                </div>
              </Spin>
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.btn}>
            <UIButton
              label={t('MANAGER.BUTTON.CLEAR_ALL')}
              handler={handlerClearAll}
              type="outline"
            />

            <UIButton
              label={t('MANAGER.BUTTON.SELECT', {
                amount: isCheckAll
                  ? '(all)'
                  : (localValues?.length && `(${localValues.length})`) || '',
              })}
              handler={() => handleSave('modal')}
            />
          </div>
        </div>
      </Spin>
    </UICustomModal>
  )
}

export default WidgetModalAddService

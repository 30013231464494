import { FC, memo, useContext } from 'react'

import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { ReactComponent as LogoutIcon } from 'assets/images/common/newSidebar/logout.svg'
import { ReactComponent as ExclamatoryIcon } from 'assets/images/common/redesign/exclamatory.svg'
import { ReactComponent as LogoIcon } from 'assets/images/common/redesign/plugAvatar.svg'
import { ManagerSidebarContext } from 'context'
import { logoutRequest } from 'store/Auth'
import { UICustomBadge } from 'ui'
import UIBurger from 'ui/Burger'
import { CLIENT_LINK } from 'utils'

import style from './style.module.scss'

type PropsType = { className?: string }

const ManagerHeader: FC<PropsType> = ({ className }) => {
  const { isMenuOpen, onToggleMenu } = useContext(ManagerSidebarContext)

  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logoutRequest())
  }

  const handleLink = () => {
    window.location.href = CLIENT_LINK
  }

  return (
    <div className={classNames(style.managerHeader, className)}>
      <UICustomBadge text={!isMenuOpen && <ExclamatoryIcon />} offset={[15, 5]}>
        <UIBurger isOpen={isMenuOpen} onClick={onToggleMenu} />
      </UICustomBadge>

      <LogoIcon className={style.logo} onClick={handleLink} />

      <LogoutIcon className={style.logout} onClick={handleLogout} />
    </div>
  )
}

export default memo(ManagerHeader)

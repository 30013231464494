import { Dispatch, FC, memo, SetStateAction, useCallback } from 'react';

import FullCalendar from '@fullcalendar/react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { StaffType } from 'entities/manager/staff';

import { modeShiftTab, modeTab } from '../constant';
import { ModeShiftType, ModeType } from '../index.type';
import styles from '../style.module.scss';
import { DateHelper } from 'utils/helpers';

type PropsType = {
  currentMode: string;
  isShift?: boolean;
  refCurrent?: FullCalendar;
  setCurrentMode: Dispatch<SetStateAction<ModeType | ModeShiftType>>;
  setMode?: Dispatch<SetStateAction<ModeType>>;
  setRangeDate: Dispatch<
    SetStateAction<{
      end: Date;
      start: Date;
    }>
  >;
  setSelectValue: Dispatch<SetStateAction<number>>;
  staffs: StaffType[] | null;
};

const UICalendarHeaderMode: FC<PropsType> = ({
  isShift,
  currentMode,
  setMode,
  refCurrent,
  setCurrentMode,
  setRangeDate,
  setSelectValue,
  staffs,
}) => {
  const [searchParams] = useSearchParams();

  const getApi = refCurrent?.getApi();

  const handlerModeClick = useCallback(
    (id: ModeType | ModeShiftType) => {
      if (setMode) {
        setMode(id as ModeType);
      }
      if (refCurrent) {
        refCurrent?.getApi().changeView(id);
        setCurrentMode(id);
        setRangeDate({
          start: new Date(DateHelper.getUnixDate(new Date())),
          end: new Date(DateHelper.getUnixDate(new Date())),
        });
        getApi?.gotoDate(new Date(DateHelper.getUnixDate(new Date())));
      }
      if (!isShift && staffs && id !== ModeType.DAY && id !== ModeType.MONTH) {
        if (searchParams.get('staffId')) {
          setSelectValue(searchParams.get('staffId') as unknown as number);
        } else {
          setSelectValue(staffs?.[0].id);
        }
      } else if (searchParams.get('staffId')) {
        setSelectValue(searchParams.get('staffId') as unknown as number);
      } else {
        setSelectValue(0);
      }
    },
    [
      getApi,
      isShift,
      refCurrent,
      searchParams,
      setCurrentMode,
      setMode,
      setRangeDate,
      setSelectValue,
      staffs,
    ],
  );

  return (
    <div className={styles.mode}>
      {(isShift ? modeShiftTab : modeTab).map(({ id, name }) => (
        <div
          key={id}
          className={classNames({ [styles.active]: currentMode === id })}
          onClick={() => handlerModeClick(id)}
        >
          {name}
        </div>
      ))}
    </div>
  );
};

export default memo(UICalendarHeaderMode);

import { FC } from 'react'

import useResponsive from 'hooks/useResponsive'
import {
  FORMAT_DATE_WITH_YEAR,
  FORMAT_DATE_CUSTOM,
  FORMAT_DATE_MONTH_WITH_YEAR,
  FORMAT_DATE_MIN_MONTH_WITH_YEAR,
  FORMAT_DATE_MONTH_DAY,
} from 'utils'
import { DateHelper } from 'utils/helpers'

import styles from '../style.module.scss'

type PropsType = {
  date: Date
  type: 'week' | 'day' | 'month'
}

const UICalendarHeaderDateTitle: FC<PropsType> = ({ date, type }) => {
  const { isTablet, isMobile, isIpad } = useResponsive()

  const SECONDS = new Date().getTimezoneOffset() * 60 * 1000

  const newDate = new Date(date).valueOf() + SECONDS

  const dateFormat =
    (isMobile && FORMAT_DATE_MONTH_DAY) ||
    (isTablet || isIpad ? FORMAT_DATE_CUSTOM : FORMAT_DATE_WITH_YEAR)

  const renderTitle = () => {
    switch (type) {
      case 'week':
        return (
          <>
            {DateHelper.toFormat(DateHelper.startOfWeek(newDate), dateFormat)}
            <br />
            {DateHelper.toFormat(DateHelper.endOfWeek(newDate), dateFormat)}
          </>
        )
      case 'month':
        return (
          <>
            {DateHelper.toFormat(
              DateHelper.startMonth(newDate),
              isTablet || isMobile
                ? FORMAT_DATE_MIN_MONTH_WITH_YEAR
                : FORMAT_DATE_MONTH_WITH_YEAR,
            )}
          </>
        )
      default:
        return <>{DateHelper.toFormat(newDate, dateFormat)}</>
    }
  }

  return (
    <div className={styles.dateTitle}>
      <span className={styles.weekTitle}>{renderTitle()}</span>
    </div>
  )
}

export default UICalendarHeaderDateTitle

import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'

import { Switch } from 'antd'
import { useTranslation } from 'react-i18next'

import UIAntTimePicker from 'ui/Ant/TimePicker'
import { FORMAT_TIME_SHORT, STANDART_DATE } from 'utils'
import { DateHelper, WeekTimeHelper } from 'utils/helpers'
import {
  SalonDataType,
  SalonTime,
  StateTimeType,
} from 'views/manager/ProfileSalon/index.type'

import styles from './style.module.scss'

type PropsType = {
  customize?: boolean
  day: number
  setIsHourError?: Dispatch<SetStateAction<boolean>>
  setValues?: (value: React.SetStateAction<SalonDataType>) => void
  text: string
  values?: SalonDataType
}

const UIBusinessHours: FC<PropsType> = ({
  text,
  customize = false,
  day,
  values,
  setValues,
  setIsHourError,
}) => {
  const { t } = useTranslation()
  const [isBreak, setIsBreak] = useState(false)
  const [isWeek, setIsWeek] = useState(false)

  const dayWeek = [1, 2, 3, 4, 5]

  const valueTime = values?.times as SalonTime[]

  const onChange = (time: Date | null, name: string, key: string) => {
    if (customize && time && setValues) {
      const newValues = { ...values, times: { ...valueTime } }

      dayWeek.map((index) => {
        newValues.times[index] = {
          ...valueTime[index],
          [name]: {
            ...((valueTime as unknown as StateTimeType)?.[index]?.[name] || {}),
            [key]: DateHelper.toFormat(time, FORMAT_TIME_SHORT),
          },
        }

        return null
      })
      setValues(newValues as SalonDataType)
    } else if (day && time && setValues && values?.times) {
      setValues((prev) => ({
        ...prev,
        times: {
          ...(prev.times as unknown as StateTimeType),
          [day]: {
            ...(prev.times as unknown as StateTimeType)?.[day],
            [name]: {
              ...((prev.times as unknown as StateTimeType)?.[day]?.[name] ||
                {}),
              [key]: DateHelper.toFormat(time, FORMAT_TIME_SHORT),
            },
          },
        },
      }))
    }

    return null
  }

  const onChangeSwitch = (name: string) => {
    const newValue = {
      ...values,
      times: {
        ...values?.times,
        [day]: {
          ...(values?.times as unknown as StateTimeType)?.[day],
        },
      },
    }

    const newValueTime = (newValue as unknown as StateTimeType).times

    if (setValues) {
      if (newValueTime && +Object.keys(newValueTime)[day - 1] === day) {
        if (name === 'time' && customize) {
          setIsWeek(!isWeek)
          dayWeek.forEach((index) => delete newValueTime[index])
        } else if (name === 'time' && !customize) {
          setIsWeek(!isWeek)
          setIsBreak(false)
          delete newValueTime[day]?.time
          delete newValueTime[day]?.break
        } else {
          setIsBreak(!isBreak)
          delete newValueTime[day]?.break
        }

        if (
          name === 'break' &&
          customize &&
          !!dayWeek.find((index) => newValueTime[index])
        ) {
          dayWeek.forEach((index) => {
            delete (newValue as unknown as StateTimeType).times[index].break
          })
        }
      }

      setValues(newValue as SalonDataType)
    }
  }

  const timeStart = (valueTime as unknown as StateTimeType)?.[+day]?.time
    ?.start as unknown as Date

  const timeEnd = (valueTime as unknown as StateTimeType)?.[+day]?.time
    ?.end as unknown as Date

  const breakStart = (valueTime as unknown as StateTimeType)?.[+day]?.break
    ?.start as unknown as Date

  const breakEnd = (valueTime as unknown as StateTimeType)?.[+day]?.break
    ?.end as unknown as Date

  useEffect(() => {
    if (
      valueTime &&
      !!valueTime[day] &&
      'break' in (valueTime as unknown as StateTimeType)[day]
    ) {
      setIsBreak(true)
    }
  }, [day, valueTime])

  useEffect(() => {
    if (
      valueTime &&
      !!valueTime[day] &&
      'time' in (valueTime as unknown as StateTimeType)[day]
    ) {
      setIsWeek(true)
    }
  }, [day, valueTime])

  useEffect(() => {
    if (setIsHourError) {
      setIsHourError(false)

      const time = valueTime as unknown as StateTimeType

      Object.keys(valueTime).forEach((item) => {
        const startT = time[+item]?.time?.start
        const endT = time[+item]?.time?.end
        const startB = time[+item]?.break?.start
        const endB = time[+item]?.break?.end

        if (
          (startT && startB && startT >= startB) ||
          (startB && endB && startB >= endB)
        ) {
          setIsHourError(true)
        } else if (startT && endT && startT >= endT) {
          setIsHourError(true)
        } else if ((startT && !endT) || (endT && !startT)) {
          setIsHourError(true)
        } else if (startB && startT && startB <= startT) {
          setIsHourError(true)
        } else if (endB && endT && endB >= endT) {
          setIsHourError(true)
        } else if ((startB && !endB) || (endB && !startB)) {
          setIsHourError(true)
        }
      })
    }
  }, [setIsHourError, valueTime])

  return (
    <div className={styles.wrapper}>
      <Switch
        checked={values?.times && !!valueTime[day] && isWeek}
        onChange={() => onChangeSwitch('time')}
        disabled={!(timeStart || timeEnd)}
      />

      <p className={styles.text}>{text}</p>
      <UIAntTimePicker
        placeholder={t('COMMON.SELECT')}
        format="HH:mm"
        value={timeStart && new Date(`${STANDART_DATE} ${timeStart}`)}
        clearIcon={false}
        showNow={false}
        inputReadOnly={true}
        disabledTime={() =>
          WeekTimeHelper.onDisabledTime(
            WeekTimeHelper.disabledTimeStart(timeEnd),
            WeekTimeHelper.disabledTimeStart(timeEnd, timeStart, true),
          )
        }
        onChange={(e: Date | null) => onChange(e, 'time', 'start')}
        minuteStep={5}
        {...(timeStart >= timeEnd && { status: 'error' })}
      />
      <div className={styles.dash}></div>
      <UIAntTimePicker
        placeholder={t('COMMON.SELECT')}
        className={styles.timepicker}
        format="HH:mm"
        clearIcon={false}
        value={timeEnd && new Date(`${STANDART_DATE} ${timeEnd}`)}
        showNow={false}
        inputReadOnly={true}
        onChange={(e: Date | null) => onChange(e, 'time', 'end')}
        disabledTime={() =>
          WeekTimeHelper.onDisabledTime(
            WeekTimeHelper.disabledTimeEnd(timeStart),
            WeekTimeHelper.disabledTimeEnd(timeStart, timeEnd, true),
          )
        }
        minuteStep={5}
        {...(timeStart >= timeEnd && { status: 'error' })}
      />

      <Switch
        checked={
          valueTime &&
          !!valueTime[day] &&
          isBreak &&
          !!(valueTime as unknown as StateTimeType)[day]?.time
        }
        onChange={() => onChangeSwitch('break')}
        disabled={!(timeStart && timeEnd)}
      />

      <div className={styles.addBreak}>
        {t('MANAGER.PROFILE_SALON.ADD_BREAK')}
      </div>
      {isBreak && (
        <UIAntTimePicker
          placeholder={t('COMMON.SELECT')}
          format="HH:mm"
          value={breakStart && new Date(`${STANDART_DATE} ${breakStart}`)}
          clearIcon={false}
          showNow={false}
          inputReadOnly={true}
          onChange={(e: Date | null) => onChange(e, 'break', 'start')}
          disabledTime={() =>
            WeekTimeHelper.onDisabledTime(
              WeekTimeHelper.disabledBreakStart(
                timeStart,
                timeEnd,
                breakEnd,
                breakStart,
              ),
              WeekTimeHelper.disabledBreakStart(
                timeStart,
                timeEnd,
                breakEnd,
                breakStart,
                true,
              ),
            )
          }
          disabled={!(timeStart && timeEnd)}
          minuteStep={5}
          {...(timeStart >= breakStart && { status: 'error' })}
        />
      )}

      {isBreak && (
        <>
          <div className={styles.dash}></div>
          <UIAntTimePicker
            placeholder={t('COMMON.SELECT')}
            className={styles.breakTimepicker}
            format="HH:mm"
            value={breakEnd && new Date(`${STANDART_DATE} ${breakEnd}`)}
            clearIcon={false}
            showNow={false}
            inputReadOnly={true}
            onChange={(e: Date | null) => onChange(e, 'break', 'end')}
            disabledTime={() =>
              WeekTimeHelper.onDisabledTime(
                WeekTimeHelper.disabledBreakEnd(
                  timeStart,
                  timeEnd,
                  breakStart,
                  breakEnd,
                ),
                WeekTimeHelper.disabledBreakEnd(
                  timeStart,
                  timeEnd,
                  breakStart,
                  breakEnd,
                  true,
                ),
              )
            }
            disabled={!(timeStart && timeEnd)}
            minuteStep={5}
            {...((breakStart >= breakEnd || breakEnd >= timeEnd) && {
              status: 'error',
            })}
          />
        </>
      )}
    </div>
  )
}

export default UIBusinessHours

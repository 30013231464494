import { ResponseFailureType } from 'store/Auth/Auth.type'

import {
  GET_MANAGER_SUBSCRIPTION_REQUEST,
  GET_MANAGER_SUBSCRIPTION_SUCCESS,
  GET_MANAGER_SUBSCRIPTION_FAILURE,
  CLEAR_MANAGER_SUBSCRIPTION_ERRORS,
  SUBSCRIBE_MANAGER_PLAN_REQUEST,
  SUBSCRIBE_MANAGER_PLAN_SUCCESS,
  SUBSCRIBE_MANAGER_PLAN_FAILURE,
  CANCEL_MANAGER_PLAN_REQUEST,
  CANCEL_MANAGER_PLAN_SUCCESS,
  CANCEL_MANAGER_PLAN_FAILURE,
  GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
  GET_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS,
  GET_MANAGER_HISTORY_SUBSCRIPTION_FAILURE,
  UPDATE_MANAGER_HISTORY_SUBSCRIPTION_FAILURE,
  UPDATE_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS,
  UPDATE_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
} from './Subscription.constant'
import {
  IGetManagerHistorySubscriptionResponse,
  ISubscriptionResponse,
  IUpdateManagerHistorySubscriptionPayload,
} from './Subscription.type'

export const getManagerSubscriptionRequest = () => ({
  type: GET_MANAGER_SUBSCRIPTION_REQUEST,
})

export const getManagerSubscriptionSuccess = (
  response: ISubscriptionResponse,
) => ({
  type: GET_MANAGER_SUBSCRIPTION_SUCCESS,
  payload: response,
})

export const getManagerSubscriptionFailure = (error: ResponseFailureType) => ({
  type: GET_MANAGER_SUBSCRIPTION_FAILURE,
  payload: error,
})

export const subscribeManagerPlanRequest = (id: number) => ({
  type: SUBSCRIBE_MANAGER_PLAN_REQUEST,
  payload: id,
})

export const subscribeManagerSuccess = () => ({
  type: SUBSCRIBE_MANAGER_PLAN_SUCCESS,
})

export const subscribeManagerFailure = (error: ResponseFailureType) => ({
  type: SUBSCRIBE_MANAGER_PLAN_FAILURE,
  payload: error,
})

export const cancelManagerPlanRequest = (id: number) => ({
  type: CANCEL_MANAGER_PLAN_REQUEST,
  payload: id,
})

export const cancelManagerSuccess = (data: unknown) => ({
  type: CANCEL_MANAGER_PLAN_SUCCESS,
  payload: data,
})

export const cancelManagerFailure = (error: ResponseFailureType) => ({
  type: CANCEL_MANAGER_PLAN_FAILURE,
  payload: error,
})

export const getManagerHistorySubscriptionRequest = (query?: string) => ({
  payload: query,
  type: GET_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
})

export const getManagerHistorySubscriptionSuccess = (
  data: IGetManagerHistorySubscriptionResponse,
) => ({
  type: GET_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS,
  payload: data,
})

export const getManagerHistorySubscriptionFailure = (
  error: ResponseFailureType,
) => ({
  type: GET_MANAGER_HISTORY_SUBSCRIPTION_FAILURE,
  payload: error,
})

export const updateManagerHistorySubscriptionRequest = (
  payload: IUpdateManagerHistorySubscriptionPayload,
) => ({
  payload,
  type: UPDATE_MANAGER_HISTORY_SUBSCRIPTION_REQUEST,
})

export const updateManagerHistorySubscriptionSuccess = () => ({
  type: UPDATE_MANAGER_HISTORY_SUBSCRIPTION_SUCCESS,
})

export const updateManagerHistorySubscriptionFailure = (
  error: ResponseFailureType,
) => ({
  type: UPDATE_MANAGER_HISTORY_SUBSCRIPTION_FAILURE,
  payload: error,
})

export const clearManagerSubsctiption = () => ({
  type: CLEAR_MANAGER_SUBSCRIPTION_ERRORS,
})

import { memo, FC } from 'react'

import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ReplyIcon } from 'assets/images/common/redesign/reply.svg'
import { IFeedbacksResponseData } from 'store/Manager/Feedbacks/Feedbakcs.type'
import { UIButton, UIRating } from 'ui'
import { FORMAT_DATE_WITH_YEAR } from 'utils'

import style from './style.module.scss'

type PropsType = Omit<IFeedbacksResponseData, 'comment' | 'id'> & {
  has_notification?: boolean
  message: string
  onReply: () => void
}

const FeedbackTableRow: FC<PropsType> = ({
  answer,
  message,
  onReply,
  rating,
  created_at,
  user,
  has_notification,
}) => {
  const { t } = useTranslation()

  return (
    <tr className={style.feedbackRow} onClick={onReply}>
      <td>
        {has_notification && (
          <p className={style.notification}>{t('COMMON.NEW')}</p>
        )}
      </td>
      <td className="text-nowrap">
        {format(new Date(created_at), FORMAT_DATE_WITH_YEAR)}
      </td>
      <td>{user?.name || ''}</td>
      <td>
        <UIRating rating={rating} />
      </td>
      <td className={style.message}>{message}</td>
      <td className={style.answer}>
        {!!answer && (
          <>
            <time>
              {format(new Date(answer?.created_at), FORMAT_DATE_WITH_YEAR)}
            </time>
            <p className="mt-10">{answer?.comment}</p>
          </>
        )}

        {!answer && (
          <>
            <p className="mb-10">{t('COMMON.NO_ANSWER')}</p>

            <UIButton
              handler={() => {}}
              label={t('COMMON.REPLY')}
              icon={<ReplyIcon />}
              type="text"
              isGreen
            />
          </>
        )}
      </td>
    </tr>
  )
}

export default memo(FeedbackTableRow)

import { Spin } from 'antd';
import { PhoneType } from 'entities/manager';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert } from 'store/Alert';
import { resetError, updateProfileRequest } from 'store/Manager/Salon';
import { IRootState } from 'store/Root.reducer';
import { UIButton, UICustomCard, UITooltip } from 'ui';
import UITextInput from 'ui/TextInput';
import TooltipUIPassword from 'ui/Tooltip/ui/Password';
import {
  SalonDataType,
  SalonTime,
  StateTimeType,
} from 'views/manager/ProfileSalon/index.type';
import { INITIAL_VALUES } from './constant';
import styles from './style.module.scss';

const SettingPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { profile, isLoading, errorMessage, errors } = useSelector(
    (state: IRootState) => state.salon,
  );

  const [values, setValues] = useState<SalonDataType>(INITIAL_VALUES);

  const handlerChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string,
  ) => {
    const { value } = e.target;

    setValues((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handlerSave = () => {
    const getTimes = () => {
      const newTimes: StateTimeType = {};

      const valueTime = values?.times as StateTimeType;

      if (values?.times) {
        Object.keys(values?.times).forEach((item) => {
          if (valueTime[item]?.time?.start) {
            newTimes[item] = valueTime[item];
          } else {
            newTimes[item] = {};
          }
        });
      }

      return newTimes;
    };

    dispatch(
      updateProfileRequest({
        name: values.name || '',
        phones: values.phones,
        email: values.email || '',
        logo: values.logo?.id,
        about: values.about || '',
        address: values.address,
        times: getTimes(),
        images: values.images,
        timezone: values.timezone,
        ...(values.password && {
          password: values.password,
          password_confirmation: values.password_confirmation,
          password_old: values.password_old,
        }),
      }),
    );
  };

  const inputs = useMemo(
    () => [
      { title: t('MANAGER.CURRENT_PASSWORD'), key: 'password_old' },
      { title: t('MANAGER.NEW_PASSWORD'), key: 'password' },
      { title: t('MANAGER.CONFIRM_PASSWORD'), key: 'password_confirmation' },
    ],
    [t],
  );

  const isBtnDisabled = !(
    values.password &&
    values.password_confirmation &&
    values.password_old
  );

  useEffect(() => {
    if (profile) {
      const phones = profile?.phones?.map((e) => ({
        number: e.number.slice(3, e.number.length),
        id: e.id,
      })) as PhoneType[];

      const newTime = {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
        7: {},
      } as StateTimeType;

      profile?.times?.forEach((item: SalonTime) => {
        newTime[item?.day] = {
          ...newTime[item?.day],
          [item?.type === 1 ? 'time' : 'break']: {
            start: item?.start,
            end: item?.end,
          },
        };
      });

      setValues({
        name: profile?.name,
        email: profile?.email,
        address: profile?.address,
        about: profile?.about,
        times: newTime,
        logo: profile?.logo,
        phones: phones?.map((i) => i.id || 0),
        images: profile?.images?.map((i) => i.id),
        timezone:
          typeof profile?.timezone === 'number' ? profile?.timezone : null,
      });
    }
  }, [profile]);

  useEffect(() => {
    if (errorMessage) {
      dispatch(
        addAlert({
          message: errorMessage,
          type: 'error',
          placement: 'right',
        }),
      );
    }

    return () => {
      dispatch(resetError());
    };
  }, [errorMessage, dispatch]);

  return (
    <Spin spinning={isLoading}>
      <UICustomCard
        title={<>{t('MANAGER.PASSWORD_CHANGE')}</>}
        className={styles.wrapper}
      >
        <div className={styles.passwordWrapp}>
          {inputs.map(({ key, title }, index) => (
            <UITextInput
              key={index}
              text={title}
              isShowPassword
              type="password"
              placeholder={title}
              error={errors?.[key]}
              onChange={(e) => handlerChange(e, key)}
              value={values[key as keyof typeof values] as string}
              iconLabel={<UITooltip content={<TooltipUIPassword />} />}
            />
          ))}
        </div>

        <UIButton
          disabled={isBtnDisabled}
          className={styles.passworsBtn}
          handler={handlerSave}
          label={t('COMMON.SAVE')}
        />
      </UICustomCard>
    </Spin>
  );
};

export default SettingPassword;

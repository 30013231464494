import {
  GET_ORDER_HISTORY_REQUEST,
  GET_ORDER_HISTORY_FAILURE,
  GET_ORDER_HISTORY_SUCCESS,
  SET_MOBILE_TITLE_OPEN,
  CLEAR_ORDERS_ERROR,
} from './Orders.constant'
import { IOrderHistoryState, OrdersActionsTypes } from './Orders.type'

const initialState: IOrderHistoryState = {
  isMobileTitleOpen: false,
  isLoading: false,
  errorMessage: null,
  data: [],
  meta: {
    page: 1,
    perPage: 0,
    total: 0,
  },
}

export default function ordersReducer(
  state = initialState,
  action: OrdersActionsTypes,
): IOrderHistoryState {
  switch (action.type) {
    case GET_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        meta: {
          page: action.payload.meta.current_page || 1,
          perPage: action.payload.meta.per_page || 0,
          total: action.payload.meta.total || 0,
        },
      }
    case GET_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      }
    case SET_MOBILE_TITLE_OPEN:
      return {
        ...state,
        isMobileTitleOpen: action.payload,
      }
    case CLEAR_ORDERS_ERROR:
      return {
        ...state,
        errorMessage: null,
      }
    default:
      return state
  }
}

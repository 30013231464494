import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as PlusSVG } from 'assets/images/common/redesign/plusCircle.svg';
import { ModalType } from 'entities/modal';
import StaffHeader from 'modules/manager/staff/Header';
import StaffUIShifts from 'modules/manager/staff/Shifts';
import { showModal } from 'store/Modal';
import { UIButton } from 'ui';

const ViewManagerShifts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleBtn = () => {
    dispatch(showModal({ modalType: ModalType.MANAGER_ADD_TIME_OFF }));
  };

  return (
    <>
      <StaffHeader
        button={
          <UIButton
            handler={handleBtn}
            label={t('MANAGER.STAFF.ADD_TIME_OFF')}
            icon={<PlusSVG />}
            type="text"
          />
        }
      />
      <StaffUIShifts />
    </>
  );
};

export default ViewManagerShifts;

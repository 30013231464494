import { DocumentStatus } from 'entities/common'
import { DELETE, GET, POST } from 'utils/http'
import axios, { CancelTokenSource } from 'axios'

let axiosSource: CancelTokenSource | null = null

const basePath = '/admin/user'

export const getAdminCustomersApi = async (query: string) => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}/customer${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const getAdminCurrentUserApi = (id: number) => GET(`${basePath}/${id}`)

export const setAdminCurrentUserDisabledApi = ({
  id,
  active,
}: {
  active: 1 | 0
  id: number
}) => POST(`${basePath}/${id}`, { active })

export const setAdminCurrentUserDocumentConfirmationApi = ({
  id,
  confirmed,
}: {
  confirmed: DocumentStatus
  id: number
}) => POST(`${basePath}/${id}`, { confirmed })

export const setAdminCurrentUserToggleSubscriptionApi = ({
  id,
  manual_free_trial,
}: {
  id: number
  manual_free_trial: number
}) => POST(`${basePath}/${id}`, { manual_free_trial })

export const getAdminSalonsApi = async (query: string) => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}/salon${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const removeAdminDocument = (id: number) => DELETE(`document/${id}`)

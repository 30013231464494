import adminEsp from './admin.json';
import commonEsp from './common.json';
import error from './error.json';
import exceptionEsp from './exception.json';
import mainEsp from './main.json';
import managerEsp from './manager.json';
import notFoundEsp from './notFound.json';
import validationEsp from './validation.json';

export default {
  ...commonEsp,
  ...mainEsp,
  ...adminEsp,
  ...managerEsp,
  ...validationEsp,
  ...exceptionEsp,
  ...error,
  ...notFoundEsp,
};

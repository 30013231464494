import { GET } from 'utils/http'

import axios, { CancelTokenSource } from 'axios'

let axiosSource: CancelTokenSource | null = null

const basePath = '/user'

export const getManagerClientsApi = async (query = '') => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}/clients${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

export const getManagerUsersApi = async (query = '') => {
  if (axiosSource) {
    axiosSource.cancel()
  }

  axiosSource = axios.CancelToken.source()

  const data = await GET(`${basePath}/contacts${query}`, {
    ...(axiosSource && { cancelToken: axiosSource.token }),
  })

  return data
}

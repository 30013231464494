import { SyntheticEvent } from 'react'

import { OnUploadFileChange } from 'ui/UploadFile/index.type'

export const TYPE_INPUT = 'input'
export const TYPE_FILE = 'file'
export const TYPE_TEXTAREA = 'textarea'

export type InputTypes =
  | typeof TYPE_INPUT
  | typeof TYPE_FILE
  | typeof TYPE_TEXTAREA

export type TemplateFieldsPropsTypeChange = (
  value: SyntheticEvent,
) => void | OnUploadFileChange

export type TemplateFieldsPropsType = {
  heightRatio?: number
  label: string
  name: string
  onChange: TemplateFieldsPropsTypeChange
  selectedName?: string
  type?: InputTypes
  value?: string
  widthRatio?: number
}

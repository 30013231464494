export const reorder = (
  list: unknown[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)

  result.splice(endIndex, 0, removed)

  return result
}

export enum ResponsiveWidth {
  XS = 0,
  SM = 500,
  MD = 767,
  LG = 1250,
}

export enum FileName {
  AUDIO = 'audio',
  DOCS = 'docs',
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum DocumentStatus {
  ACCEPT = 'ACCEPT',
  EMPTY = 'EMPTY',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum AdminNotificationStatus {
  ADDED = 'App\\Notifications\\DocumentNewUpload',
  UPDATED = 'App\\Notifications\\DocumentUpdateUpload',
}

export enum HttpStatus {
  OK = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  ExpiredSubscribe = 402,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  LargeRequest = 413,
  UnprocessableEntity = 422,
  ManyRequests = 429,
  InternalServerError = 500,
}

enum HttpExceptionType {
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  REFRESH_TOKEN_VERIFY = 'REFRESH_TOKEN_VERIFY',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_MALFORMED = 'TOKEN_MALFORMED',
  TOKEN_NOT_PROVIDED = 'TOKEN_NOT_PROVIDED',
  TOKEN_VERIFY = 'TOKEN_VERIFY',
}

export type ErrorResponseType = {
  appointments?: any
  code: HttpExceptionType
  errors: { [key: string]: string }
  message: string
  status: number
}

export type UploadMedia = {
  file: Blob
}

export type FullMedia = {
  id: number
  path: string
}

export type TimeFromTo = {
  from: string
  to: string
}

export type SelectOption = {
  label: string
  value: string
}

export type ResponseType<T> = {
  data: T
  links: ResponseLinkType
  meta: ResponseMetaType
}

export type ResponseLinkType = {
  first: string
  last: string
  next: string | null
  prev: string | null
}

export type ResponseMetaType = {
  current_page: number
  from: number
  last_page: number
  links: ResponseMetaLinksType[]
  path: string
  per_page: number
  to: number
  total: number
}

export type ResponseMetaLinksType = {
  active: boolean
  label: string
  url: null | string
}

export type SalonsAddressType = {
  address_components: SalonsAddressComponents[]
  formatted_address: string
  geometry: SalonsAddressGeometryType
  place_id: string
  types: string[]
}

type SalonsAddressComponents = {
  long_name: string
  short_name: string
  types: string[]
}

type SalonsAddressGeometryType = {
  bounds: SalonsAddressGeometryBoundsType
  location: AddressGeometryLocationType
  location_type: string
  viewport: SalonsAddressGeometryBoundsType
}

type SalonsAddressGeometryBoundsType = {
  east: number
  north: number
  south: number
  west: number
}

type AddressGeometryLocationType = {
  lat: number
  lng: number
}

export type SalonTimesType = {
  day: number
  end: string
  start: string
  type: number
}

export interface ICustomer {
  active: number
  email: string
  id: number
  name: string
  phone?: string
  profile: ICustomerProfile
  type: string
}

export interface ICustomerProfile {
  avatar: FullMedia | null
  id: number
  phone: string | null
}

export type SeoType = {
  description?: string
  keywords?: string
  title?: string
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FC, memo, useMemo, useRef, useState } from 'react';
import { SelectOptionType } from 'ui/AppointmentsSidebar/index.type';
import UITextInput from 'ui/TextInput';

import styles from './style.module.scss';
import useClickOutside from 'hooks/useClickOutside';
import { SyntheticEvent } from 'react-draft-wysiwyg';
import classNames from 'classnames';
import { ReactComponent as SearchSVG } from 'assets/images/common/redesign/search.svg';

export type SearchOptionType = SelectOptionType & {
  inFrontOf?: string;
  subtitle?: string;
} & {
  [key: string]: string | number;
};

type PropTypes = {
  className?: string;
  isLoading?: boolean;
  label?: string;
  onBlur?: () => void;
  onFocus?: (value: string) => void;
  onSelect?: (value: string | number, obj: SearchOptionType) => void;
  options?: Array<SearchOptionType>;
  placeholder?: string;
  select?: SearchOptionType | string | number;
};

const CustomSearch: FC<PropTypes> = ({
  isLoading,
  className,
  label,
  placeholder,
  options,
  select,
  onSelect,
  onFocus,
  onBlur,
}) => {
  const ref = useRef(null);
  const [search, setSearch] = useState('');
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownOpen = () => {
    setDropdownOpen(true);

    if (onFocus) {
      onFocus(search);
    }
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);

    if (onBlur) {
      onBlur();
    }
  };

  const handleChange = (event: SyntheticEvent) => {
    setSearch((event.target as HTMLInputElement).value);
  };

  const handleSelect = (item: SearchOptionType) => () => {
    handleDropdownClose();
    setSearch('');

    if (onSelect) {
      onSelect(item.value, item);
    }
  };

  const renderPlaceholder = useMemo(
    () => getCorrectOptionByState(select, options) || placeholder,
    [select, options, placeholder],
  );

  useClickOutside(ref, handleDropdownClose);

  return (
    <div ref={ref} className={classNames(styles['custom-search'], className)}>
      {!!label && <label htmlFor={label}>{label}</label>}

      <UITextInput
        id={label}
        className={classNames(styles['custom-search__input'], {
          [styles['custom-search__input_selected']]: !!select,
        })}
        onFocus={handleDropdownOpen}
        placeholder={renderPlaceholder}
        onChange={handleChange}
        value={search}
        icon={
          isLoading ? (
            <Spin
              indicator={<LoadingOutlined spin style={{ fontSize: 16 }} />}
            />
          ) : (
            <SearchSVG className={styles['custom-search__input__icon']} />
          )
        }
      />

      {!isLoading && isDropdownOpen && (
        <div className={styles['custom-search__popup']}>
          <ul className={styles['custom-search__popup__list']}>
            {options
              ?.filter(
                (item) =>
                  item.label
                    .trim()
                    .toLowerCase()
                    .split('-')
                    .join('')
                    .includes(
                      search.trim().toLowerCase().split('-').join(''),
                    ) ||
                  (item?.subtitle &&
                    item?.subtitle
                      ?.trim()
                      ?.toLowerCase()
                      ?.split('-')
                      ?.join('')
                      ?.includes(
                        search.trim().toLowerCase().split('-').join(''),
                      )),
              )
              ?.map((item, index) => (
                <li
                  className={styles['custom-search__popup__list-item']}
                  onClick={handleSelect(item)}
                  key={`item.value-${index}`}
                >
                  <p
                    className={styles['custom-search__popup__list-item__text']}
                  >
                    <span>{item.label}</span>
                    <span
                      className={
                        styles[
                          'custom-search__popup__list-item__text__in-fron-of'
                        ]
                      }
                    >
                      {item.inFrontOf}
                    </span>
                  </p>

                  {item.subtitle && <span>{item.subtitle}</span>}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

function getCorrectOptionByState(
  select: SearchOptionType | string | number | undefined,
  options: SearchOptionType[] | undefined,
) {
  if (typeof select === 'undefined' || !options) return '';
  if (typeof select === 'string' || typeof select === 'number')
    return (
      options.find((item) => String(item.value) === String(select))?.label || ''
    );

  return select.label || '';
}

export default memo(CustomSearch);

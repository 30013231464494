import React, { FC, memo, useState } from 'react';

import FullCalendar, {
  DateSelectArg,
  DayCellContentArg,
  EventAddArg,
  EventApi,
  EventChangeArg,
} from '@fullcalendar/react';
// eslint-disable-next-line import/order
import interactionPlugin from '@fullcalendar/interaction';
// eslint-disable-next-line import/order
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import classNames from 'classnames';

import { PropsType } from './index.type';
import style from './style.module.scss';
import { useTranslation } from 'react-i18next';

const getDayContent = ({ date, dayNumberText, view }: DayCellContentArg) => {
  const events = view.calendar.getEvents();
  const currentEventEnd = (!!events && events[0]?.end) || {};
  const currentEventStart = (!!events && events[0]?.start) || {};
  const isEvent = currentEventEnd > date && currentEventStart <= date;
  const className = classNames({ [style.dateEvent]: isEvent });

  return <div className={className}>{dayNumberText}</div>;
};

const UICalendar: FC<PropsType> = ({
  setDate,
  selectAllow,
  ref,
  initialDate,
  className,
  isDragging,
  eventStart,
  eventEnd,
}) => {
  const { i18n } = useTranslation();
  const [, setEvents] = useState<EventApi[]>([]);

  const classes = classNames(
    style.searchWhenCalendarWrapper,
    style.calendarWrapper,
    className,
    { [style.hideHover]: !isDragging },
  );

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect();
    calendarApi.removeAllEvents();
    calendarApi.addEvent({
      id: '0',
      start: selectInfo.startStr,
      end: isDragging ? selectInfo.endStr : selectInfo.startStr,
      allDay: selectInfo.allDay,
    });

    if (setDate) {
      setDate({
        end: isDragging ? selectInfo.end : selectInfo.start,
        start: selectInfo.start,
      });
    }
  };

  // for component rerender
  const handleEventChange = (arg: EventChangeArg | EventAddArg) => {
    setEvents([arg.event]);
  };

  return (
    <div className={classes}>
      <FullCalendar
        ref={ref}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView={'dayGridMonth'}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        titleFormat={{
          month: 'long',
          year: 'numeric',
        }}
        initialDate={initialDate}
        firstDay={1}
        editable={true}
        selectable={true}
        events={[
          {
            start: eventStart,
            end: eventEnd,
          },
        ]}
        eventContent={() => null}
        dragScroll={false}
        select={handleDateSelect}
        viewClassNames={style.view}
        eventLongPressDelay={0}
        selectLongPressDelay={0}
        eventClassNames={style.event}
        dayCellClassNames={style.dayCell}
        dayHeaderClassNames={style.dayHeader}
        dayCellContent={getDayContent}
        eventChange={handleEventChange}
        eventAdd={handleEventChange}
        {...(selectAllow && {
          selectAllow: (selectInfo) => selectAllow(selectInfo.start),
        })}
        locale={i18n.language}
      />
    </div>
  );
};

export default memo(UICalendar);
